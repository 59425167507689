import React, { Component } from 'react';
import { Row, Col, Container, Image } from 'react-bootstrap';
import {
  HomeGoods,
  HomeIntroduce,
  HomeNotice,
  HomePopup,
  HomePopup2,
  Database,
  Storage,
} from 'components';
import 'style.scss';
import { observer, inject } from 'mobx-react';
import '../../components/Story/StoryCss.scss';
import { Link } from 'react-router-dom';

@inject('store')
@observer
class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      popUp: false,
      title: '정재훈 a.k.a, ZiBEZI',
      subtitle:
        "ZiBEZI's free style painting with improvised inspiration and imagination drew attention from the movie 2019 Oscar winning, 'PARASITE' directed by Bong Joon Ho. The artist now receives a lot attention from the public for being a part of the movie. Whenever his friends ask him where he is, he used to answer back to them that he is home in Korean \"jib-e-ji\". The artist's name ZiBEZI is driven from his answer. ZiBEZI is not limited to one material, but uses a variety of materials other than oil pastel, markers and acrylics to create his own works. ZiBEZI mainly works in the US and in Korea. ",
      paragraph:
        'Inspried by Antoni Gaudi, "The straight line is the human line, the curve is the God\'s line.',
      imageSrc: null,
      list: [],
      newList: [],
      goodsHeader: 'New arrival',
      goodsContent: [],
      noticeHeader: 'Notice',
      noticeContents: [
        {
          src: require('images/home/exhibition2024.jpg'),
          title: 'ZiBEZI Solo Exhibition',
          subtitle: 'LKATE Art Gallery / 24. 03. 08 ~ 24. 03. 31',
          // link: "http://www.webdaily.co.kr/view.php?ud=201908291701409825992c130dbe_7",
          // subtitle2:"인스타",
          // link2:"https://www.instagram.com/p/B1-R7uYnKBw/?igshid=1k10oy4e8v2xs",
          // subtitle3:"이벤트",
          // link3:"http://pf.kakao.com/_ZUNxfj/39515163",
          // subtitle4:"뉴스3",
          // link4:"http://www.vogue.co.kr/2019/06/12/%ea%b8%b0%ec%83%9d%ec%b6%a9%ec%97%90-%ed%9b%84%eb%8b%88%ed%9b%88-%ea%b7%b8%eb%a6%bc%ec%9d%b4/"
        },
        {
          src: require('images/home/busan.jpg'),
          title: 'ZiBEZI in 지비지 부산 / 22. 11. 05 - 22. 11. 27 ',
          subtitle:
            ' KT&G상상마당부산 갤러리 5F / 밀락더마켓 M컨템포러리 갤러리',
          // link: 'http://news.chosun.com/site/data/html_dir/2019/07/30/2019073002734.html',
        },
      ],
      instagramHeader: 'Instagram',
      instagramContents: [],
    };
  }
  closePopup = () => {
    this.setState({ popUp: true });
  };
  returnElement = (key, url, title, eng_title, timestamp) => {
    return {
      key: key,
      url: url,
      title: title,
      eng_title: eng_title,
      timestamp: timestamp,
    };
  };

  componentWillMount = async () => {
    let docRefs = Database.collection('Artworks')
      .orderBy('timestamp', 'desc')
      .limit(4);
    let storageRefs = Storage.ref('artworks');
    let sorted = [];
    await docRefs
      .where('uploaded', '==', true)
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          let key = doc._key.path.segments[doc._key.path.segments.length - 1];
          storageRefs
            .child('/' + key)
            .getDownloadURL()
            .then((url) => {
              sorted.push(
                this.returnElement(
                  key,
                  url,
                  doc.data().title,
                  doc.data().eng_title,
                  doc.data().timestamp,
                ),
              );
              this.setState({ goodsContent: sorted });
            })
            .catch((err) => {
              console.log(err);
            });
        });
      })
      .catch((err) => console.log(err));
  };

  displayImage(url) {
    return (
      <div
        style={{
          position: 'relative',
          backgroundColor: 'black',
          zIndex: 200,
          width: '200px',
          height: '200px',
          borderWidth: 1,
          borderStyle: 'solid',
        }}
      >
        <Image
          ref={(ref) => (this.image = ref)}
          src={url}
          width={'100%'}
          height={'100%'}
          className=""
        />
      </div>
    );
  }
  render() {
    return (
      <Container fluid>
        <HomePopup2 open={true} onClose={this.closePopup}/>
        <Row>
          <div className="home_wrap">
            <div className="home_cont">
              <div className="home_h_line"></div>
              <p className="home_text1">
                <h5>Modern Art Gallery &amp; Shop</h5>
              </p>
              <h1 className="home_text2">
                <b>
                  ZiBEZI
                  Gallery&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </b>
                <br></br>
              </h1>
              <div className="home_button_wrap">
                <Col md={3}>
                  <Link to="/shop" className="home_button">
                    Gallery
                  </Link>
                </Col>
                <Col md={3}>
                  <Link to="/story" iv className="home_button">
                    Story
                  </Link>
                </Col>
                <Col md={3}>
                  <Link to="/shop/pagination" className="home_button">
                    For Sale
                  </Link>
                </Col>
                <Col md={3}>
                  <Link to="/shop/pick" className="home_button">
                    Major
                  </Link>
                </Col>
              </div>
            </div>
          </div>
          <div className="home_background" />
        </Row>
        <HomeIntroduce
          title={this.state.title}
          subtitle={this.state.subtitle}
          paragraph={this.state.paragraph}
        />

        <HomeGoods
          header={this.state.goodsHeader}
          contents={this.state.goodsContent}
        />

        <HomeNotice
          header={this.state.noticeHeader}
          contents={this.state.noticeContents}
        />
      </Container>
    );
  }
}
export default Home;
