import React, { Component } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { Database } from 'components';

import 'style.scss';
import 'components/style.scss';
class AdminSoldout extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tshirts: {},
      shoes: {},
      loading: true,
    };
  }
  componentWillMount = () => {
    Database.collection('System')
      .doc('soldout')
      .get()
      .then((doc) => {
        if (doc.exists) {
          let soldout = doc.data();

          this.setState({
            tshirts: soldout.tshirts,
            shoes: soldout.shoes,
            loading: false,
          });
        } else {
        }
      })
      .catch((err) => {
        console.log('Database_read_error');
      });
  };
  controlSoldout = (type, key, index) => {
    let current = this.state[type];
    current[key].size[index].soldout = !current[key].size[index].soldout;
    type === 'tshirts'
      ? this.setState({ tshirts: current })
      : this.setState({ shoes: current });
  };
  saveSolooutState = () => {
    this.setState({ loading: true });
    Database.collection('System')
      .doc('soldout')
      .update({
        tshirts: this.state.tshirts,
        shoes: this.state.shoes,
      })
      .then(() => {
        this.setState({
          loading: false,
        });
        window.location.reload();
      })
      .catch((err) => {
        console.log('Database_write_error');
      });
  };
  render() {
    let tshirts = [];
    Object.keys(this.state.tshirts).forEach((key, e) => {
      tshirts.push(
        <div key={key + e + 'header'} className="soldout-sub-header">
          - {this.state.tshirts[key].text}
        </div>,
      );
      tshirts.push(
        <Col
          key={key + e + 'container'}
          xs={12}
          className="soldout-sub-container"
        >
          {this.state.tshirts[key].size.map((element, k) => {
            return (
              <div
                key={element.text}
                className="soldout-btn pointer"
                onClick={() => {
                  this.controlSoldout('tshirts', key, k);
                }}
              >
                {this.state.tshirts[key].size[k].soldout && (
                  <div className="soldout-screen" />
                )}
                {element.text}
              </div>
            );
          })}
        </Col>,
      );
    });
    let shoes = [];
    Object.keys(this.state.shoes).forEach((key, e) => {
      shoes.push(
        <div key={key + e + 'header'} className="soldout-sub-header">
          - {this.state.shoes[key].text}
        </div>,
      );
      shoes.push(
        <Col
          key={key + e + 'container'}
          xs={12}
          className="soldout-shoes-container"
        >
          {this.state.shoes[key].size.map((element, k) => {
            return (
              <Col
                xs={2}
                key={element.text}
                className="soldout-btn pointer"
                onClick={() => {
                  this.controlSoldout('shoes', key, k);
                }}
              >
                {this.state.shoes[key].size[k].soldout && (
                  <div className="soldout-screen" />
                )}
                {element.text}
              </Col>
            );
          })}
        </Col>,
      );
    });
    return (
      <Container className="admin from-bottom-animation">
        <Row className="admin-row">
          <Col xs={12} className="admin-category-header">
            <span>티셔츠</span>
          </Col>
          <Col xs={12}>{tshirts}</Col>
          <Col xs={12} className="admin-category-header">
            <span>신발</span>
          </Col>
          <Col xs={12}>{shoes}</Col>
          <Col
            xs={12}
            className="soldout-save-btn pointer"
            onClick={() => {
              this.saveSolooutState();
            }}
          >
            저장
          </Col>
        </Row>
      </Container>
    );
  }
}
export default AdminSoldout;
