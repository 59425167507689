import React, { Component, Fragment } from 'react';
import { Image, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { Firebase as firebase, LoginForm } from 'components';
import { FiArrowLeft, FiMenu } from 'react-icons/fi';
import '../style.scss';
import 'style.scss';

import { observer, inject } from 'mobx-react';
@inject('store')
@observer
class NavigationBar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isScrolled: 'rgba(255,255,255,0)',
      showDropDown: false,
      loginModal: false,
      toggle: false,
    };
  }

  componentDidMount() {
    window.addEventListener('scroll', this.listenToScroll, { passive: false });
    window.addEventListener('resize', this.resize.bind(this));
    this.resize();
  }
  componentWillUnmount() {
    window.removeEventListener('scroll', this.listenToScroll, {
      passive: false,
    });
    window.removeEventListener('resize', this.resize.bind(this));
  }
  listenToScroll = () => {
    if (window.pageYOffset !== 0)
      this.setState({ isScrolled: 'rgba(255,255,255,0.5)' });
    else {
      //2021.10.29 temporarily
      this.setState({ isScrolled: 'rgba(255,255,255,0.5)' });
    }
  };
  resize = () => {
    if (window.innerWidth < 768) {
      this.setState({ toggle: true });
    } else {
      this.setState({ toggle: false });
    }
    console.log(this.state.toggle);
  };
  handleLogOut = () => {
    console.log('logout pressed', firebase.auth().currentUser);
    if (firebase.auth().currentUser) {
      firebase
        .auth()
        .signOut()
        .then(function () {})
        .then(() => {
          window.location.reload();
        })
        .catch(function (error) {
          console.log('@@Logout' + error);
        });
    }
  };
  componentDidUpdate(prevProps) {}
  render() {
    return !this.state.toggle ? (
      <Row
        fluid
        className="nav-container"
        style={{ backgroundColor: this.state.isScrolled }}
      >
        <div className="logo-container">
          <Link to="/">
            <Image
              src={require('images/zibezi.png')}
              style={{ width: 'auto', height: '30px' }}
            />
          </Link>
        </div>

        <div className="navi-container">
          {this.props.store.user.isAdmin() ? (
            <Link
              to="/admin/home"
              className="nav-element"
              activeclassname="highlight"
            >
              <span>Admin</span>
            </Link>
          ) : (
            <div />
          )}
          <Link
            exact
            to="/"
            className="nav-element"
            activeclassname="highlight"
          >
            <span>Home</span>
          </Link>

          <Link to="/story" className="nav-element" activeclassname="highlight">
            <span>Story</span>
          </Link>
          <Link to="/shop" className="nav-element" activeclassname="highlight">
            <span>Gallery</span>
          </Link>
          <Link
            to="/shop/pagination"
            className="nav-element"
            activeclassname="highlight"
          >
            <span>Shop</span>
          </Link>
          <Link
            to="/notice"
            className="nav-element"
            activeclassname="highlight"
          >
            <span>Notice</span>
          </Link>
          {this.props.store.user.isLogined ? (
            <Fragment>
              <Link
                to="/mypage"
                className="nav-element"
                activeclassname="highlight"
              >
                <span>Mypage</span>
              </Link>

              <div className="nav-element pointer" onClick={this.handleLogOut}>
                <span>Logout</span>
              </div>
            </Fragment>
          ) : (
            <Fragment>
              <div
                className="nav-element pointer"
                onClick={() => this.setState({ loginModal: true })}
              >
                <span>Sign in</span>
              </div>
              <LoginForm
                isOpen={this.state.loginModal}
                callback={() => {
                  this.setState({ loginModal: false });
                }}
              />
            </Fragment>
          )}
        </div>
      </Row>
    ) : (
      <Fragment>
        <div
          style={{
            position: 'absolute',
            // zIndex:this.state.showDropDown?1000:0,
            top: 0,
            left: 0,
            zIndex: 999,
            backgroundColor: this.state.showDropDown
              ? 'rgba(51,51,51,0.7)'
              : 'rgba(51,51,51,0)',
            transition: 'ease-in 0.1s all',
            width: '100vw',
            // height:"100vh",
          }}
          onClick={() => this.setState({ showDropDown: false })}
        />
        <Row
          className="nav-draw-container"
          style={
            this.state.showDropDown
              ? { height: '300px', backgroundColor: 'rgba(255,255,255,0.8)' }
              : { backgroundColor: this.state.isScrolled }
          }
        >
          <div style={{ position: 'relative' }}>
            <div className="draw-header">
              <div
                className="icon-box pointer"
                onClick={() => {
                  console.log(this.props.history);
                  this.props.history.goBack();
                  console.log(this.props.history);
                }}
              >
                <FiArrowLeft size={20} />
              </div>
              <div className="logo-container">
                <Link to="/">
                  <Image
                    src={require('images/zibezi.png')}
                    style={{ width: 'auto', height: '30px' }}
                  />
                </Link>
              </div>
              <div
                className="icon-box pointer "
                onClick={() => {
                  this.setState({ showDropDown: !this.state.showDropDown });
                }}
              >
                <FiMenu size={20} />
              </div>
            </div>

            <div
              className="nav-btn-box"
              style={
                this.state.showDropDown
                  ? { display: 'flex' }
                  : { display: 'none' }
              }
            >
              {this.props.store.user.isAdmin() ? (
                <Link
                  to="/admin/home"
                  onClick={() => this.setState({ showDropDown: false })}
                  className="nav-btn"
                  activeclassname="nav-btn-activated"
                >
                  <span>Admin</span>
                </Link>
              ) : (
                <div />
              )}
              <Link
                exact
                to="/"
                onClick={() => this.setState({ showDropDown: false })}
                className="nav-btn"
                activeclassname="nav-btn-activated"
              >
                <span>Home</span>
              </Link>
              <Link
                to="/story"
                onClick={() => this.setState({ showDropDown: false })}
                className="nav-btn"
                activeclassname="nav-btn-activated"
              >
                <span>Story</span>
              </Link>
              <Link
                to="/shop"
                onClick={() => this.setState({ showDropDown: false })}
                className="nav-btn"
                activeclassname="nav-btn-activated"
              >
                <span>Gallery</span>
              </Link>
              <Link
                to="/shop/pagination"
                onClick={() => this.setState({ showDropDown: false })}
                className="nav-btn"
                activeclassname="nav-btn-activated"
              >
                <span>Shop</span>
              </Link>
              <Link
                to="/notice"
                onClick={() => this.setState({ showDropDown: false })}
                className="nav-btn"
                activeclassname="nav-btn-activated"
              >
                <span>Notice</span>
              </Link>
              {this.props.store.user.isLogined ? (
                <Fragment>
                  <Link
                    to="/mypage"
                    className="nav-btn"
                    activeclassname="nav-btn-activated"
                    onClick={() => this.setState({ showDropDown: false })}
                  >
                    <span>Mypage</span>
                  </Link>

                  <div
                    className="nav-btn pointer"
                    activeclassname="nav-btn-activated"
                    onClick={() => {
                      this.setState({ showDropDown: false });
                      this.handleLogOut();
                    }}
                  >
                    <span>Logout</span>
                  </div>
                </Fragment>
              ) : (
                <Fragment>
                  <div
                    className="nav-btn pointer"
                    activeclassname="nav-btn-activated"
                    onClick={() => {
                      this.setState({ showDropDown: false, loginModal: true });
                    }}
                  >
                    <span>Sign in</span>
                  </div>
                  <LoginForm
                    isOpen={this.state.loginModal}
                    callback={() => {
                      this.setState({ loginModal: false });
                    }}
                  />
                </Fragment>
              )}
            </div>
          </div>
        </Row>
      </Fragment>
    );
  }
}
export default NavigationBar;
