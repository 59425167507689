import { observable, runInAction, toJS } from 'mobx';
import { Database, Storage } from 'components';
const Works = observable({
  number: 1,
  loading: true,
  worksNum: 0,
  currentPage: 0,
  firstArtworks: [],
  allArtworks: [],
  //PAGELOADING:FALSE;
  setCurrentPage(page) {
    this.currentPage = page;
  },
  setAllArtworks(works) {
    this.allArtworks = works;
  },
  setLoading(bool) {
    setTimeout(() => {
      runInAction(() => {
        this.loading = bool;
      }, 500);
    });
  },
  getLoading() {
    return this.loading;
  },
  increase() {
    this.number++;
  },
  getFirst() {
    return new Promise(function (resolve, reject) {
      const docRefs = Database.collection('Artworks').where(
        'uploaded',
        '==',
        true,
      );
      const storageRefs = Storage.ref('artworks');

      let worksList = [];
      let tmpID = 0;
      docRefs
        .orderBy('timestamp', 'desc')
        .limit(12)
        .get()
        .then((snap) => {
          snap.forEach((doc) => {
            let workData = doc.data();
            workData['id'] = tmpID++;
            let key = doc._key.path.segments[doc._key.path.segments.length - 1];
            workData['key'] = key;
            storageRefs
              .child(`/${key}`)
              .getDownloadURL()
              .then((url) => {
                workData['imageUrl'] = url;
              });
            worksList.push(workData);
          });
          return worksList;
        })
        .finally(() => {
          resolve(worksList);
        });
    });
  },
  getAll() {
    return new Promise(function (resolve, reject) {
      const docRefs = Database.collection('Artworks').where(
        'uploaded',
        '==',
        true,
      );
      const storageRefs = Storage.ref('artworks');

      let worksList = [];
      let tmpID = 0;
      docRefs
        .orderBy('timestamp', 'desc')
        //.limit(50)
        .get()
        .then((snap) => {
          snap.forEach((doc) => {
            let workData = doc.data();
            workData['id'] = tmpID++;
            let key = doc._key.path.segments[doc._key.path.segments.length - 1];
            workData['key'] = key;
            storageRefs
              .child(`/${key}`)
              .getDownloadURL()
              .then((url) => {
                workData['imageUrl'] = url;
              });
            worksList.push(workData);
          });
          return worksList;
        })
        .finally(() => {
          resolve(worksList);
        });
    });
  },
  async getFirstArtworks() {
    if (this.allArtworks.length === 0) {
      await runInAction(async () => {
        this.firstArtworks = this.getFirst();
      });
    }
    return this.firstArtworks;
  },
  async getAllArtworks() {
    if (this.allArtworks.length === 0 || this.allArtworks.length === 12) {
      await runInAction(async () => {
        this.allArtworks = this.getAll();
        this.allArtworks.then((res) => {
          this.worksNum = res.length;
        });
      });
    }
    return this.allArtworks;
  },

  async getZibeziPick() {
    const docRefs = Database.collection('Artworks')
      .where('uploaded', '==', true)
      .where('pick', '==', true);
    const storageRefs = Storage.ref('artworks');
    let worksList = [];
    let tmpID = 0;
    await docRefs
      .orderBy('timestamp', 'desc')
      .get()
      .then((snap) => {
        snap.forEach((doc) => {
          let workData = doc.data();
          workData['id'] = tmpID++;
          let key = doc._key.path.segments[doc._key.path.segments.length - 1];
          workData['key'] = key;
          storageRefs
            .child(`/${key}`)
            .getDownloadURL()
            .then((url) => {
              workData['url'] = url;
            });
          worksList.push(workData);
        });
      });
    return worksList;
  },
});

export { Works };
