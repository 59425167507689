import React, { Component } from 'react';
import { Row, Col, Image } from 'react-bootstrap';
import { MdFiberNew } from 'react-icons/md';
import { Link } from 'react-router-dom';
import '../style.scss';
import 'style.scss';

class HomeGoods extends Component {
  homeElement = (url, key, title, eng_title) => {
    return (
      <div key={key} className="home-goods pointer">
        <Link to={'/shop/artworks/' + key}>
          <Image src={url} className="home-goods-image" />
          <div className="home-goods-textbox">
            <span className="label">{title}</span>
            <span className="detail">{eng_title}</span>
          </div>
        </Link>
      </div>
    );
  };
  render() {
    return (
      <Row className="home-component">
        <Col xs={12}>
          <span className="component-header">
            <MdFiberNew size={'3vh'} className="home-icon" />
            {this.props.header}
          </span>
        </Col>

        <Col
          xs={12}
          className="justify-content-between from-bottom-animation home-goods-list"
        >
          <div style={{ display: 'flex', flexDirection: 'row', width: 'auto' }}>
            {this.props.contents.map((element) => {
              return this.homeElement(
                element.url,
                element.key,
                element.title,
                element.eng_title,
              );
            })}
          </div>
        </Col>
      </Row>
    );
  }
}
export default HomeGoods;
