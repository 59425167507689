import { observable, action, computed } from 'mobx';

class User {
  @observable uid;
  @observable email;
  @observable name;

  constructor() {
    this.name = this.uid = this.email = '';
    this.uid = '';
  }

  @action
  successLogin = (user) => {
    this.uid = user;
    console.log(this.uid);
    //this.name = user.name;
  };

  @action
  isAdmin = () => {
    return this.uid === 'admin@zibezi.com' || this.uid === 'pmw1130@gmail.com'; //this.uid === "admin_zibezi";
  };

  @action
  isLogin = () => {
    // return this.uid !== '' && this.name !== '';
    return this.uid !== '';
  };

  @computed
  get isLogined() {
    return this.uid !== '';
  }
}

export default User;
