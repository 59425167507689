import Firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/auth';
import 'firebase/storage';
const config = {
  apiKey: 'AIzaSyDFLoKFD4QB8I30SHLpzYzb6vUCfQ-XpT4',
  authDomain: 'zibezi-gallery.firebaseapp.com',
  databaseURL: 'https://zibezi-gallery.firebaseio.com',
  projectId: 'zibezi-gallery',
  storageBucket: 'zibezi-gallery.appspot.com',
  messagingSenderId: '614772309098',
  appId: '1:614772309098:web:8e8b9660cd521a6c',
};
Firebase.initializeApp(config);
export default Firebase;
