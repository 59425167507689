import React, { Component } from 'react';
import { Container, Row, Col, Image } from 'react-bootstrap';
import { Firebase, Database, Storage } from 'components';
import { PositionableContainer } from 're-position';
import * as htmlToImage from 'html-to-image';
import Modal from 'react-modal';
import StackGrid from 'react-stack-grid';
import 'style.scss';
import 'components/style.scss';

import { observer, inject } from 'mobx-react';

@inject('store')
@observer
class Custom extends Component {
  constructor(props) {
    super(props);

    let user = Firebase.auth().currentUser;
    let email = '';
    if (user !== null) {
      email = user.email;
    }
    this.state = {
      email,
      demand: '',
      main_slider_elements: [],
      activeDrags: 0,
      endOfCustom: false,
      category: null,
      color: null,
      artworks_list_modal: false,
      artworks_selected: null,
      artworks_list: [],
      curIndex: 0,
      maxIndex: 0,
      custom_list: [],
      inputFocused: false,
      num_drag: 0,
      test: 0,
      selected: null,
      text: '',
    };
  }
  handleUpdate = (position) => {
    this.setState({ position });
  };
  displayWon = (src) => {
    let tmp = src;
    if (src !== undefined) {
      if (typeof src === 'string') {
        tmp = src.replace(/,/gi, '');
        tmp = tmp.replace(/\s/gi, '');
      }
      tmp = tmp.toString();
      let index = tmp.length;
      while (1) {
        index -= 3;
        if (index < 1) return tmp;
        tmp = tmp.slice(0, index) + ',' + tmp.slice(index, tmp.length);
      }
    }
  };

  componentWillMount = () => {};
  componentDidMount = () => {
    let database = Database.collection('Artworks');
    let storage = Storage.ref('artworks');
    database
      .where('uploaded', '==', true)
      .get()
      .then((res) => {
        res.forEach((doc) => {
          const key = doc._key.path.segments[doc._key.path.segments.length - 1];
          const data = doc.data();
          storage
            .child('/' + key)
            .getDownloadURL()
            .then((url) => {
              this.setState({
                artworks_list: [
                  ...this.state.artworks_list,
                  { title: data.title, eng_title: data.eng_title, key, url },
                ],
              });
            });
        });
        this.setState({
          maxIndex: res.size,
          curIndex: res.size >= 11 ? 11 : res.size,
        });
      });
  };
  displayHashtags = () => {};

  saveme = (price) => {
    this.setState({ endOfCustom: true });
    let database = Database.collection('Cart');
    let storage = Storage.ref('custom');
    const uid = this.state.email;
    database
      .add({
        uid: Firebase.auth().currentUser.email,
        timestamp: new Date(),
        gid: 'CUSTOM',
        demand: this.state.demand,
        base: this.state.category === 1 ? '에코백' : '반팔 무지 티셔츠',
        options: this.state.custom_list,
        price,
        count: 1,
      })
      .then((res) => {
        const key = res._key.path.segments[1];
        this.setState({ key });

        htmlToImage
          .toJpeg(document.getElementById('custom-result'), { quality: 0.95 })
          .then(function (dataURL) {
            const BASE64_MARKER = ';base64,';
            if (dataURL.indexOf(BASE64_MARKER) === -1) {
              const parts = dataURL.split(',');
              const contentType = parts[0].split(':')[1];
              const raw = parts[1];
              return new Blob([raw], {
                type: contentType,
              });
            }
            const parts = dataURL.split(BASE64_MARKER);
            const contentType = parts[0].split(':')[1];
            const raw = window.atob(parts[1]);
            const rawLength = raw.length;
            const uInt8Array = new Uint8Array(rawLength);
            let i = 0;
            while (i < rawLength) {
              uInt8Array[i] = raw.charCodeAt(i);
              i++;
            }
            const imageValue = new Blob([uInt8Array], {
              type: contentType,
            });

            Storage.ref('custom/' + key)
              .put(imageValue)
              .then(() => {})
              .catch(() => {
                console.log('업로드 오류');
              });
            // var link = document.createElement('a');
            // link.download = 'my-image-name.jpeg';
            // link.href = dataUrl;
            // link.click();
          });
      });
    // htmlToImage.toJpeg(document.getElementById('custom-result'), { quality: 0.95 })
    //   .then(function (dataUrl) {
    //     var link = document.createElement('a');
    //     link.download = 'my-image-name.jpeg';
    //     link.href = dataUrl;
    //     link.click();

    //   });

    // setTimeout(() => {
    //   this.setState({ endOfCustom: false })
    // }, 300);
  };
  onMore = () => {
    let { curIndex, maxIndex } = this.state;
    console.log(curIndex, maxIndex);
    curIndex = curIndex + 12 <= maxIndex ? curIndex + 12 : maxIndex;
    this.setState({ curIndex });
  };
  onSelectCategory = (category) => {
    this.setState({ category });
  };
  onSelectColor = (color) => {
    this.setState({ color });
  };
  resize = (d, index) => {
    let custom_list = this.state.custom_list;
    if (d) {
      custom_list[index].ratio = custom_list[index].ratio + 0.1;
    } else {
      custom_list[index].ratio = custom_list[index].ratio - 0.1;
    }
    this.setState({ custom_list });
  };

  onArtworkDelete = (key) => {
    let custom_list = this.state.custom_list;
    custom_list[key].deleted = true;
    this.setState({ custom_list });
  };
  returnHighlightedBtn = (flag) => {
    return flag ? { border: 'solid 1px #333', color: '#333' } : {};
  };
  renderArtworksModal = () => {
    return (
      <Modal
        ariaHideApp={false}
        isOpen={this.state.artworks_list_modal}
        onRequestClose={() => this.setState({ artworks_list_modal: false })}
        className="custom-artwork-modal"
        overlayClassName="modal-overlay"
      >
        <div className="modal-container">
          <div className="modal-header">아트워크 선택</div>
          <div className="modal-content">
            <div>
              <StackGrid
                className="my-3"
                columnWidth={'25%'}
                gridRef={(grid) => (this.grid = grid)}
                gutterWidth={30}
                gutterHeight={30}
              >
                {this.state.artworks_list
                  .slice(0, this.state.curIndex + 1)
                  .map((element) => {
                    return (
                      <div
                        key={element.key}
                        className="modal-grid"
                        style={
                          this.state.artworks_selected === element.key
                            ? { backgroundColor: '#333' }
                            : {}
                        }
                        onClick={() => {
                          this.setState({ artworks_selected: element.key });
                        }}
                      >
                        <Image
                          src={element.url}
                          className="grid-image"
                          style={
                            this.state.artworks_selected === element.key
                              ? { backgroundColor: '#333' }
                              : {}
                          }
                        />
                        <div
                          className="grid-text"
                          style={
                            this.state.artworks_selected === element.key
                              ? { color: 'white', fontWeight: 500 }
                              : {}
                          }
                        >
                          {element.title}/{element.eng_title}
                        </div>
                      </div>
                    );
                  })}
              </StackGrid>
            </div>
            <div className="refresh-btn">
              <span className="refresh-text" onClick={() => this.onMore()}>
                더 불러오기
              </span>
            </div>
          </div>
          <div
            className="modal-confirm"
            onClick={() => {
              this.state.artworks_list.forEach((element) => {
                if (element.key === this.state.artworks_selected) {
                  this.setState({
                    custom_list: [
                      ...this.state.custom_list,
                      {
                        title: element.title,
                        type: true,
                        key: element.key,
                        url: element.url,
                        index: this.state.num_drag,
                        deleted: false,
                        onHover: false,
                        ratio: 1,
                        position: {
                          left: '0%',
                          top: '0%',
                          width: '150px',
                          height: '150px',
                          rotation: '0deg',
                        },
                      },
                    ],
                    num_drag: ++this.state.num_drag,
                    artworks_selected: null,
                    artworks_list_modal: false,
                    //selected:this.state.custom_list.length
                  });
                  return;
                }
              });
            }}
          >
            추가하기
          </div>
        </div>
      </Modal>
    );
  };
  render() {
    const { width, top, left, height, rotateAngle } = this.state;
    let totalInfo = {
      base: this.state.category,
      artworks: [],
      text: [],
    };
    this.state.custom_list.forEach((element) => {
      if (element.deleted !== true) {
        if (element.type) {
          totalInfo.artworks.push(element);
        } else {
          totalInfo.text.push(element);
        }
      }
    });

    return (
      <Container className="custom">
        {this.renderArtworksModal()}
        <Row className="custom-row ">
          <Col xs={12} className="custom-header">
            <span>커스텀</span>
          </Col>

          <Col xs={12} className="custom-step">
            <div className="custom-step-header">1. 베이스 선택</div>
            <div className="custom-step-container">
              <div
                className="custom-category-btn pointer"
                style={this.returnHighlightedBtn(this.state.category === 0)}
                onClick={() => this.onSelectCategory(0)}
              >
                티셔츠
              </div>
            </div>
          </Col>
          {this.state.category !== null ? (
            <Col xs={12} className="custom-step from-bottom-animation">
              <div className="custom-step-header">2. 색상 선택</div>
              <div className="custom-step-container">
                <div
                  className="custom-category-btn pointer"
                  style={this.returnHighlightedBtn(
                    this.state.color === 'BLACK',
                  )}
                  onClick={() => this.onSelectColor('BLACK')}
                >
                  블랙
                </div>
                <div
                  className="custom-category-btn pointer"
                  style={this.returnHighlightedBtn(
                    this.state.color === 'WHITE',
                  )}
                  onClick={() => this.onSelectColor('WHITE')}
                >
                  화이트
                </div>
                <div
                  className="custom-category-btn pointer"
                  style={this.returnHighlightedBtn(this.state.color === 'GRAY')}
                  onClick={() => this.onSelectColor('GRAY')}
                >
                  그레이
                </div>
                <div
                  className="custom-category-btn pointer"
                  style={this.returnHighlightedBtn(this.state.color === 'PINK')}
                  onClick={() => this.onSelectColor('PINK')}
                >
                  핑크
                </div>
              </div>
            </Col>
          ) : (
            <div />
          )}
          {this.state.category !== null && this.state.color !== null ? (
            <Col xs={12} className="custom-step from-bottom-animation">
              <div className="custom-step-header">
                <span>3. 커스텀</span>
                <span className="header-sub">
                  아트워크, 텍스트 추가시 비용이 추가적으로 발생합니다.
                </span>
              </div>
              <div className="option-container">
                <div
                  className="option-artwork"
                  onClick={() =>
                    this.setState({
                      artworks_list_modal: true,
                      artworks_selected: null,
                    })
                  }
                >
                  아트워크 추가
                </div>
                <div
                  className="option-text"
                  style={this.state.inputFocused ? { width: 'auto' } : {}}
                  onClick={() => this.textInput.focus()}
                >
                  텍스트 추가
                  <input
                    type="text"
                    ref={(ref) => (this.textInput = ref)}
                    onChange={(e) => {
                      this.setState({ text: e.target.value });
                    }}
                    value={this.state.text}
                    className="option-text-input"
                    onFocus={() => {
                      this.setState({ inputFocused: true });
                    }}
                    onBlur={() => {
                      setTimeout(
                        () => this.setState({ inputFocused: false }),
                        300,
                      );
                    }}
                    style={
                      this.state.inputFocused
                        ? {
                            width: '200px',
                            marginLeft: '10px',
                            marginRight: '10px',
                          }
                        : { width: '0' }
                    }
                  />
                  <div
                    className="option-text-confirm"
                    style={
                      this.state.inputFocused
                        ? { display: 'block' }
                        : { display: 'none' }
                    }
                    onClick={() => {
                      this.setState({
                        inputFocused: false,
                        custom_list: [
                          ...this.state.custom_list,
                          {
                            type: false,
                            index: this.state.num_drag,
                            text: this.state.text,
                            onHover: false,
                            position: {
                              left: '25%',
                              top: '0%',
                              width: '100px',
                              height: '30px',
                              rotation: '0deg',
                            },
                          },
                        ],
                        text: '',
                        num_drag: ++this.state.num_drag,
                      });
                    }}
                  >
                    추가
                  </div>
                </div>
                <div
                  className="option-text"
                  style={
                    this.state.selected !== null ? {} : { display: 'none' }
                  }
                  onClick={() => {
                    let { custom_list } = this.state;
                    custom_list[this.state.selected].deleted = true;
                    this.setState({ custom_list });
                  }}
                >
                  삭제
                </div>
              </div>
              <div className="custom-custom-container">
                <div className="custom-paper" id="custom-result">
                  <Image
                    src={require(this.state.color === 'BLACK'
                      ? 'images/goods/tshirts/black.jpg'
                      : this.state.color === 'WHITE'
                      ? 'images/goods/tshirts/white.jpg'
                      : this.state.color === 'GRAY'
                      ? 'images/goods/tshirts/gray.jpg'
                      : this.state.color === 'PINK'
                      ? 'images/goods/tshirts/pink.jpg'
                      : 'images/goods/tshirts/white.jpg')}
                    onClick={() => {
                      this.setState({ selected: null });
                    }}
                    className="custom-background"
                  />

                  {this.state.custom_list.map((element, key) => {
                    return element.type === true ? (
                      <PositionableContainer
                        snapTo={1}
                        key={key}
                        className="custom-box-ex"
                        //disabled={this.state.tmp}
                        disabled={
                          element.deleted ||
                          this.state.selected !== element.index
                        }
                        movable
                        resizable={['ne', 'nw', 'se', 'sw']}
                        rotatable
                        position={element.position}
                        onClick={() => {
                          if (this.state.selected === element.index)
                            this.setState({ selected: null });
                          else this.setState({ selected: element.index });
                        }}
                        onUpdate={(position) => {
                          let { custom_list } = this.state;
                          custom_list[element.index].position = position;
                          this.setState({
                            custom_list,
                          });
                        }}
                      >
                        <Image
                          src={element.url}
                          style={{
                            display: element.deleted ? 'none' : 'block',
                            maxWidth: '100%',
                            maxHeight: '100%',
                            objectFit: 'contain',
                          }}
                        />
                      </PositionableContainer>
                    ) : (
                      // <Draggable key={key} handle={"#drag" + element.index}>
                      //   <div className="custom-image-wrapper"
                      //     onMouseOver={() => {
                      //       let custom_list = this.state.custom_list;
                      //       custom_list[element.index].onHover = true;
                      //       this.setState({ custom_list });
                      //     }}
                      //     onMouseOut={() => {
                      //       let custom_list = this.state.custom_list;
                      //       custom_list[element.index].onHover = false;
                      //       this.setState({ custom_list });
                      //     }}
                      //     style={element.deleted ? { display: "none" } : {}}>
                      //     <Image src={element.url}
                      //       className="custom-center"
                      //       style={{ width: element.ratio * 100 + "px", height: element.ratio * 100 + "px" }}

                      //     />
                      //     <div
                      //       className="controller-container"
                      //       style={!element.onHover ? { display: 'none' } : {}}
                      //     //style={this.state.endOfCustom?{display:'none', width:'100%'}:{width:'100%'}}
                      //     >
                      //       <FaMinus
                      //         className="pointer"
                      //         size={"13px"}
                      //         onClick={() => this.resize(false, element.index)}
                      //       />
                      //       <FaPlus
                      //         className="pointer"
                      //         size={"13px"}
                      //         onClick={() => this.resize(true, element.index)}
                      //       />
                      //       <FaArrowsAlt size={"13px"} id={"drag" + element.index} className="pointer" />
                      //       <FaTimes size={"13px"} className="pointer" onClick={() => { this.onArtworkDelete(element.index) }} />
                      //     </div>
                      //   </div>
                      // </Draggable>

                      <PositionableContainer
                        snapTo={1}
                        key={key}
                        className="custom-box-ex"
                        //disabled={this.state.tmp}
                        disabled={
                          element.deleted ||
                          this.state.selected !== element.index
                        }
                        movable
                        resizable={['ne', 'nw', 'se', 'sw']}
                        rotatable
                        position={element.position}
                        onClick={() => {
                          if (this.state.selected === element.index)
                            this.setState({ selected: null });
                          else this.setState({ selected: element.index });
                        }}
                        onUpdate={(position) => {
                          console.log(position);
                          let { custom_list } = this.state;
                          custom_list[element.index].position = position;
                          this.setState({
                            custom_list,
                          });
                        }}
                      >
                        <div
                          style={{
                            color: 'black',
                            fontSize:
                              (
                                parseInt(
                                  element.position.height.replace('px', ''),
                                ) * 0.5
                              ).toString() + 'px',
                            fontWeight: '500',
                            display: element.deleted ? 'none' : 'block',
                          }}
                        >
                          {element.text}
                        </div>
                      </PositionableContainer>
                    );
                  })}
                </div>
              </div>
              <textarea
                className="custom-demand"
                rows={20}
                value={this.state.demand}
                placeholder="상세한 요구사항을 적어주세요."
                onChange={(e) => this.setState({ demand: e.target.value })}
              />
            </Col>
          ) : (
            <div />
          )}
          {this.state.category !== null && this.state.color !== null ? (
            <Col xs={12} className="custom-step from-bottom-animation">
              <div className="custom-step-header">4. 견적표</div>
              <div className="custom-step-container">
                <div className="custom-total">
                  <div className="header">베이스</div>
                  <div className="custom-total-element">
                    <div className="flex1">1</div>
                    <div className="flex4">
                      {totalInfo.base === 0 ? '무지 반팔 티셔츠' : '에코백'}
                    </div>
                    <div className="flex1 justify-content-end">
                      {totalInfo.base === 0 ? '99,000' : '150,000'}원
                    </div>
                  </div>

                  {totalInfo.artworks.length > 0 && (
                    <div className="header">아트워크</div>
                  )}
                  {totalInfo.artworks.map((element, key) => {
                    return (
                      <div className="custom-total-element">
                        <div className="flex1">{key + 1}</div>
                        <div className="flex2">
                          <Image
                            src={element.url}
                            style={{
                              width: '150px',
                              height: '150px',
                              objectFit: 'contain',
                            }}
                          />
                        </div>
                        <div className="flex2">{element.title}</div>
                        <div className="flex1 justify-content-end">
                          5,000 원
                        </div>
                      </div>
                    );
                  })}
                  {totalInfo.text.length > 0 && (
                    <div className="header">문구</div>
                  )}
                  {totalInfo.text.map((element, key) => {
                    return (
                      <div className="custom-total-element">
                        <div className="flex1">{key + 1}</div>
                        <div className="flex4">{element.text}</div>
                        <div className="flex1 justify-content-end">
                          5,000 원
                        </div>
                      </div>
                    );
                  })}

                  <div className="footer">
                    {totalInfo.base === 0 ? '99,000' : '150,000'} + 5,000 x{' '}
                    {totalInfo.artworks.length} + 5,000 x{' '}
                    {totalInfo.text.length} ={' '}
                    {this.displayWon(
                      5000 * totalInfo.artworks.length +
                        5000 * totalInfo.text.length +
                        (totalInfo.base === 0 ? 99000 : 150000),
                    )}{' '}
                    원
                  </div>
                </div>
              </div>
              <div className="add-cart-wrapper">
                <div
                  className="next-btn pointer"
                  onClick={() =>
                    this.saveme(
                      5000 * totalInfo.artworks.length +
                        5000 * totalInfo.text.length +
                        (totalInfo.base === 0 ? 99000 : 150000),
                    )
                  }
                >
                  장바구니에 추가
                </div>
              </div>
            </Col>
          ) : (
            <div />
          )}
        </Row>
      </Container>
    );
  }
}
export default Custom;
