import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import { Container, Row, Spinner } from 'react-bootstrap';
import Modal from 'react-modal';
import firebase from '../Utils/Firebase';
import '../style.scss';
import 'style.scss';
import './Login.scss';

import { observer, inject } from 'mobx-react';

var firebaseui = require('firebaseui');
var ui = new firebaseui.auth.AuthUI(firebase.auth());

var uiConfig = {
  callbacks: {
    signInSuccessWithAuthResult: function (authResult, redirectUrl) {
      // User successfully signed in.
      // Return type determines whether we continue the redirect automatically
      // or whether we leave that to developer to handle.
      return true;
    },
    uiShown: function () {
      // The widget is rendered.
      // Hide the loader.
      document.getElementById('loader').style.display = 'none';
    },
  },
  // Will use popup for IDP Providers sign-in flow instead of the default, redirect.
  signInFlow: 'popup',
  signInSuccessUrl: '<url-to-redirect-to-on-success>',
  signInOptions: [
    // Leave the lines as is for the providers you want to offer your users.
    firebase.auth.GoogleAuthProvider.PROVIDER_ID,
    firebase.auth.FacebookAuthProvider.PROVIDER_ID,
    firebase.auth.EmailAuthProvider.PROVIDER_ID,
  ],
  // Terms of service url.
  tosUrl: '<your-tos-url>',
  // Privacy policy url.
  privacyPolicyUrl: '<your-privacy-policy-url>',
};
ui.start('#firebaseui-auth-container', uiConfig);

@inject('store')
@observer
class LoginForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isSubmitted: false,
      isLogged: false,
      isOpen: false,
      email: '',
      password: '',
      error: false,
      clicked: false,
      validated: false,
    };
  }

  handleLogin = async () => {
    this.setState({ isSubmitted: true });
    let error = false;
    await firebase
      .auth()
      .signInWithEmailAndPassword(this.state.email, this.state.password)
      .then(() => {
        this.props.store.user.successLogin(this.state.email);
        this.setState({ isLogged: true });
        setTimeout(() => {
          this.props.callback();
        }, 3000);
      })
      .catch(function (err) {
        error = true;
        console.log('err' + err);
      });
    if (error) this.setState({ error, isSubmitted: false });

    // //for speed
  };

  componentWillReceiveProps = (nextProps) => {
    this.setState({ isOpen: nextProps.isOpen });
  };
  handleChange = (event) => {
    this.setState({
      [event.target.id]: event.target.value,
    });
  };
  checkLoginValidate() {
    firebase
      .auth()
      .signInWithEmailAndPassword(this.state.email, this.state.password)
      .catch(function (error) {
        // Handle Errors here.
        // var errorCode = error.code;
        // var errorMessage = error.message;
        // ...
      });
  }

  render() {
    return (
      <Modal
        isOpen={this.state.isOpen}
        onRequestClose={() => {
          this.props.callback();
        }}
        className="signin-modal"
        overlayClassName="modal-overlay"
      >
        <Container fluid style={{ padding: 0 }}>
          <Row className="modal-container">
            {this.state.isLogged ? (
              <div className="success-login">로그인 되었습니다</div>
            ) : this.state.isSubmitted ? (
              <div style={{ height: '40px' }}>
                <Spinner animation={'border'} />
              </div>
            ) : (
              <Fragment>
                <Row className="titleWrap">
                  <div className="title">
                    <span className="member">Login</span>
                  </div>
                </Row>

                <div className="loginWrap">
                  <div className="loginInput">
                    <input
                      placeholder="이메일"
                      id="email"
                      value={this.state.email}
                      type="email"
                      onChange={(e) => this.handleChange(e)}
                    ></input>
                  </div>
                  <div className="loginInput">
                    <input
                      type="password"
                      id="password"
                      placeholder="비밀번호"
                      value={this.state.password}
                      onChange={(e) => this.handleChange(e)}
                      onKeyPress={(event) => {
                        if (event.key === 'Enter') {
                          this.handleLogin(event);
                        }
                      }}
                    ></input>
                  </div>

                  {this.state.error && (
                    <p className="notValid2">
                      Please check your id or password...
                    </p>
                  )}

                  <button
                    className="loginButton"
                    onClick={() => {
                      this.handleLogin();
                    }}
                  >
                    로그인
                  </button>
                  <Link
                    to="/signup"
                    className="loginButton"
                    onClick={() => this.props.callback()}
                  >
                    회원가입
                  </Link>
                </div>
              </Fragment>
            )}
          </Row>
        </Container>
      </Modal>
      // <Container className="Wrap">
      //   {!firebase.auth().currentUser ? (
      //     <Container>
      //       <Row className="titleWrap">
      //         <div className="title">
      //           <span className="member">
      //             LOGIN
      //                   </span>
      //         </div>
      //       </Row>
      //       <Row>
      //         <div className="loginWrap">
      //           <div className="loginInput">
      //             <label>
      //               <input placeholder="EMAIL" value={this.state.email} type="email" onChange={this.handleChange}></input>
      //             </label>
      //           </div>
      //           <div className="loginInput">
      //             <label>
      //               <input type="password" placeholder="PASSWORD" value={this.state.password} onChange={this.handleChange} onKeyPress={event => {
      //                 if (event.key === 'Enter') {
      //                   this.handleLogin(event);
      //                 }
      //               }}></input>
      //             </label>
      //           </div>
      //           <div>
      //             {this.state.error && <p className="notValid2">로그인 정보를 확인하세요</p>}
      //             {this.state.isSubmitted && <Spinner animation={"border"} />}
      //             <button className="loginButton" onClick={this.handleLogin} >LOGIN</button>
      //             <Link to="/signup" className="loginButton">SIGN UP</Link>
      //           </div>
      //         </div>
      //       </Row>
      //       {/* <Row >
      //         <div className="loginWrap">
      //           <p className="signMsg"> 처음 방문하셨나요? </p>

      //           <p className="signMsg"> Email 혹은 비밀번호를 잊으셨나요?  </p>
      //                       <a className="signInBtn">Find Email/Password</a>
      //         </div>
      //       </Row> */}
      //     </Container>
      //   ) : (
      //       <Redirect to="/" />
      //     )}
      // </Container>
    );
  }
}
export default LoginForm;
