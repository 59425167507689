import React, { Component } from 'react';
import { LoginForm } from 'components';
import { Container } from 'react-bootstrap';
class Login extends Component {
  render() {
    return (
      <Container fluid>
        <LoginForm></LoginForm>
      </Container>
    );
  }
}
export default Login;
