import React, { Component, Fragment } from 'react';
import { Col, Spinner } from 'react-bootstrap';
import { Database, Storage } from 'components';
import { TiFolderOpen } from 'react-icons/ti';
import { WithContext as ReactTags } from 'react-tag-input';
import { FiX, FiRotateCw, FiChevronUp, FiChevronDown } from 'react-icons/fi';

import 'components/style.scss';
import 'style.scss';
import { observer, inject } from 'mobx-react';

const KeyCodes = {
  comma: 188,
  enter: 13,
  space: 32,
};

const delimiters = [KeyCodes.comma, KeyCodes.enter, KeyCodes.space];

@inject('store')
@observer
class UploadGoods extends Component {
  constructor(props) {
    super(props);
    this.state = {
      title: '',
      eng_title: '',
      description: '',
      bottom_text: '',
      onSale: false, // 판매중인지
      type: 0, // 기타:0, 티셔츠:1, 신발:2, 에코백:3
      price: 0,
      quantities: 1,
      status: false,
      mainPreview: [],
      preview: [],
      key: null,
      tags: [],
      suggestions: [],
      priority: 0,
    };
    this.handleDelete = this.handleDelete.bind(this);
    this.handleAddition = this.handleAddition.bind(this);
  }
  displayWon = (src) => {
    let tmp = src;
    if (src !== undefined) {
      if (typeof src === 'string') {
        tmp = src.replace(/,/gi, '');
        tmp = tmp.replace(/\s/gi, '');
      }
      tmp = tmp.toString();
      let index = tmp.length;
      while (1) {
        index -= 3;
        if (index < 1) return tmp;
        tmp = tmp.slice(0, index) + ',' + tmp.slice(index, tmp.length);
      }
    }
  };
  handleDelete(i) {
    const { tags } = this.state;
    this.setState({
      tags: tags.filter((tag, index) => index !== i),
    });
  }

  handleAddition(tag) {
    let tmp = tag;
    tag.text = '#' + tag.text;
    this.setState((state) => ({ tags: [...state.tags, tmp] }));
  }

  // displayDate(date) {
  //     var date = new Date();
  //     var year = date.getFullYear();
  //     var month = date.getMonth() + 1
  //     var day = date.getDate();
  //     if (month < 10) {
  //         month = "0" + month;
  //     }
  //     if (day < 10) {
  //         day = "0" + day;
  //     }

  //     return (year + "-" + month + "-" + day);

  // }
  componentDidMount = () => {
    let hashtags = Database.collection('Hashtags_goods');
    hashtags
      .get()
      .then(async (querySnapshot) => {
        let hashlist = [];
        await querySnapshot.forEach((doc) => {
          hashlist.push(doc.data());
        });
        this.setState({ suggestions: hashlist });
      })
      .catch((error) => {
        console.log('Error : ', error);
      });
  };
  displayButton = (flag) => {
    return flag ? (
      <div className="inputbox-button" style={{ borderColor: '#326bb5' }}>
        <div className="activated" />
      </div>
    ) : (
      <div className="inputbox-button" />
    );
  };
  upload = () => {
    let hashtags = {};
    let comments = [];
    for (let i = 0; i < this.state.tags.length; i++) {
      hashtags[this.state.tags[i].id] = true;
    }
    for (let i = 0; i < this.state.preview.length; i++) {
      comments.push(this.state.preview[i].comment);
    }
    Database.collection('Goods')
      .add({
        description: this.state.description.replace(/\n/gi, '<br/>'),
        bottom_text: this.state.bottom_text.replace(/\n/gi, '<br/>'),
        on_sale: this.state.onSale,
        title: this.state.title,
        eng_title: this.state.eng_title,
        type: this.state.type,
        price: this.state.price,
        quantities: this.state.quantities,
        priority: parseInt(this.state.priority),
        comments,
        hashtags,
        uploaded: false,
      })
      .then(async (res) => {
        const key = res._key.path.segments[1];
        this.setState({ key });
        let hashtagsRefs = Database.collection('Hashtags_goods');
        this.state.tags.forEach((tag) => {
          hashtagsRefs
            .doc(tag.id)
            .get()
            .then((doc) => {
              if (doc.exists) {
                let { id, text, count } = doc.data();
                hashtagsRefs
                  .doc(tag.id)
                  .update({
                    id,
                    text,
                    count: count + 1,
                  })
                  .catch((err) => {
                    console.log('@update' + err);
                  });
              } else {
                hashtagsRefs
                  .doc(tag.id)
                  .set({
                    id: tag.id,
                    text: tag.text,
                    count: 1,
                  })
                  .catch((err) => {
                    console.log('@add' + err);
                  });
              }
            })
            .catch((err) => {
              console.log('@get' + err);
            });
        });
        let goodsRef = Storage.ref('goods/' + key);
        let imgArr = this.state.preview.slice();
        imgArr.unshift(this.state.mainPreview[0]);
        for (let i = 0; i < imgArr.length; i++) {
          let path = goodsRef.child('/' + i);
          await path
            .put(imgArr[i].imageValue)
            .then((snapshot) => {})
            .catch((err) => {
              this.setState({ status: false });
              console.log(err);
            });
        }
        Database.collection('Goods').doc(this.state.key).update({
          num_image: imgArr.length,
        });
      })
      .then(() => {
        Database.collection('Goods')
          .doc(this.state.key)
          .update({
            uploaded: true,
          })
          .then(() => {
            this.setState({ status: false });
            this.props.callback();
            alert('업로드 성공');
          });
      })
      .catch((err) => {
        this.setState({ status: false });
        console.log(err);
      });
  };
  load_image = (e, isMain) => {
    const files = e.target.files;
    const filesArr = Array.prototype.slice.call(files);
    filesArr.forEach((file) => {
      const reader = new FileReader();
      // reader.onprogress = e => {
      //     const percent = Math.floor(e.loaded / e.total * 100);
      //     this.setState({ percent });
      // };
      reader.onload = (e) => {
        const image = new Image();
        image.className = isMain ? 'main-img-item' : 'img-item';
        image.src = e.target.result;
        image.onload = (imageEvent) => {
          // Resize the image
          this.resize_image(image, file, isMain);
        };
      };
      reader.readAsDataURL(file);
    });
  };

  resize_image = (image, file, isMain) => {
    let canvas = document.createElement('canvas'),
      max_size = 1280,
      width = image.width,
      height = image.height;

    if (width > height) {
      if (width > max_size) {
        height *= max_size / width;
        width = max_size;
      }
    } else {
      if (height > max_size) {
        width *= max_size / height;
        height = max_size;
      }
    }
    canvas.width = width;
    canvas.height = height;
    canvas.getContext('2d').drawImage(image, 0, 0, width, height);
    const dataUrl = canvas.toDataURL('image/jpeg');
    const imageValue = this.dataURLToBlob(dataUrl);

    if (isMain) {
      this.setState({
        mainPreview: [{ dataUrl, imageValue, type: 'image/jpeg' }],
      });
    } else {
      let preview = this.state.preview;
      preview.push({ dataUrl, imageValue, type: 'image/jpeg', comment: '' });
      this.setState({ preview });
    }
    // const origin_size = Math.floor(file.size / 1000);
    // const re_size = Math.floor(imageValue.size / 1000);
    // const imgW = Math.floor(image.width);
    // const imgH = Math.floor(image.height);
    // const reW = Math.floor(width);
    // const reH = Math.floor(height);
  };
  rotateImage = (item, index, isMain) => {
    let url = item.dataUrl; //type = item.type;
    const image = new Image();
    image.className = 'img-item';

    image.onload = (imageEvent) => {
      let canvas = document.createElement('canvas');
      let w = image.width,
        h = image.height;
      let max = w > h ? w : h;
      canvas.width = max;
      canvas.height = max;
      let ctx = canvas.getContext('2d');
      //ctx.translate(canvas.width/2,canvas.height/2);

      ctx.rotate((90 * Math.PI) / 180);
      ctx.drawImage(image, 0, -image.height);
      ctx.restore();
      var tempCanvas = document.createElement('canvas'),
        tCtx = tempCanvas.getContext('2d');
      tempCanvas.width = h;
      tempCanvas.height = w;
      tCtx.drawImage(canvas, 0, 0);
      tCtx.restore();
      //ctx.drawImage(image,-image.width/2,-image.height/2);
      //ctx.rotate(-Math.PI/2);
      //ctx.translate(-canvas.width/2,-canvas.height/2);
      //ctx.fillRect(0,0,25,10);
      const dataUrl = tempCanvas.toDataURL('image/jpeg');
      const imageValue = this.dataURLToBlob(dataUrl);
      if (isMain) {
        this.setState({
          mainPreview: [{ dataUrl, imageValue, type: 'image/jpeg' }],
        });
      } else {
        let preview = this.state.preview;
        preview[index] = {
          dataUrl,
          imageValue,
          type: 'image/jpeg',
          comment: preview[index].comment,
        };
        this.setState({ preview });
      }
    };
    image.src = url;
  };
  dataURLToBlob = (dataURL) => {
    const BASE64_MARKER = ';base64,';
    if (dataURL.indexOf(BASE64_MARKER) === -1) {
      const parts = dataURL.split(',');
      const contentType = parts[0].split(':')[1];
      const raw = parts[1];
      return new Blob([raw], {
        type: contentType,
      });
    }
    const parts = dataURL.split(BASE64_MARKER);
    const contentType = parts[0].split(':')[1];
    const raw = window.atob(parts[1]);
    const rawLength = raw.length;
    const uInt8Array = new Uint8Array(rawLength);
    let i = 0;
    while (i < rawLength) {
      uInt8Array[i] = raw.charCodeAt(i);
      i++;
    }
    return new Blob([uInt8Array], {
      type: contentType,
    });
  };
  render() {
    return (
      <Col xs={12} className="upload-detail">
        <div className="px-3" style={{ width: '100%' }}>
          <span className="weight900">해시태그</span>
          <br />
          <br />
        </div>
        <div className="pb-3 tag-test">
          <ReactTags
            tags={this.state.tags}
            inline={false}
            suggestions={this.state.suggestions}
            handleDelete={this.handleDelete}
            handleAddition={this.handleAddition}
            // handleDrag={this.handleDrag}
            placeholder={'태그 입력'}
            delimiters={delimiters}
          />
        </div>

        <div className="p-3">
          <span className="weight900">
            제목 {'  (' + this.state.title.length + '/50)'}
          </span>
          <input
            type="text"
            value={this.state.title}
            maxLength={50}
            onChange={(e) => this.setState({ title: e.target.value })}
            className="textbox image-title input-text"
          />
          <span className="weight900">
            제목(영문) {'  (' + this.state.eng_title.length + '/50)'}
          </span>
          <input
            type="text"
            value={this.state.eng_title}
            maxLength={50}
            onChange={(e) => this.setState({ eng_title: e.target.value })}
            className="textbox image-title input-text"
          />
          <span className="weight900">
            상세정보 {'  (' + this.state.description.length + '/1000)'}
          </span>
          <textarea
            className="textbox image-description"
            maxLength={1000}
            onChange={(e) => this.setState({ description: e.target.value })}
            value={this.state.description}
          />
          {/* <span>설명 {'  ('+this.state.description.length+'/1000)'}</span>
                    <textarea className="textbox image-description" maxLength={1000} onChange={(e=>this.setState({description:e.target.value}))} value={this.state.description}/> */}
        </div>

        <div
          className="w100p flex justify-content-center align-items-center p-3"
          style={{ flexDirection: 'column' }}
        >
          <label
            htmlFor="upload-main"
            className="flex justify-content-center align-items-center pointer"
            style={{
              width: '180px',
              height: '50px',
              borderRadius: '10px',
              backgroundColor: '#eee',
              flexDirection: 'column',
              fontSize: '14px',
              fontWeight: '900',
              color: '#333',
            }}
          >
            <div
              className="flex justify-content-center align-items-center"
              style={{ flexDirection: 'row' }}
            >
              <span style={{ marginRight: '20px' }}>메인 이미지 선택</span>
              <TiFolderOpen size={'20px'} style={{ color: '#333' }} />
            </div>
            {/* <ProgressBar style={{ width: "80%", marginTop: "10%", marginBottom: "10%" }} now={this.state.percent} animated />
                        <span>{this.state.percent}%</span> */}
          </label>
          <input
            ref={(ref) => (this.image_input = ref)}
            style={{ display: 'none' }}
            type="file"
            accept="image/*"
            id="upload-main"
            onChange={(e) => {
              this.load_image(e, true);
            }}
          />
          <div
            className="flex align-items-center justify-content-center"
            style={{ flexDirection: 'row', width: '90%', padding: '1vw' }}
          >
            {this.state.mainPreview.map((element, key) => {
              return (
                <div className="div-item" key={key}>
                  <div>
                    <img
                      alt=""
                      src={element.dataUrl}
                      className="main-img-item"
                    />
                  </div>
                  <div className="image-icon-box">
                    <div
                      className="pointer custom-icon"
                      onClick={() => {
                        this.rotateImage(element, key, true);
                      }}
                    >
                      <FiRotateCw size={'20px'} style={{ color: '#333' }} />
                    </div>
                    <div
                      className="pointer custom-icon"
                      onClick={() => {
                        this.setState({ mainPreview: [] });
                      }}
                    >
                      <FiX size={'20px'} style={{ color: '#333' }} />
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
        <div
          className="w100p flex justify-content-center align-items-center p-3"
          style={{ flexDirection: 'column' }}
        >
          <label
            htmlFor="upload-image"
            className="flex justify-content-center align-items-center pointer"
            style={{
              width: '180px',
              height: '50px',
              borderRadius: '10px',
              backgroundColor: '#eee',
              flexDirection: 'column',
              fontSize: '14px',
              fontWeight: '700',
              color: '#333',
            }}
          >
            <div
              className="flex justify-content-center align-items-center"
              style={{ flexDirection: 'row' }}
            >
              <span style={{ marginRight: '20px' }}>상세 이미지 선택</span>
              <TiFolderOpen size={'20px'} style={{ color: '#333' }} />
            </div>
            {/* <ProgressBar style={{ width: "80%", marginTop: "10%", marginBottom: "10%" }} now={this.state.percent} animated />
                        <span>{this.state.percent}%</span> */}
          </label>
          <input
            ref={(ref) => (this.image_input = ref)}
            style={{ display: 'none' }}
            type="file"
            accept="image/*"
            id="upload-image"
            multiple
            onChange={(e) => {
              this.load_image(e, false);
            }}
          />
          <div className="scroll-hidden preview-div">
            {this.state.preview.map((element, key) => {
              return (
                <Fragment>
                  <div className="div-item" key={key}>
                    <div className="item-container">
                      <img alt="" src={element.dataUrl} className="img-item" />
                    </div>
                    <div className="image-icon-box">
                      <div
                        className="pointer custom-icon"
                        onClick={() => {
                          let preview = this.state.preview.slice();
                          preview.splice(key, 1);
                          this.setState({ preview });
                        }}
                      >
                        <FiX size={'15px'} style={{ color: '#333' }} />
                      </div>
                      <div
                        className="pointer custom-icon"
                        onClick={() => {
                          this.rotateImage(element, key, false);
                        }}
                      >
                        <FiRotateCw size={'15px'} style={{ color: '#333' }} />
                      </div>
                      <div
                        className="pointer custom-icon"
                        onClick={() => {
                          if (key !== 0) {
                            let preview = this.state.preview.slice();
                            let item = preview[key];
                            preview.splice(key, 1);
                            preview.splice(key - 1, 0, item);
                            this.setState({ preview });
                          }
                        }}
                      >
                        <FiChevronUp size={'15px'} style={{ color: '#333' }} />
                      </div>
                      <div
                        className="pointer custom-icon"
                        onClick={() => {
                          if (key !== this.state.preview.length - 1) {
                            let preview = this.state.preview.slice();
                            let item = preview[key];
                            preview.splice(key, 1);
                            preview.splice(key + 1, 0, item);
                            this.setState({ preview });
                          }
                        }}
                      >
                        <FiChevronDown
                          size={'15px'}
                          style={{ color: '#333' }}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="image-text">
                    <textarea
                      type="text"
                      maxLength={1000}
                      onChange={(e) => {
                        let preview = this.state.preview;
                        preview[key].comment = e.target.value;
                        this.setState({ preview });
                      }}
                      value={this.state.preview[key].comment}
                      className="image-text-input"
                    />
                  </div>
                </Fragment>
              );
            })}
          </div>
        </div>

        <div className="p-3">
          <span className="weight900">판매여부</span>
          <div className="inputbox-container py-2">
            <div
              className="inputbox-element"
              onClick={() => {
                this.setState({ onSale: true });
              }}
            >
              {this.displayButton(this.state.onSale)}
              <span
                className="btn-text"
                style={
                  this.state.onSale ? { color: '#326bb5', fontWeight: 500 } : {}
                }
              >
                판매
              </span>
            </div>
            <div
              className="inputbox-element"
              onClick={() => {
                this.setState({ onSale: false });
              }}
            >
              {this.displayButton(!this.state.onSale)}
              <span
                className="btn-text"
                style={
                  !this.state.onSale
                    ? { color: '#326bb5', fontWeight: 500 }
                    : {}
                }
              >
                판매하지 않음
              </span>
            </div>
          </div>

          <span className="weight900">가격</span>
          <input
            type="number"
            value={this.state.price}
            onChange={(e) => this.setState({ price: e.target.value })}
            className="textbox numberbox input-text"
          />
          <span className="weight900">원</span>
          <br />

          <span className="weight900">수량</span>
          <input
            type="number"
            value={this.state.quantities}
            onChange={(e) => this.setState({ quantities: e.target.value })}
            className="textbox numberbox input-text"
          />
          <span className="weight900">개</span>
          <br />

          <span className="weight900">하이라이트</span>
          <input
            type="number"
            value={this.state.priority}
            onChange={(e) => this.setState({ priority: e.target.value })}
            className="textbox numberbox input-text"
          />
          <span className="weight900"></span>
          <br />

          <span className="weight900">종류</span>
          <div className="inputbox-container">
            <div
              className="inputbox-element"
              onClick={() => {
                this.setState({ type: 1 });
              }}
            >
              {this.displayButton(this.state.type === 1)}
              <span
                className="btn-text"
                style={
                  this.state.type === 1
                    ? { color: '#326bb5', fontWeight: 500 }
                    : {}
                }
              >
                티셔츠
              </span>
            </div>
            <div
              className="inputbox-element"
              onClick={() => {
                this.setState({ type: 2 });
              }}
            >
              {this.displayButton(this.state.type === 2)}
              <span
                className="btn-text"
                style={
                  this.state.type === 2
                    ? { color: '#326bb5', fontWeight: 500 }
                    : {}
                }
              >
                신발
              </span>
            </div>
            <div
              className="inputbox-element"
              onClick={() => {
                this.setState({ type: 3 });
              }}
            >
              {this.displayButton(this.state.type === 3)}
              <span
                className="btn-text"
                style={
                  this.state.type === 3
                    ? { color: '#326bb5', fontWeight: 500 }
                    : {}
                }
              >
                에코백
              </span>
            </div>
            <div
              className="inputbox-element"
              onClick={() => {
                this.setState({ type: 4 });
              }}
            >
              {this.displayButton(this.state.type === 4)}
              <span
                className="btn-text"
                style={
                  this.state.type === 4
                    ? { color: '#326bb5', fontWeight: 500 }
                    : {}
                }
              >
                모자
              </span>
            </div>

            <div
              className="inputbox-element"
              onClick={() => {
                this.setState({ type: 0 });
              }}
            >
              {this.displayButton(this.state.type === 0)}
              <span
                className="btn-text"
                style={
                  this.state.type === 0
                    ? { color: '#326bb5', fontWeight: 500 }
                    : {}
                }
              >
                기타
              </span>
            </div>
          </div>
        </div>
        <div className="p-3">
          <span className="weight900">
            하단 글 {'  (' + this.state.bottom_text.length + '/1000)'}
          </span>
          <textarea
            className="textbox image-description"
            maxLength={1000}
            onChange={(e) => this.setState({ bottom_text: e.target.value })}
            value={this.state.bottom_text}
          />
          {/* <span>설명 {'  ('+this.state.description.length+'/1000)'}</span>
                    <textarea className="textbox image-description" maxLength={1000} onChange={(e=>this.setState({description:e.target.value}))} value={this.state.description}/> */}
        </div>

        {this.state.status ? (
          <div className="h100px border-top flex justify-content-center align-items-center w100p">
            <Spinner animation={'border'} />
          </div>
        ) : (
          <div
            onClick={() => {
              this.setState({ status: true });
              this.upload();
            }}
            className="h100px border-top pointer"
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              width: '100%',
            }}
          >
            <span className="weight900">업로드하기</span>
          </div>
        )}
      </Col>
    );
  }
}
export default UploadGoods;
