import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import test from '../../images/home/ContactUs.png';
import { FaWindowClose } from 'react-icons/fa';

const PopupContainer = styled.div`
  box-sizing: border-box;
  position: fixed;
  top: 20vh;
  left: 20vw;
  width: 50vw;
  z-index: 9999;
  object-fit: cover;
  outline: 0;
`;
const PopupImage = styled.img`
  width: 90%;
  display: block;
  margin: auto;
`;

export default function FooterPopup(props) {
  const [popup, setPopup] = useState(false);
  const closePopup = () => setPopup(false);

  useEffect(() => {
    if (props.popup === true) setPopup(true);
  }, [props]);

  return (
    <>
      {popup ? (
        <PopupContainer>
          <FaWindowClose size={20} onClick={closePopup} />
          <PopupImage src={test} alt="popup image" />
        </PopupContainer>
      ) : (
        ''
      )}
    </>
  );
}
