import React, { Component } from 'react';
import './NoticeCss.scss';
import { Row, Col, Container } from 'react-bootstrap';
import ScrollAnimation from 'react-animate-on-scroll';

class NoticeClass extends Component {
  render() {
    return (
      <Container fluid>
        <div className="header-container">
          <div className="header-title">CLASS</div>
        </div>
        <Row style={{ display: 'block' }} className="consult-row">
          <ScrollAnimation animateOnce={true} animateIn="from-bottom-animation">
            <Col xs={12} md={4} className="blue-hover card-Style between">
              <a href="https://event-us.kr/zibezi/event/8294">
                <div className="image-container-class">
                  <img
                    src={require(`images/notice/class_adult.jpg`)}
                    className="notice-card-image"
                    alt=""
                  ></img>
                </div>
              </a>
              <div className="text-box">
                <h1 className="card-title">Art for Adults</h1>
                <p className="card-desc">
                  정재훈 작가와 함께 다양한 선을 이용하여 물체를 캐릭터화 또는
                  패턴화 시켜 캔버스에 담아볼 수 있는 시간입니다.
                  <br /> 작가와 함께 나만의 작품을 만들어 보세요. 나만의
                  아이콘과 소중한 추억도 만들어 보세요{' '}
                </p>
              </div>
            </Col>
            <Col xs={12} md={4} className="blue-hover card-Style between">
              <a href="https://event-us.kr/zibezi/event/8294">
                <div className="image-container-class">
                  <img
                    src={require(`images/artClassChildren.jpg`)}
                    className="notice-card-image"
                    alt=""
                  ></img>
                </div>
              </a>
              <div className="text-box">
                <h1 className="card-title">Art for Children</h1>

                <p className="card-desc">
                  정재훈 작가와 함께 동심의 순수로 바라본 이미지들을 캐릭터화
                  또는 패턴화 시켜 캔버스에 담아볼 수 있는 시간입니다. <br />
                  학업에 지친 아이들에게,창의력과 오감발달을 할 수 있는 소중한
                  시간을 아이들에게 선물해주세요
                </p>
              </div>
            </Col>
            <Col xs={12} md={4} className="blue-hover card-Style between">
              <a href="https://event-us.kr/zibezi/event/8294">
                <div className="image-container-class">
                  <img
                    src={require(`images/notice/class_college.jpg`)}
                    className="notice-card-image"
                    alt=""
                  ></img>
                </div>
              </a>
              <div className="text-box">
                <h1 className="card-title">Art for Groups</h1>

                <p className="card-desc">
                  기업, 대학생, 동호회 등 그룹을 모아 신청할 수 있습니다. 각종
                  행사 및 레크레이션 시간 활용으로 아주 적합합니다.
                  <br /> 작가와 함께 나만의 작품을 만들어보세요. 단체 아트클래스
                  시간으로 소중한 추억을 만들어보세요.
                </p>
              </div>
            </Col>
          </ScrollAnimation>
        </Row>
      </Container>
    );
  }
}
export default NoticeClass;
