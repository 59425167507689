import Firebase from './Firebase';
const db = Firebase.firestore();
export default db;

// import {Database} from 'components';

//1. id설정 안하고 데이터 추가 - add
// Database.collection("테이블이름").add({
//     attr1:"",
//     attr2:"",
//     attr3:""
// })

//2. id설정 하고 데이터 추가 - set
// Database.collection("테이블이름").doc("id").set({
//     attr1:"",
//     attr2:"",
//     attr3:""
// })

//3. 데이터 업데이트 - update
// Database.collection("테이블 이름").doc("id").update({
//     attr1:"",
//     attr2:"",
//     attr3:"",
// })

//4. 특정 데이터 하나 불러오기 - get
// let docRef = Database.collection("테이블이름").doc("id");
// docRef.get().then((doc)=>{
//    if(doc.exists){
//         //해당 데이터는 doc.data()에서 json형식으로 확인
//         console.log(doc.data())
//    } else{
//         //해당하는 데이터 없을 때 처리
//    }
// })

//5. 해당하는 여러 데이터 불러오기 - where
// let docRefs = Database.collection("테이블이름");
// docRefs.where("email", "==", "pmw1130@gmail.com");
// docRefs.where("name", "==", "박명욱");
// docRefs.get()
// 위에 세줄은 한줄로 써도 됨 ex) docRefs.where("email", "==", "pmw1130@gmail.com").where("name", "==", "박명욱").get()
//     .then(function(querySnapshot) {
//         querySnapshot.forEach((doc) => {
//             console.log(doc.data());
//         });
//     })
//     .catch(function(error) {
//         console.log("Error getting documents: ", error);
//     });
