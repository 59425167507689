import React, { useState, useEffect } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import { ShopHeader, UploadModal, Firebase as firebase } from 'components';
import 'components/style.scss';
import 'style.scss';
import { observer, useObserver } from 'mobx-react';
import useStore from '../../../stores/useStore';
import WorksPerPage from './WorksPerPage';
import ReactPaginate from 'react-paginate';

const Pagination = observer((props) => {
  const { Works } = useStore();
  const { User } = useStore();
  const [works, setWorks] = useState([]);
  const [displayed, setDisplayed] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [worksPerPage, setWorksPerPage] = useState(12);
  const [upload, setUpload] = useState(false);
  const [loading, setLoading] = useState(false);
  const [tab, setTab] = useState(false);
  // const [allWorks, setAllWorks] = useState();
  // const download = () => {
  //   console.log(download);
  //   if (allWorks) {
  //     console.log('start', allWorks);
  //     const a = document.createElement('a');
  //     a.href = URL.createObjectURL(
  //       new Blob([JSON.stringify(allWorks, null, 2)], {
  //         type: 'application/json',
  //       }),
  //     );
  //     a.setAttribute('download', 'data.txt');
  //     document.body.appendChild(a);
  //     a.click();
  //     document.body.removeChild(a);
  //   }
  // };
  useEffect(() => {
    getData();
    async function getData() {
      //첫 로딩이면
      if (Works.allArtworks.length === 0) {
        Works.setLoading(true);
        const firstArtworks = new Promise(function (resolve, reject) {
          resolve(Works.getFirstArtworks());
        });
        await firstArtworks.then((res) => {
          setDisplayed(
            res.slice(
              Works.currentPage * worksPerPage,
              Works.currentPage * worksPerPage + worksPerPage,
            ),
          );
          setTimeout(() => {
            Works.setLoading(false);
          }, 1500);
        });

        const allArtworks = new Promise(function (resolve, reject) {
          resolve(Works.getAllArtworks());
        });
        await allArtworks.then((res) => {
          setCurrentPage(Works.currentPage);
          setWorks(res);
        });
      }
      //첫 로딩이 아니면,
      else {
        Works.setLoading(true);
        const allArtworks = new Promise(function (resolve, reject) {
          resolve(Works.getAllArtworks());
        });

        allArtworks.then((res) => {
          setCurrentPage(Works.currentPage);
          setWorks(res);
          // setAllWorks(res);

          setDisplayed(
            res.slice(
              Works.currentPage * worksPerPage,
              Works.currentPage * worksPerPage + worksPerPage,
            ),
          );
          Works.setLoading(false);
        });
      }
    }
  }, [Works.currentPage]);

  const indexOfLast = currentPage * worksPerPage;
  const indexOfFirst = indexOfLast - worksPerPage;
  function currentWorks(tmp) {
    let currentWorks = 0;
    currentWorks = tmp.slice(indexOfFirst, indexOfLast);
    return currentWorks;
  }

  const handlePageClick = async (data) => {
    setLoading(true);
    await Works.setCurrentPage(data.selected);
    window.scrollTo(0, 0);
    setLoading(false);
  };

  return useObserver(() => (
    <Container className="artworks-main">
      {/* <button onClick={download}>Export data to local txt file</button> */}

      <Row className="p-3">
        <Col md={2} xs={4}>
          <NavLink
            exact
            to="/shop/pagination"
            onClick={() => {}}
            className="shop-btn"
            activeClassName="shop-btn-highlight"
          >
            <span>Artworks</span>
          </NavLink>
        </Col>
        <Col md={2} xs={4}>
          <NavLink
            exact
            to="/shop/pick"
            onClick={() => {}}
            className="shop-btn"
            activeClassName="shop-btn-highlight"
          >
            <span>ZiBEZI's Pick</span>
          </NavLink>
        </Col>
      </Row>
      <ShopHeader
        description={'The work can be purchased through 1:1 consultation.'}
        description2={'You can enjoy the works through scrolling.'}
      />
      <Row>
        <div className="fadein shop-header">
          <div className="btn-box">
            {firebase.auth().currentUser &&
            firebase.auth().currentUser.email == 'admin@zibezi.com' ? (
              <div className="shop-btn">
                <div
                  onClick={() => {
                    setUpload(true);
                  }}
                  className="pointer "
                >
                  <span>Upload</span>
                </div>
              </div>
            ) : (
              <div />
            )}
          </div>
        </div>
      </Row>
      <UploadModal
        show={upload}
        category={false}
        callback={() => {
          setUpload(false);
        }}
      />
      <div className="display-container">
        <WorksPerPage works={displayed} />
        <div className="paginate-container" disabled={loading}>
          <ReactPaginate
            pageCount={Works.worksNum / worksPerPage}
            pageRangeDisplayed={9}
            marginPagesDisplayed={0}
            initialPage={Works.currentPage}
            breakLabel={'...'}
            previousLabel={'Pre'}
            nextLabel={'Next'}
            onPageChange={handlePageClick}
            containerClassName={'react-paginate'}
            activeClassName={'currentPage'}
            previousClassName={'previousBtn'}
            nextClassName={'nextBtn'}
          />
        </div>
      </div>
    </Container>
  ));
});
export default Pagination;
