import React, { Component } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { Redirect } from 'react-router-dom';
import './payment.scss';
import '../Login/Login.scss';
import { Database } from 'components';
import DaumPostcode from 'react-daum-postcode';
import MyungModal from 'react-modal';
import Modal from 'react-responsive-modal';
import { observer, inject } from 'mobx-react';

const IMP = window.IMP;
IMP.init('imp43107329');

@inject('store')
@observer
class PayGoods extends Component {
  constructor(props) {
    super(props);
    this.state = {
      list: this.props.list.list,
      orderList: [],
      contents: this.props.children,
      name: '',
      email: '',
      priceSum: '',

      addsearch: false,
      zipcode: '',
      address: '',
      address_detail: '',
      receiverName: '',
      receiverNumber: '',
      orderMessage: '',
      shipment: false,
      agreement: false,
      timestap: '',

      isSubmitted: false,
      isNamed: false,
      isZipcoded: false,
      isNumbered: false,
      isAgreeChecked: false,
      isSameMember: false,
      isAllValid: false,
      paymentSuccess: false,
    };
  }

  componentDidMount = () => {
    Database.collection('Users')
      .doc(this.props.store.user.uid)
      .get()
      .then((query) => {
        let data = query.data();
        this.setState({
          name: data.name,
          email: data.email,
          //  address : data.address + ", " +data.address_detail,
          phone: data.phone_number,
        });
      });
  };

  handleAddress = (data) => {
    console.log(data.zonecode);
    let fullAddress = data.address;
    let extraAddress = '';

    if (data.addressType === 'R') {
      if (data.bname !== '') {
        extraAddress += data.bname;
      }
      if (data.buildingName !== '') {
        extraAddress +=
          extraAddress !== '' ? `, ${data.buildingName}` : data.buildingName;
      }
      fullAddress += extraAddress !== '' ? ` (${extraAddress})` : '';
    }
    this.setState({ zipcode: data.zonecode, address: fullAddress });
    if (this.state.zipcode) {
      this.setState({ isZipcoded: true });
    } else {
      this.setState({ isZipcoded: false });
    }
  };

  validateForm() {
    if (this.state.receiverName) {
      this.setState({ isNamed: true });
    } else {
      this.setState({ isNamed: false });
    }
    //zipcode
    if (this.state.zipcode !== '') {
      this.setState({ isZipcoded: true });
    } else {
      this.setState({ isZipcoded: false });
    }

    if (
      this.state.receiverNumber.length === 11 ||
      this.state.receiverNumber.length === 10
    ) {
      this.setState({ isNumbered: true });
    } else {
      this.setState({ isNumbered: false });
    }

    //agree,privacy not needed
    this.setState({ isAllvalid: this.isValidated() });
  }
  isValidated() {
    return (
      this.state.isNamed &&
      this.state.isZipcoded &&
      this.state.isNumbered &&
      this.state.isAgreeChecked
    );
  }

  handleChange = (event) => {
    this.setState({ [event.target.id]: event.target.value });
    switch (event.target.id) {
      case 'receiverName':
        if (event.target.value) {
          this.setState({ isNamed: true });
        } else {
          this.setState({ isNamed: false });
        }
        break;
      case 'receiverNumber':
        if (
          event.target.value.length === 11 ||
          event.target.value.length === 10
        ) {
          this.setState({ isNumbered: true });
        } else {
          this.setState({ isNumbered: false });
        }
        break;
      default:
    }
  };

  handleCheckChange = (event) => {
    this.setState({ [event.target.id]: event.target.checked });
  };

  onClickPay = async () => {
    this.setState({ isSubmitted: true });
    this.validateForm();

    if (this.isValidated()) {
      console.log('all valid');
      const User = this.props.store.user.uid;
      //sum , orderlist

      let sum = 0;
      this.state.list.forEach((item) => {
        sum += parseInt(item.price * item.amount);
        this.state.orderList.push({
          title: item.title,
          amount: item.amount,
          option: item.option,
          demand: item.demand,
        });
      });
      //order name
      let orderName = '';
      let listCount = this.state.list.length - 1;
      if (listCount === 0) orderName = this.state.list[0].title;
      else orderName = this.state.list[0].title + ' 외' + listCount + '종';

      IMP.request_pay(
        {
          pg: 'inicis', // version 1.1.0부터 지원.
          pay_method: 'card',
          merchant_uid: 'merchant_' + this.state.email + new Date().getTime(),
          name: orderName,
          // amount : sum+2500,
          amount: sum + 2500,
          buyer_email: User,
          buyer_name: this.state.name,
          buyer_tel: this.state.receiverNumber,
          buyer_addr: this.state.address + ' ' + this.state.address_detail,
          buyer_postcode: this.state.zipcode,
          custom_data:
            JSON.stringify(this.state.orderList) +
            '&&&' +
            this.state.receiverName +
            '&&&' +
            this.state.orderMessage,
          m_redirect_url: 'https://www.zibezi.com',
        },
        async function (rsp) {
          if (rsp.success) {
            console.log('before' + rsp.custom_data);
            var customdata = rsp.custom_data.split('&&&');
            console.log('later' + customdata);
            var result = true;
            var msg = '결제가 완료되었습니다.';
            var msg2 = '결제가 완료되었습니다, 홈으로 이동합니다.';
            msg += ' 고유ID : ' + rsp.imp_uid;
            msg += ' 상점 거래ID : ' + rsp.merchant_uid;
            msg += ' 결제 금액 : ' + rsp.paid_amount;
            msg += ' 카드 승인번호 : ' + rsp.apply_num;
            msg += ' 구매자 이메일 : ' + rsp.buyer_email;
            msg += ' 보내는사람 : ' + rsp.buyer_name;
            msg += ' 받는사람 :' + customdata[1];
            msg += ' 주소 :' + rsp.buyer_postcode + '/' + rsp.buyer_addr;
            msg += ' 번호 :' + rsp.buyer_tel;
            msg += ' 물품 :' + customdata[0];
            msg += ' 배송메시지 : ' + customdata[2];
            msg += ' 결제시간 : ' + rsp.paid_at;
            //set log
            await Database.collection('Orders')
              .doc(rsp.imp_uid)
              .set({
                IMP_UID: rsp.imp_uid,
                cardApproval: rsp.apply_num,
                buyer_email: rsp.buyer_email,
                receiver: customdata[1],
                phone: rsp.buyer_tel,
                address: rsp.buyer_postcode + '/' + rsp.buyer_addr,
                deliveryMsg: customdata[2],
                payTime: rsp.paid_at,
                amount: rsp.paid_amount,
                orderList: JSON.parse(customdata[0]),
                log: msg,
              });

            // clear cart
            let cartRef = Database.collection('Cart');
            await cartRef
              .where('uid', '==', rsp.buyer_email)
              .get()
              .then(function (querySnapshot) {
                querySnapshot.forEach((doc) => {
                  doc.ref.delete();
                });
              })
              .catch(function (error) {
                console.log('Error Deleting All carts: ', error);
              });
          } else {
            result = false;
            msg2 = '결제에 실패하였습니다.';
            msg2 += '에러내용 : ' + rsp.error_msg;
          }
          this.setState({ paymentSuccess: result });
        }.bind(this),
      );
    } //this.isValidated()
    else {
      console.log('not all valid');
    }
  };

  clearCart = async () => {
    // delete all,
    let cartRef = Database.collection('Cart');
    const uid = this.props.store.user.uid;
    await cartRef
      .where('uid', '==', uid)
      .get()
      .then(function (querySnapshot) {
        querySnapshot.forEach((doc) => {
          doc.ref.delete();
        });
      })
      .catch(function (error) {
        console.log('Error Deleting All carts: ', error);
      });
  };

  onSameMember = async (event) => {
    await this.setState({ [event.target.id]: event.target.checked });
    if (this.state.isSameMember) {
      const userEmail = this.props.store.user.uid;
      let userRefs = Database.collection('Users').doc(userEmail);
      userRefs
        .get()
        .then((doc) => {
          const data = doc.data();

          this.setState({
            receiverName: data.name,
            zipcode: data.zip,
            address: data.address,
            address_detail: data.address_detail,
            receiverNumber: data.phone_number,
          });
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };
  onCloseModal = () => {
    this.setState({ shipment: false });
  };

  render() {
    return (
      <Container fluid className="payWrap">
        <MyungModal
          isOpen={this.state.addsearch}
          onRequestClose={() => {
            this.setState({ addsearch: false });
          }}
          className="address-modal"
          overlayClassName="modal-overlay"
        >
          <Container fluid style={{ padding: 0 }}>
            <Row className="modal-container">
              <DaumPostcode onComplete={this.handleAddress} />
            </Row>
          </Container>
        </MyungModal>
        {!this.state.paymentSuccess ? (
          <Container>
            <Row className="payTitle">
              <h2>Order / Payment</h2>
            </Row>

            <Row className="orderContainer">
              <div className="orderTitle">
                <h3>주문 내역</h3>
              </div>
              <Col md={12} className="orderTable">
                <Col md={2} className="tableCol">
                  <span className="tempText">상품 이미지</span>
                </Col>
                <Col md={4} className="tableCol">
                  <span className="tempText">상품 정보 </span>
                </Col>
                <Col md={3} className="tableCol">
                  <span className="tempText">판매가</span>
                </Col>
                <Col md={3} className="tableCol">
                  <span className="tempText">수량</span>
                </Col>
              </Col>
              {this.state.list.map((element, key) => {
                return (
                  <div key={key} className="orderContent">
                    <Col md={2} className="contentCol">
                      <img
                        src={element.src}
                        className="contentImg"
                        alt=""
                      ></img>
                    </Col>
                    <Col md={4} className="contentCol">
                      <p className="tempText">
                        {element.title}({element.eng_title})
                      </p>
                      {element.option.size && (
                        <p className="tempText">{element.option.size}</p>
                      )}
                      {element.option.color && (
                        <p className="tempText">{element.option.color}</p>
                      )}
                      {element.option.shoes_model && (
                        <p className="tempText">{element.option.shoes_model}</p>
                      )}
                      {element.demand && (
                        <p className="tempText"> : {element.demand}</p>
                      )}
                    </Col>
                    <Col md={3} className="contentCol">
                      <p className="tempText">{element.price}</p>
                    </Col>
                    <Col md={3} className="contentCol">
                      <p className="tempText">{element.amount}</p>
                    </Col>
                  </div>
                );
              })}
            </Row>
            <Row>
              <Col
                style={{ fontSize: '10px', fontWeight: '300' }}
                xs={{ span: 2, offset: 10 }}
              >
                <p>합계 + 배송비(2500) </p>
                <span>총 </span>
                <span>
                  {(() => {
                    let sum = 0;
                    this.state.list.forEach((item) => {
                      sum += parseInt(item.price * item.amount);
                    });
                    //배송비
                    sum += 2500;
                    return ': ' + sum;
                  })()}
                </span>
              </Col>
            </Row>

            <Row className="orderContainer">
              <div className="orderTitle">
                <h3>배송 정보</h3>
                <p className="required tempText">
                  <img
                    src="http://img.echosting.cafe24.com/skin/base_ko_KR/order/ico_required.gif"
                    alt=""
                  />
                  <span className="tempText">필수입력사항</span>
                </p>
                <p className="required tempText">
                  <input
                    id="isSameMember"
                    name="isSameMember"
                    type="checkbox"
                    checked={this.state.isSameMember}
                    onChange={this.onSameMember}
                  ></input>
                  <label for="isSameMember">회원정보와 동일</label>
                </p>
                <p></p>
              </div>
              <div className="orderForm">
                <div className="inputLine">
                  <Col md={3} className="left">
                    <span className="tempText">받으시는분 </span>
                    <img
                      src="http://img.echosting.cafe24.com/skin/base_ko_KR/order/ico_required.gif"
                      alt=""
                    />
                  </Col>
                  <Col md={9} className="right">
                    <input
                      id="receiverName"
                      name="receiverName"
                      value={this.state.receiverName}
                      onChange={this.handleChange}
                      className="inputStyle"
                      placeholder=""
                      size="25"
                      type="text"
                    />
                    {this.state.isSubmitted && !this.state.isNamed && (
                      <p className="notValid">이름을 입력하세요</p>
                    )}
                  </Col>
                </div>

                <div className="inputLine">
                  <Col md={3} className="left">
                    <span className="tempText">주소 </span>
                    <img
                      src="http://img.echosting.cafe24.com/skin/base_ko_KR/order/ico_required.gif"
                      alt=""
                    />
                  </Col>
                  <Col md={9} className="right">
                    <input
                      id="zipcode"
                      name="zipcode"
                      fw-msg=""
                      disabled
                      value={this.state.zipcode}
                      className="inputStyle"
                      placeholder=""
                      size="6"
                      maxlength="6"
                      readonly="1"
                      type="text"
                    />
                    <div
                      onClick={() => {
                        this.setState({ addsearch: true });
                      }}
                      className="pointer searchBtn"
                    >
                      <span className="tempText">우편번호</span>
                    </div>
                    <br />

                    <input
                      id="address"
                      name="address"
                      disabled
                      value={this.state.address}
                      className="inputStyle"
                      placeholder=""
                      size="40"
                      type="text"
                      readonly="1"
                    />
                    <span className="grid tempText">기본주소</span>
                    <br />
                    <input
                      id="address_detail"
                      name="address_detail"
                      value={this.state.address_detail}
                      onChange={this.handleChange}
                      className="inputStyle"
                      placeholder=""
                      size="40"
                    />
                    <span className="grid tempText">나머지주소(선택입력)</span>
                    {this.state.isSubmitted && !this.state.isZipcoded && (
                      <p className="notValid">주소를 입력하세요</p>
                    )}
                  </Col>
                </div>

                <div className="inputLine">
                  <Col md={3} className="left">
                    <span className="tempText">휴대전화(11자리) </span>
                    <img
                      src="http://img.echosting.cafe24.com/skin/base_ko_KR/order/ico_required.gif"
                      alt=""
                    />
                  </Col>
                  <Col md={9} className="right">
                    <input
                      id="receiverNumber"
                      name="receiverNumber"
                      className="inputStyle"
                      placeholder=""
                      size="40"
                      type="text"
                      value={this.state.receiverNumber}
                      onChange={this.handleChange}
                    />
                    {this.state.isSubmitted && !this.state.isNumbered && (
                      <p className="notValid">전화번호를 입력하세요</p>
                    )}
                  </Col>
                </div>

                <div className="inputLine">
                  <Col md={3} className="left">
                    <span className="tempText">배송메시지 </span>
                  </Col>
                  <Col md={9} className="right">
                    <textarea
                      style={{ width: '90%' }}
                      id="orderMessage"
                      name="orderMessage"
                      fw-label="배송 메세지"
                      maxlength="255"
                      cols="70"
                      value={this.state.orderMessage}
                      onChange={this.handleChange}
                    ></textarea>
                  </Col>
                </div>
              </div>
              <div className="agreeContainer">
                <div className="agreeBody">
                  <h3 className="agreeBodyTitle">[필수] 이용약관 동의</h3>
                  <div className="agreeBodyContent">
                    <p className="contents">
                      제1조(목적)
                      <br />
                      이 약관은 ZIBEZI (전자상거래 사업자)가 운영하는 ZIBEZI
                      사이버 몰(이하 “몰”이라 한다)에서 제공하는 인터넷 관련
                      서비스(이하 “서비스”라 한다)를 이용함에 있어 사이버 몰과
                      이용자의 권리, 의무 및 책임사항을 규정함을 목적으로
                      합니다.
                      <br />
                      ※「PC통신, 무선 등을 이용하는 전자상거래에 대해서도 그
                      성질에 반하지 않는 한 이 약관을 준용합니다」
                    </p>
                    <p className="contents">
                      제2조(정의)
                      <br />① “몰”이란 zibezi가 재화 또는 용역(이하 “재화
                      등”이라 함)을 이용자에게 제공하기 위하여 컴퓨터 등
                      정보통신설비를 이용하여 재화 등을 거래할 수 있도록 설정한
                      가상의 영업장을 말하며, 아울러 사이버몰을 운영하는
                      사업자의 의미로도 사용합니다.
                      <br />② “이용자”란 “몰”에 접속하여 이 약관에 따라 “몰”이
                      제공하는 서비스를 받는 회원 및 비회원을 말합니다.
                      <br />③ ‘회원’이라 함은 “몰”에 회원등록을 한 자로서,
                      계속적으로 “몰”이 제공하는 서비스를 이용할 수 있는 자를
                      말합니다.
                      <br />④ ‘비회원’이라 함은 회원에 가입하지 않고 “몰”이
                      제공하는 서비스를 이용하는 자를 말합니다.
                    </p>
                    <p className="contents">
                      제3조 (약관 등의 명시와 설명 및 개정)
                      <br />① “몰”은 이 약관의 내용과 상호 및 대표자 성명,
                      영업소 소재지 주소(소비자의 불만을 처리할 수 있는 곳의
                      주소를 포함), 전화번호,모사전송번호,전자우편주소,
                      사업자등록번호, 통신판매업 신고번호,
                      개인정보보호책임자등을 이용자가 쉽게 알 수 있도록 00
                      사이버몰의 초기 서비스화면(전면)에 게시합니다. 다만,
                      약관의 내용은 이용자가 연결화면을 통하여 볼 수 있도록 할
                      수 있습니다.
                      <br />② “몰은 이용자가 약관에 동의하기에 앞서 약관에
                      정하여져 있는 내용 중 청약철회,배송책임,환불조건 등과 같은
                      중요한 내용을 이용자가 이해할 수 있도록 별도의 연결화면
                      또는 팝업화면 등을 제공하여 이용자의 확인을 구하여야
                      합니다.
                      <br />③ “몰”은 「전자상거래 등에서의 소비자보호에 관한
                      법률」, 「약관의 규제에 관한 법률」, 「전자문서 및
                      전자거래기본법」, 「전자금융거래법」, 「전자서명법」,
                      「정보통신망 이용촉진 및 정보보호 등에 관한 법률」,
                      「방문판매 등에 관한 법률」, 「소비자기본법」 등 관련 법을
                      위배하지 않는 범위에서 이 약관을 개정할 수 있습니다.
                      <br />④ “몰”이 약관을 개정할 경우에는 적용일자 및
                      개정사유를 명시하여 현행약관과 함께 몰의 초기화면에 그
                      적용일자 7일 이전부터 적용일자 전일까지 공지합니다. 다만,
                      이용자에게 불리하게 약관내용을 변경하는 경우에는 최소한
                      30일 이상의 사전 유예기간을 두고 공지합니다. 이 경우
                      “몰“은 개정 전 내용과 개정 후 내용을 명확하게 비교하여
                      이용자가 알기 쉽도록 표시합니다.
                      <br />⑤ “몰”이 약관을 개정할 경우에는 그 개정약관은 그
                      적용일자 이후에 체결되는 계약에만 적용되고 그 이전에 이미
                      체결된 계약에 대해서는 개정 전의 약관조항이 그대로
                      적용됩니다. 다만 이미 계약을 체결한 이용자가 개정약관
                      조항의 적용을 받기를 원하는 뜻을 제3항에 의한 개정약관의
                      공지기간 내에 “몰”에 송신하여 “몰”의 동의를 받은 경우에는
                      개정약관 조항이 적용됩니다.
                      <br />⑥ 이 약관에서 정하지 아니한 사항과 이 약관의 해석에
                      관하여는 전자상거래 등에서의 소비자보호에 관한 법률,
                      약관의 규제 등에 관한 법률, 공정거래위원회가 정하는
                      전자상거래 등에서의 소비자 보호지침 및 관계법령 또는
                      상관례에 따릅니다.
                    </p>
                    <p className="contents">
                      제4조(서비스의 제공 및 변경)
                      <br />① “몰”은 다음과 같은 업무를 수행합니다.
                      <br />
                      &nbsp; 1. 재화 또는 용역에 대한 정보 제공 및 구매계약의
                      체결
                      <br />
                      &nbsp; 2. 구매계약이 체결된 재화 또는 용역의 배송
                      <br />
                      &nbsp; 3. 기타 “몰”이 정하는 업무
                      <br />② “몰”은 재화 또는 용역의 품절 또는 기술적 사양의
                      변경 등의 경우에는 장차 체결되는 계약에 의해 제공할 재화
                      또는 용역의 내용을 변경할 수 있습니다. 이 경우에는 변경된
                      재화 또는 용역의 내용 및 제공일자를 명시하여 현재의 재화
                      또는 용역의 내용을 게시한 곳에 즉시 공지합니다.
                      <br />③ “몰”이 제공하기로 이용자와 계약을 체결한 서비스의
                      내용을 재화등의 품절 또는 기술적 사양의 변경 등의 사유로
                      변경할 경우에는 그 사유를 이용자에게 통지 가능한 주소로
                      즉시 통지합니다.
                      <br />④ 전항의 경우 “몰”은 이로 인하여 이용자가 입은
                      손해를 배상합니다. 다만, “몰”이 고의 또는 과실이 없음을
                      입증하는 경우에는 그러하지 아니합니다.
                    </p>
                    <p className="contents">
                      제5조(서비스의 중단)
                      <br />① “몰”은 컴퓨터 등 정보통신설비의 보수점검 및 교체
                      및 고장, 통신의 두절 등의 사유가 발생한 경우에는 서비스의
                      제공을 일시적으로 중단할 수 있습니다.
                      <br />② “몰”은 제1항의 사유로 서비스의 제공이 일시적으로
                      중단됨으로 인하여 이용자 또는 제3자가 입은 손해에 대하여
                      배상합니다. 단, “몰”이 고의 또는 과실이 없음을 입증하는
                      경우에는 그러하지 아니합니다.
                      <br />③ 사업종목의 전환, 사업의 포기, 업체 간의 통합 등의
                      이유로 서비스를 제공할 수 없게 되는 경우에는 “몰”은
                      제8조에 정한 방법으로 이용자에게 통지하고 당초 “몰”에서
                      제시한 조건에 따라 소비자에게 보상합니다. 다만, “몰”이
                      보상기준 등을 고지하지 아니한 경우에는 이용자들의 마일리지
                      또는 적립금 등을 “몰”에서 통용되는 통화가치에 상응하는
                      현물 또는 현금으로 이용자에게 지급합니다.
                    </p>
                    <p className="contents">
                      제6조(회원가입)
                      <br />① 이용자는 “몰”이 정한 가입 양식에 따라 회원정보를
                      기입한 후 이 약관에 동의한다는 의사표시를 함으로서
                      회원가입을 신청합니다.
                      <br />② “몰”은 제1항과 같이 회원으로 가입할 것을 신청한
                      이용자 중 다음 각 호에 해당하지 않는 한 회원으로
                      등록합니다.
                      <br />
                      &nbsp; 1. 가입신청자가 이 약관 제7조제3항에 의하여 이전에
                      회원자격을 상실한 적이 있는 경우, 다만 제7조제3항에 의한
                      회원자격 상실 후 3년이 경과한 자로서 “몰”의 회원재가입
                      승낙을 얻은 경우에는 예외로 한다.
                      <br />
                      &nbsp; 2. 등록 내용에 허위, 기재누락, 오기가 있는 경우
                      <br />
                      &nbsp; 3. 기타 회원으로 등록하는 것이 “몰”의 기술상 현저히
                      지장이 있다고 판단되는 경우
                      <br />③ 회원가입계약의 성립 시기는 “몰”의 승낙이 회원에게
                      도달한 시점으로 합니다.
                      <br />④ 회원은 회원가입 시 등록한 사항에 변경이 있는 경우,
                      상당한 기간 이내에 “몰”에 대하여 회원정보 수정 등의
                      방법으로 그 변경사항을 알려야 합니다.
                    </p>
                    <p className="contents">
                      제7조(회원 탈퇴 및 자격 상실 등)
                      <br />① 회원은 “몰”에 언제든지 탈퇴를 요청할 수 있으며
                      “몰”은 즉시 회원탈퇴를 처리합니다.
                      <br />② 회원이 다음 각 호의 사유에 해당하는 경우, “몰”은
                      회원자격을 제한 및 정지시킬 수 있습니다.
                      <br />
                      &nbsp; 1. 가입 신청 시에 허위 내용을 등록한 경우
                      <br />
                      &nbsp; 2. “몰”을 이용하여 구입한 재화 등의 대금, 기타
                      “몰”이용에 관련하여 회원이 부담하는 채무를 기일에 지급하지
                      않는 경우
                      <br />
                      &nbsp; 3. 다른 사람의 “몰” 이용을 방해하거나 그 정보를
                      도용하는 등 전자상거래 질서를 위협하는 경우
                      <br />
                      &nbsp; 4. “몰”을 이용하여 법령 또는 이 약관이 금지하거나
                      공서양속에 반하는 행위를 하는 경우
                      <br />③ “몰”이 회원 자격을 제한,정지 시킨 후, 동일한
                      행위가 2회 이상 반복되거나 30일 이내에 그 사유가 시정되지
                      아니하는 경우 “몰”은 회원자격을 상실시킬 수 있습니다.
                      <br />④ “몰”이 회원자격을 상실시키는 경우에는 회원등록을
                      말소합니다. 이 경우 회원에게 이를 통지하고, 회원등록 말소
                      전에 최소한 30일 이상의 기간을 정하여 소명할 기회를
                      부여합니다.
                    </p>
                    <p className="contents">
                      제8조(회원에 대한 통지)
                      <br />① “몰”이 회원에 대한 통지를 하는 경우, 회원이 “몰”과
                      미리 약정하여 지정한 전자우편 주소로 할 수 있습니다.
                      <br />② “몰”은 불특정다수 회원에 대한 통지의 경우
                      1주일이상 “몰” 게시판에 게시함으로서 개별 통지에 갈음할 수
                      있습니다. 다만, 회원 본인의 거래와 관련하여 중대한 영향을
                      미치는 사항에 대하여는 개별통지를 합니다.
                    </p>
                    <p className="contents">
                      제9조(구매신청)
                      <br />① “몰”이용자는 “몰”상에서 다음 또는 이와 유사한
                      방법에 의하여 구매를 신청하며, “몰”은 이용자가 구매신청을
                      함에 있어서 다음의 각 내용을 알기 쉽게 제공하여야 합니다.
                      <br />
                      &nbsp; 1. 재화 등의 검색 및 선택
                      <br />
                      &nbsp; 2. 받는 사람의 성명, 주소, 전화번호,
                      전자우편주소(또는 이동전화번호) 등의 입력
                      <br />
                      &nbsp; 3. 약관내용, 청약철회권이 제한되는 서비스,
                      배송료?설치비 등의 비용부담과 관련한 내용에 대한 확인
                      <br />
                      &nbsp; 4. 이 약관에 동의하고 위 3.호의 사항을 확인하거나
                      거부하는 표시(예, 마우스 클릭)
                      <br />
                      &nbsp; 5. 재화등의 구매신청 및 이에 관한 확인 또는 “몰”의
                      확인에 대한 동의
                      <br />
                      &nbsp; 6. 결제방법의 선택
                    </p>
                    <p className="contents">
                      제10조 (계약의 성립)
                      <br />① “몰”은 제9조와 같은 구매신청에 대하여 다음 각 호에
                      해당하면 승낙하지 않을 수 있습니다. 다만, 미성년자와
                      계약을 체결하는 경우에는 법정대리인의 동의를 얻지 못하면
                      미성년자 본인 또는 법정대리인이 계약을 취소할 수 있다는
                      내용을 고지하여야 합니다.
                      <br />
                      &nbsp; 1. 신청 내용에 허위, 기재누락, 오기가 있는 경우
                      <br />
                      &nbsp; 2. 미성년자가 담배, 주류 등 청소년보호법에서
                      금지하는 재화 및 용역을 구매하는 경우
                      <br />
                      &nbsp; 3. 기타 구매신청에 승낙하는 것이 “몰” 기술상 현저히
                      지장이 있다고 판단하는 경우
                      <br />② “몰”의 승낙이 제12조제1항의 수신확인통지형태로
                      이용자에게 도달한 시점에 계약이 성립한 것으로 봅니다.
                      <br />③ “몰”의 승낙의 의사표시에는 이용자의 구매 신청에
                      대한 확인 및 판매가능 여부, 구매신청의 정정 취소 등에 관한
                      정보 등을 포함하여야 합니다.
                    </p>
                    <p className="contents">
                      제11조(지급방법)
                      <br />
                      “몰”에서 구매한 재화 또는 용역에 대한 대금지급방법은 다음
                      각 호의 방법중 가용한 방법으로 할 수 있습니다. 단, “몰”은
                      이용자의 지급방법에 대하여 재화 등의 대금에 어떠한 명목의
                      수수료도 추가하여 징수할 수 없습니다.
                      <br />
                      1. 폰뱅킹, 인터넷뱅킹, 메일 뱅킹 등의 각종 계좌이체
                      <br />
                      2. 선불카드, 직불카드, 신용카드 등의 각종 카드 결제
                      <br />
                      3. 온라인무통장입금
                      <br />
                      4. 전자화폐에 의한 결제
                      <br />
                      5. 수령 시 대금지급
                      <br />
                      6. 마일리지 등 “몰”이 지급한 포인트에 의한 결제
                      <br />
                      7. “몰”과 계약을 맺었거나 “몰”이 인정한 상품권에 의한 결제
                      <br />
                      8. 기타 전자적 지급 방법에 의한 대금 지급 등
                    </p>
                    <p className="contents">
                      제12조(수신확인통지,구매신청 변경 및 취소)
                      <br />① “몰”은 이용자의 구매신청이 있는 경우 이용자에게
                      수신확인통지를 합니다.
                      <br />② 수신확인통지를 받은 이용자는 의사표시의 불일치
                      등이 있는 경우에는 수신확인통지를 받은 후 즉시 구매신청
                      변경 및 취소를 요청할 수 있고 “몰”은 배송 전에 이용자의
                      요청이 있는 경우에는 지체 없이 그 요청에 따라 처리하여야
                      합니다. 다만 이미 대금을 지불한 경우에는 제15조의 청약철회
                      등에 관한 규정에 따릅니다.
                    </p>
                    <p className="contents">
                      제13조(재화 등의 공급)
                      <br />① “몰”은 이용자와 재화 등의 공급시기에 관하여 별도의
                      약정이 없는 이상, 이용자가 청약을 한 날부터 7일 이내에
                      재화 등을 배송할 수 있도록 주문제작, 포장 등 기타의 필요한
                      조치를 취합니다. 다만, “몰”이 이미 재화 등의 대금의 전부
                      또는 일부를 받은 경우에는 대금의 전부 또는 일부를 받은
                      날부터 3영업일 이내에 조치를 취합니다. 이때 “몰”은
                      이용자가 재화 등의 공급 절차 및 진행 사항을 확인할 수
                      있도록 적절한 조치를 합니다.
                      <br />② “몰”은 이용자가 구매한 재화에 대해 배송수단,
                      수단별 배송비용 부담자, 수단별 배송기간 등을 명시합니다.
                      만약 “몰”이 약정 배송기간을 초과한 경우에는 그로 인한
                      이용자의 손해를 배상하여야 합니다. 다만 “몰”이 고의,과실이
                      없음을 입증한 경우에는 그러하지 아니합니다.
                    </p>
                    <p>
                      제14조(환급)
                      <br />
                      “몰”은 이용자가 구매신청한 재화 등이 품절 등의 사유로 인도
                      또는 제공을 할 수 없을 때에는 지체 없이 그 사유를
                      이용자에게 통지하고 사전에 재화 등의 대금을 받은 경우에는
                      대금을 받은 날부터 3영업일 이내에 환급하거나 환급에 필요한
                      조치를 취합니다.
                    </p>
                    <p className="contents">
                      제15조(청약철회 등)
                      <br />① “몰”과 재화등의 구매에 관한 계약을 체결한 이용자는
                      「전자상거래 등에서의 소비자보호에 관한 법률」 제13조
                      제2항에 따른 계약내용에 관한 서면을 받은 날(그 서면을 받은
                      때보다 재화 등의 공급이 늦게 이루어진 경우에는 재화 등을
                      공급받거나 재화 등의 공급이 시작된 날을 말합니다)부터 7일
                      이내에는 청약의 철회를 할 수 있습니다. 다만, 청약철회에
                      관하여 「전자상거래 등에서의 소비자보호에 관한 법률」에
                      달리 정함이 있는 경우에는 동 법 규정에 따릅니다.
                      <br />② 이용자는 재화 등을 배송 받은 경우 다음 각 호의 1에
                      해당하는 경우에는 반품 및 교환을 할 수 없습니다.
                      <br />
                      &nbsp; 1. 이용자에게 책임 있는 사유로 재화 등이 멸실 또는
                      훼손된 경우(다만, 재화 등의 내용을 확인하기 위하여 포장
                      등을 훼손한 경우에는 청약철회를 할 수 있습니다)
                      <br />
                      &nbsp; 2. 이용자의 사용 또는 일부 소비에 의하여 재화 등의
                      가치가 현저히 감소한 경우
                      <br />
                      &nbsp; 3. 시간의 경과에 의하여 재판매가 곤란할 정도로
                      재화등의 가치가 현저히 감소한 경우
                      <br />
                      &nbsp; 4. 같은 성능을 지닌 재화 등으로 복제가 가능한 경우
                      그 원본인 재화 등의 포장을 훼손한 경우
                      <br />③ 제2항제2호 내지 제4호의 경우에 “몰”이 사전에
                      청약철회 등이 제한되는 사실을 소비자가 쉽게 알 수 있는
                      곳에 명기하거나 시용상품을 제공하는 등의 조치를 하지
                      않았다면 이용자의 청약철회 등이 제한되지 않습니다.
                      <br />④ 이용자는 제1항 및 제2항의 규정에 불구하고 재화
                      등의 내용이 표시?광고 내용과 다르거나 계약내용과 다르게
                      이행된 때에는 당해 재화 등을 공급받은 날부터 3월 이내, 그
                      사실을 안 날 또는 알 수 있었던 날부터 30일 이내에 청약철회
                      등을 할 수 있습니다.
                    </p>
                    <p className="contents">
                      제16조(청약철회 등의 효과)
                      <br />① “몰”은 이용자로부터 재화 등을 반환받은 경우
                      3영업일 이내에 이미 지급받은 재화 등의 대금을 환급합니다.
                      이 경우 “몰”이 이용자에게 재화등의 환급을 지연한때에는 그
                      지연기간에 대하여 「전자상거래 등에서의 소비자보호에 관한
                      법률 시행령」제21조의2에서 정하는 지연이자율(괄호 부분
                      삭제)을 곱하여 산정한 지연이자를 지급합니다.
                      <br />② “몰”은 위 대금을 환급함에 있어서 이용자가 신용카드
                      또는 전자화폐 등의 결제수단으로 재화 등의 대금을 지급한
                      때에는 지체 없이 당해 결제수단을 제공한 사업자로 하여금
                      재화 등의 대금의 청구를 정지 또는 취소하도록 요청합니다.
                      <br />③ 청약철회 등의 경우 공급받은 재화 등의 반환에
                      필요한 비용은 이용자가 부담합니다. “몰”은 이용자에게
                      청약철회 등을 이유로 위약금 또는 손해배상을 청구하지
                      않습니다. 다만 재화 등의 내용이 표시?광고 내용과 다르거나
                      계약내용과 다르게 이행되어 청약철회 등을 하는 경우 재화
                      등의 반환에 필요한 비용은 “몰”이 부담합니다.
                      <br />④ 이용자가 재화 등을 제공받을 때 발송비를 부담한
                      경우에 “몰”은 청약철회 시 그 비용을 누가 부담하는지를
                      이용자가 알기 쉽도록 명확하게 표시합니다.
                    </p>
                    <p className="contents">
                      제17조(개인정보보호)
                      <br />① “몰”은 이용자의 개인정보 수집시 서비스제공을
                      위하여 필요한 범위에서 최소한의 개인정보를 수집합니다.
                      다음 사항을 필수사항으로 하며 그 외 사항은 선택사항으로
                      합니다.
                      <br />
                      &nbsp; 1.성명
                      <br />
                      &nbsp; 2.주소
                      <br />
                      &nbsp; 3.전화번호
                      <br />
                      &nbsp; 4.희망ID(회원의 경우)
                      <br />
                      &nbsp; 5.비밀번호(회원의 경우)
                      <br />
                      &nbsp; 6.전자우편주소(또는 이동전화번호)
                      <br />② “몰”이 이용자의 개인식별이 가능한 개인정보를
                      수집하는 때에는 반드시 당해 이용자의 동의를 받습니다.
                      <br />③ 제공된 개인정보는 당해 이용자의 동의없이 목적외의
                      이용이나 제3자에게 제공할 수 없으며, 이에 대한 모든 책임은
                      　몰　이 집니다. 다만, 다음의 경우에는 예외로 합니다.
                      <br />
                      &nbsp; 1.배송업무상 배송업체에게 배송에 필요한 최소한의
                      이용자의 정보(성명, 주소, 전화번호)를 알려주는 경우
                      <br />
                      &nbsp; 2.통계작성, 학술연구 또는 시장조사를 위하여 필요한
                      경우로서 특정 개인을 식별할 수 없는 형태로 제공하는 경우
                      <br />
                      &nbsp; 3.재화등의 거래에 따른 대금정산을 위하여 필요한
                      경우
                      <br />
                      &nbsp; 4.도용방지를 위하여 본인확인에 필요한 경우
                      <br />
                      &nbsp; 5.법률의 규정 또는 법률에 의하여 필요한 불가피한
                      사유가 있는 경우
                      <br />④ “몰”이 제2항과 제3항에 의해 이용자의 동의를 받아야
                      하는 경우에는 개인정보보호 책임자의 신원(소속, 성명 및
                      전화번호, 기타 연락처), 정보의 수집목적 및 이용목적,
                      제3자에 대한 정보제공 관련사항(제공받은자, 제공목적 및
                      제공할 정보의 내용) 등 「정보통신망 이용촉진 및 정보보호
                      등에 관한 법률」 제22조제2항이 규정한 사항을 미리
                      명시하거나 고지해야 하며 이용자는 언제든지 이 동의를
                      철회할 수 있습니다.
                      <br />⑥ 이용자는 언제든지 “몰”이 가지고 있는 자신의
                      개인정보에 대해 열람 및 오류정정을 요구할 수 있으며 “몰”은
                      이에 대해 지체 없이 필요한 조치를 취할 의무를 집니다.
                      이용자가 오류의 정정을 요구한 경우에는 “몰”은 그 오류를
                      정정할 때까지 당해 개인정보를 이용하지 않습니다.
                      <br />⑦ “몰”은 개인정보 보호를 위하여 이용자의 개인정보를
                      처리하는 자를 최소한으로 제한하여야 하며 신용카드,
                      은행계좌 등을 포함한 이용자의 개인정보의 분실, 도난, 유출,
                      동의 없는 제3자 제공, 변조 등으로 인한 이용자의 손해에
                      대하여 모든 책임을 집니다.
                    </p>
                    <p className="contents">
                      제18조(“몰“의 의무)
                      <br />① “몰”은 법령과 이 약관이 금지하거나 공서양속에
                      반하는 행위를 하지 않으며 이 약관이 정하는 바에 따라
                      지속적이고, 안정적으로 재화?용역을 제공하는데 최선을
                      다하여야 합니다.
                      <br />② “몰”은 이용자가 안전하게 인터넷 서비스를 이용할 수
                      있도록 이용자의 개인정보(신용정보 포함)보호를 위한 보안
                      시스템을 갖추어야 합니다.
                      <br />③ “몰”이 상품이나 용역에 대하여 「표시?광고의
                      공정화에 관한 법률」 제3조 소정의 부당한 표시?광고행위를
                      함으로써 이용자가 손해를 입은 때에는 이를 배상할 책임을
                      집니다.
                      <br />④ “몰”은 이용자가 원하지 않는 영리목적의 광고성
                      전자우편을 발송하지 않습니다.
                    </p>
                    <p className="contents">
                      제19조(회원의 ID 및 비밀번호에 대한 의무)
                      <br />① 제17조의 경우를 제외한 ID와 비밀번호에 관한
                      관리책임은 회원에게 있습니다.
                      <br />② 회원은 자신의 ID 및 비밀번호를 제3자에게 이용하게
                      해서는 안됩니다.
                      <br />③ 회원이 자신의 ID 및 비밀번호를 도난당하거나
                      제3자가 사용하고 있음을 인지한 경우에는 바로 “몰”에
                      통보하고 “몰”의 안내가 있는 경우에는 그에 따라야 합니다.
                    </p>
                    <p className="contents">
                      제20조(이용자의 의무)
                      <br />
                      이용자는 다음 행위를 하여서는 안 됩니다.
                      <br />
                      1. 신청 또는 변경시 허위 내용의 등록
                      <br />
                      2. 타인의 정보 도용
                      <br />
                      3. “몰”에 게시된 정보의 변경
                      <br />
                      4. “몰”이 정한 정보 이외의 정보(컴퓨터 프로그램 등) 등의
                      송신 또는 게시
                      <br />
                      5. “몰” 기타 제3자의 저작권 등 지적재산권에 대한 침해
                      <br />
                      6. “몰” 기타 제3자의 명예를 손상시키거나 업무를 방해하는
                      행위
                      <br />
                      7. 외설 또는 폭력적인 메시지, 화상, 음성, 기타 공서양속에
                      반하는 정보를 몰에 공개 또는 게시하는 행위
                    </p>
                    <p className="contents">
                      제21조(연결“몰”과 피연결“몰” 간의 관계)
                      <br />① 상위 “몰”과 하위 “몰”이 하이퍼링크(예:
                      하이퍼링크의 대상에는 문자, 그림 및 동화상 등이
                      포함됨)방식 등으로 연결된 경우, 전자를 연결 “몰”(웹
                      사이트)이라고 하고 후자를 피연결 “몰”(웹사이트)이라고
                      합니다.
                      <br />② 연결“몰”은 피연결“몰”이 독자적으로 제공하는 재화
                      등에 의하여 이용자와 행하는 거래에 대해서 보증 책임을 지지
                      않는다는 뜻을 연결“몰”의 초기화면 또는 연결되는 시점의
                      팝업화면으로 명시한 경우에는 그 거래에 대한 보증 책임을
                      지지 않습니다.
                    </p>
                    <p className="contents">
                      제22조(저작권의 귀속 및 이용제한)
                      <br />① “몰“이 작성한 저작물에 대한 저작권 기타
                      지적재산권은 ”몰“에 귀속합니다.
                      <br />② 이용자는 “몰”을 이용함으로써 얻은 정보 중 “몰”에게
                      지적재산권이 귀속된 정보를 “몰”의 사전 승낙 없이 복제,
                      송신, 출판, 배포, 방송 기타 방법에 의하여 영리목적으로
                      이용하거나 제3자에게 이용하게 하여서는 안됩니다.
                      <br />③ “몰”은 약정에 따라 이용자에게 귀속된 저작권을
                      사용하는 경우 당해 이용자에게 통보하여야 합니다.
                    </p>
                    <p className="contents">
                      제23조(분쟁해결)
                      <br />① “몰”은 이용자가 제기하는 정당한 의견이나 불만을
                      반영하고 그 피해를 보상처리하기 위하여 피해보상처리기구를
                      설치?운영합니다.
                      <br />② “몰”은 이용자로부터 제출되는 불만사항 및 의견은
                      우선적으로 그 사항을 처리합니다. 다만, 신속한 처리가
                      곤란한 경우에는 이용자에게 그 사유와 처리일정을 즉시
                      통보해 드립니다.
                      <br />③ “몰”과 이용자 간에 발생한 전자상거래 분쟁과
                      관련하여 이용자의 피해구제신청이 있는 경우에는
                      공정거래위원회 또는 시?도지사가 의뢰하는 분쟁조정기관의
                      조정에 따를 수 있습니다.
                    </p>
                    <p className="contents">
                      제24조(재판권 및 준거법)
                      <br />① “몰”과 이용자 간에 발생한 전자상거래 분쟁에 관한
                      소송은 제소 당시의 이용자의 주소에 의하고, 주소가 없는
                      경우에는 거소를 관할하는 지방법원의 전속관할로 합니다.
                      다만, 제소 당시 이용자의 주소 또는 거소가 분명하지 않거나
                      외국 거주자의 경우에는 민사소송법상의 관할법원에
                      제기합니다.
                      <br />② “몰”과 이용자 간에 제기된 전자상거래 소송에는
                      한국법을 적용합니다.
                    </p>
                    부 칙(시행일) 이 약관은 년 월 일부터 시행합니다.
                  </div>
                  <p className="agreeFooter">
                    <span>이용약관에 동의하십니까?</span>
                    <input
                      className="footerCheck"
                      id="isAgreeChecked"
                      name="isAgreeChecked"
                      type="checkbox"
                      checked={this.state.isAgreeChecked}
                      onChange={this.handleCheckChange}
                    ></input>
                    <label for="isAgreeChecked" className="footerLabel">
                      동의함
                    </label>
                  </p>
                  {this.state.isSubmitted && !this.state.isAgreeChecked && (
                    <p className="notValid"> 약관에 동의가 필요합니다</p>
                  )}
                </div>
                <div className="agreeContent">
                  <div className="agreeBox">
                    <h4 className="agreeTitle">배송안내</h4>
                    <button
                      className="searchBtn"
                      onClick={() => this.setState({ shipment: true })}
                    >
                      {' '}
                      자세히보기{' '}
                    </button>

                    <Modal
                      open={this.state.shipment}
                      onClose={this.onCloseModal}
                      center
                    >
                      <h2 className="">배송안내</h2>

                      <label>1. 상품 </label>
                      <p>
                        재는 방법이나 위치에 따라 사이즈의 1~3cm 의 오차가 있을
                        수 있으니 상세사이즈를 꼭 확인해주세요. 아트 워크 및
                        굿즈의 색상은 촬영시 조명에 따라 다르게 보일 수 있으니
                        가장 가까운 상세컷을 확인해주세요. 모니터의 해상도에
                        따라 약간씩 다르게 보일 수 있으니 참고해주세요.
                      </p>
                      <label>2. 배송 </label>

                      <p>
                        배송기간은 주문후 평균 입금후 굿즈– 약 5~10일정도,
                        슈즈잡화 5~10일정도, 맞춤제작 약2주(일요일/국가공휴일
                        제외) 소요됩니다. 제작이 지연되거나 수입상품의 경우 다소
                        더 늦어질 수 있습니다. 구매전 문의사항은 게시판 또는
                        상담전화를 통하여 구매해주세요. 주문하신 상품의 개수가
                        많거나 배송지연상품이 끼어있는 경우 4일 이후엔 준비된
                        상품 먼저 발송해드립니다. (부분배송을 원치 않으실 경우
                        미리 말씀해주셔야 합니다.) 주문메세지란에 주문변경
                        관련사항은 반영되지 않으니 변경사항이 있으시면 꼭 따로
                        게시판에 글을 남겨주세요 .
                      </p>
                      <label>3. 주문변경 </label>
                      <p>
                        발송되기 전 상태일시 오후3시까지 변경가능하며 이후는
                        변경이 불가능합니다. 이후 취소시엔 상품 수령시
                        반송처리가 되며 택배비는 고객님 부담입니다. 변경시엔
                        발송이 보류되며 다음날 발송되거나 주문서를 새로
                        작성해주세요.
                      </p>
                      <label>4. 입금 </label>
                      <p>
                        주문자 성함과 입금자 성함이 다를 경우, 자동으로 확인되지
                        않으니 다를 경우 꼭 알려주셔야 입금확인이 가능합니다.
                      </p>
                      <label>5. 교환 및 반품 </label>
                      <p>
                        교환 및 반품은 사진을 찍어 카카오톡을 통한 상담 후
                        굿즈의 하자가 있는 경우에 한해서만 가능하고, 아트워크의
                        교환 및 반품은 불가합니다. ※교환/반품이 불가능한 경우
                        전자상거래법에 의하여 교환/반품 요청은 수령일로부터
                        7일이내입니다. -상품 수령일로부터 7일이 지났을 경우
                        -사용 및 소비에 의해 상품의 가치가 훼손된 경우
                        (섬유유연제/향수/바디로션/담배냄새/주름/수선/스크래치
                        등) -판매/생산방식의 특성상 교환/반품시 판매자에게
                        회복할 수 없는 손해가 발생하는 경우 (개별생산/맞춤제작
                        등)
                      </p>
                      <label>6.불량 </label>
                      <p>
                        아트워크를 제외한 굿즈의 경우 제작과정상 미비한 오염/
                        니트소재의 약간의 울트임이나 짜임의 불규칙, 매듭이
                        보이는 현상/미세한 박음질 마감처리 컬러가 있는 의류의
                        경우 염색이나 워싱공정상 미비한 색감차이 / 공정상
                        약품처리처리 과정에 의한 냄새(가죽,섬유냄새등)는 불량
                        사유가 되지 않으며 이를 이유로 반품시엔 단순변심으로
                        구분되어 택배비가 부과됩니다. 세탁부주의에 의한 변의
                        변형/착용한 흔적이 있을경우 각종냄새-향수, 섬유탈취제
                        냄새등이 날 경우 착용으로 간주하여 반품,교환이
                        어렵습니다. 배송요청 메시지란에 남겨주신 메모는 반영되지
                        않을 경우도 있으니 상품에 대핸 변경/요청사항이 있으시면
                        꼭 따로 게시판에 남겨주세요.
                      </p>
                    </Modal>
                  </div>
                </div>
              </div>
            </Row>

            <Row className="orderContainer">
              <div
                className="pointer orderTitle"
                onClick={() => {
                  this.onClickPay();
                }}
              >
                <p
                  style={{
                    textAlign: 'center',
                    fontSize: '15px',
                    color: '#333',
                    fontWeight: '700',
                  }}
                >
                  결제하기
                </p>
              </div>
              <div className="payMethodContainer"></div>
            </Row>
          </Container>
        ) : (
          <Redirect to="/paySuccess" />
        )}
      </Container>
    );
  }
}
export default PayGoods;
