import React, { Component } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { Database } from 'components';

import 'style.scss';
import 'components/style.scss';
class AdminStory extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      solo: '',
      team: '',
      movie: '',
      award: '',
      etc: '',
      jury: '',
    };
  }

  brToNewline = (str) => {
    return str.split('<br/>').join('\n');
  };
  newlineToBr = (str) => {
    return str.split('\n').join('<br/>');
  };
  savePortfolio = () => {
    let { solo, team, movie, award, etc, jury } = this.state;
    solo = this.newlineToBr(solo);
    team = this.newlineToBr(team);
    movie = this.newlineToBr(movie);
    award = this.newlineToBr(award);
    etc = this.newlineToBr(etc);
    jury = this.newlineToBr(jury);

    const portfolio = { solo, team, movie, award, etc, jury };
    Database.collection('Story')
      .doc('portfolio')
      .update(portfolio)
      .then((res) => {
        console.log(res);
        alert('저장되었습니다');
      })
      .catch((err) => {
        console.log(err);
      });
  };
  componentDidMount = () => {
    Database.collection('Story')
      .doc('portfolio')
      .get()
      .then((res) => {
        let { solo, team, movie, award, etc, jury } = res.data();
        solo = solo.replace(/<br\/>/gi, '\n');
        team = team.replace(/<br\/>/gi, '\n');
        movie = movie.replace(/<br\/>/gi, '\n');
        award = award.replace(/<br\/>/gi, '\n');
        etc = etc.replace(/<br\/>/gi, '\n');
        jury = jury.replace(/<br\/>/gi, '\n');

        this.setState({ solo, team, movie, award, etc, jury });
      });
  };

  render() {
    return (
      <Container
        className="from-bottom-animation"
        style={{ backgroundColor: 'white' }}
      >
        <Row
          xs={12}
          style={{ height: '50px', margin: '20px', marginTop: '40px' }}
        >
          <Col xs={11} style={styles.header}>
            포트폴리오
          </Col>
          <Col xs={1}>
            <span onClick={() => this.savePortfolio()} style={styles.saveText}>
              저장
            </span>
          </Col>
        </Row>
        <Row xs={12} style={styles.row}>
          <Col xs={6} style={styles.box}>
            <p style={styles.title}>개인전</p>
            <textarea
              style={styles.textarea}
              value={this.state.solo}
              onChange={(e) => {
                this.setState({ solo: e.target.value });
              }}
            ></textarea>
          </Col>
          <Col xs={6} style={styles.box}>
            <p style={styles.title}>단체전</p>
            <textarea
              style={styles.textarea}
              value={this.state.team}
              onChange={(e) => {
                this.setState({ team: e.target.value });
              }}
            ></textarea>
          </Col>
        </Row>

        <Row xs={12} style={styles.row}>
          <Col xs={6} style={styles.box}>
            <p style={styles.title}>영화</p>
            <textarea
              style={styles.textarea}
              value={this.state.movie}
              onChange={(e) => {
                this.setState({ movie: e.target.value });
              }}
            ></textarea>
          </Col>
          <Col xs={6} style={styles.box}>
            <p style={styles.title}>수상</p>
            <textarea
              style={styles.textarea}
              value={this.state.award}
              onChange={(e) => {
                this.setState({ award: e.target.value });
              }}
            ></textarea>
          </Col>
        </Row>
        <Row xs={12} style={styles.row}>
          <Col xs={6} style={styles.box}>
            <p style={styles.title}>콜라보레이션</p>
            <textarea
              style={styles.textarea}
              value={this.state.etc}
              onChange={(e) => {
                this.setState({ etc: e.target.value });
              }}
            ></textarea>
          </Col>
          <Col xs={6} style={styles.box}>
            <p style={styles.title}>앰베서더 & 심사위원</p>
            <textarea
              style={styles.textarea}
              value={this.state.jury}
              onChange={(e) => {
                this.setState({ jury: e.target.value });
              }}
            ></textarea>
          </Col>
        </Row>
      </Container>
    );
  }
}
export default AdminStory;

const styles = {
  header: {
    fontSize: '24px',
    fontWeight: '500',
  },
  saveText: {
    cursor: 'pointer',
    color: '#166cbc',
    fontSize: '18px',
    fontWeight: '500',
  },
  row: {
    margin: '20px',
    width: '100%',
  },
  title: {
    fontSize: '16px',
  },
  textarea: {
    width: '85%',
    height: '300px',
    borderWidth: '1px',
    borderColor: '#ccc',
    borderRadius: '5px',
    outline: 'none',
    padding: '15px',
    fontSize: '14px',
    whiteSpace: 'pre-wrap',
  },
};
