import React, { Component, Fragment } from 'react';
import UploadModal from '../Upload/UploadModal';

import '../style.scss';
import 'style.scss';
import { observer, inject } from 'mobx-react';

@inject('store')
@observer
class ShopHeader extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show: true,
    };
  }
  render() {
    return (
      <Fragment>
        <UploadModal show={this.state.show} />
        <div className="fadein shop-header">
          <div className="header">
            <span>{this.props.description}</span>
            <p>{this.props.description2}</p>
          </div>
        </div>
      </Fragment>
    );
  }
}
export default ShopHeader;
