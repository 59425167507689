import React, { Component } from 'react';
import { Row, Col, Image } from 'react-bootstrap';
import { GiNewspaper } from 'react-icons/gi';
import '../style.scss';
import 'style.scss';

class HomeNotice extends Component {
  render() {
    return (
      <Row className="home-component">
        <Col xs={12} className="">
          <span className="component-header">
            <GiNewspaper size={'3vh'} className="home-icon" />
            {this.props.header}
          </span>
        </Col>

        {this.props.contents.map((obj) => {
          return (
            <Col
              key={obj.title}
              xs={12}
              md={6}
              className="px-2 home-notice from-bottom-animation"
            >
              <Image src={obj.src} className="home-notice-image" />
              <div className="home-notice-text-box">
                <span className="label">{obj.title}</span>
                <span className="detail">
                  <p>{obj.subtitle}</p>
                  {/* <a className="pointer" onClick={() => window.open(obj.link)}> {obj.subtitle} &nbsp;</a>
                                    <a className="pointer" onClick={() => window.open(obj.link2)}> {obj.subtitle2} &nbsp;</a>
                                    <a className="pointer" onClick={() => window.open(obj.link3)}> {obj.subtitle3} &nbsp;</a>
                                    <a className="pointer" onClick={() => window.open(obj.link4)}> {obj.subtitle4}</a> */}
                </span>
              </div>
            </Col>
          );
        })}
      </Row>
    );
  }
}
export default HomeNotice;
