import React from 'react';
import { Link } from 'react-router-dom';
const NotFound = () => (
  <div style={{ margin: 'auto', marginTop: '100px' }}>
    <center>페이지 로드에 오류가 있습니다</center>
    <center>
      <Link to="/">Go Home</Link>
    </center>
    <img
      src={require(`images/logo.jpg`)}
      style={{
        width: 400,
        height: 400,
        display: 'block',
        margin: 'auto',
        position: 'relative',
      }}
      alt=""
    />
  </div>
);
export default NotFound;
