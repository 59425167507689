import React, { Component } from 'react';
import { Route, Switch } from 'react-router-dom';
import { Container } from 'react-bootstrap';
import Gallerys from './GalleryScreen';
import GalleryDetail from './GalleryDetail';
import 'components/style.scss';
import 'style.scss';
class Gallery extends Component {
  render() {
    return (
      <Container fluid>
        <Switch>
          <Route exact path="/gallery" component={Gallerys} />
          <Route path="/gallery/:aid" component={GalleryDetail} />
        </Switch>
      </Container>
    );
  }
}
export default Gallery;
