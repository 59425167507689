import React, { Component } from 'react';
import { Container, Row, Col, Image, Spinner } from 'react-bootstrap';
import { Link, NavLink } from 'react-router-dom';
import {
  ShopHeader,
  UploadModal,
  EditModal,
  Database,
  Storage,
} from 'components';
import StackGrid from 'react-stack-grid';
import Modal from 'react-modal';
import 'components/style.scss';
import 'style.scss';
import { observer, inject } from 'mobx-react';
import { FiEdit, FiX, FiSearch } from 'react-icons/fi';
import BottomScrollListener from 'react-bottom-scroll-listener';

@inject('store')
@observer
class Artworks extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
      size: 0,
      edit: false,
      key: null,
      gridWidth: window.innerWidth > 768 ? '30%' : '45%',
      frameWidth: window.innerWidth > 768 ? '15px' : '10px',
      list: [],
      hashtags: [],
      selectedTags: [],
      showDeleteModal: false,
      loaded: true,
      last: '',
      status: false,
      search_word: '',
    };
    this.onClickSearch = this.onClickSearch.bind(this);
  }

  returnElement = (key, url, title, eng_title, soldout) => {
    return (
      <div
        key={key}
        className="artwork-grid"
        onClick={() => {}}
        style={{ padding: this.state.frameWidth }}
      >
        <div className="artwork-element">
          <Link to={'/shop/artworks/' + key} key={key}>
            <div className=" pointer">
              <Image
                className="artwork-image"
                src={url ? url : require('images/1.1.png')}
              />
            </div>
          </Link>
          <div className="grid-text-box">
            <span className="grid-name-text">
              {title}
              {eng_title ? ' / ' + eng_title : ''}
            </span>
            <br />
            {/* 
            2020.11.25 수정사항
            아트워크 판매 여부 상태 표시하지 않음.*/}
            <span className={soldout ? 'soldout-circle' : ''}></span>
            <br />
          </div>
          {this.props.store.user.isAdmin() && (
            <div className="grid-admin-box">
              <div
                className="grid-admin-icon pointer"
                onClick={() => {
                  this.setState({ key, edit: true });
                }}
              >
                <FiEdit size={20} />
              </div>
              <div
                className="grid-admin-icon pointer"
                onClick={async () => {
                  await this.setState({ key, showDeleteModal: true });
                }}
              >
                <FiX size={20} />
              </div>
            </div>
          )}
        </div>
      </div>
    );
  };

  handleOnDocumentBottom = async () => {
    // let selectedTags = this.state.selectedTags.filter((id) => id !== tag.id);
    if (this.state.loaded) {
      let list = this.state.list.slice();
      if (!this.state.selectedTags.length) {
        this.setState(
          { loaded: false, status: true },
          this.grid.updateLayout(),
        );
        let docRefs = Database.collection('Artworks')
          .orderBy('timestamp', 'desc')
          .startAfter(this.state.last)
          .limit(10);
        let storageRefs = Storage.ref('artworks');
        await docRefs
          .where('uploaded', '==', true)
          .get()
          .then((querySnapshot) => {
            this.setState({
              last: querySnapshot.docs[querySnapshot.docs.length - 1].data()
                .timestamp,
            });
            this.updateList(storageRefs, list, querySnapshot);
            //foreach; https://qastack.kr/programming/18983138/callback-after-all-asynchronous-foreach-callbacks-are-completed
          })
          .catch((err) => console.log(err));
      } //if
      this.setState({ list }, this.grid.updateLayout());
      setTimeout(() => this.setState({ loaded: true, status: false }), 800);
    }
  };

  updateArtworkList = async (init = false, tags = []) => {
    let dbRefs = Database.collection('Artworks').where('uploaded', '==', true);
    let last = this.state.last;
    if (init) {
      last = '';
      this.setState({ list: [] });
    }
    tags.forEach((tag) => {
      dbRefs = dbRefs.where(`hashtags.${tag}`, '==', true);
    });
    dbRefs.get().then((snap) => {
      console.log(snap.size);
      this.setState({ size: snap.size, list: [] });
    });

    this.setState({ status: true });

    let storageRefs = Storage.ref('artworks');

    dbRefs
      .orderBy('timestamp', 'desc')
      .limit(15)
      .get()
      .then((snap) => {
        this.setState({
          last: snap.docs[snap.docs.length - 1].data().timestamp,
        });
        let list = this.state.list.slice();
        this.updateList(storageRefs, list, snap);
      })
      .catch((err) => {
        console.log(err);
      });
    this.setState({ loaded: true, status: false });
  };
  componentWillMount = async () => {
    this.updateArtworkList(true, []);
  };
  componentDidMount = () => {
    /*
      scroll 이벤트 2020.11.25
    */
    // window.addEventListener("scroll", function () {
    //   const elements = document.getElementsByClassName("artwork-grid");
    //   console.log(elements.length);
    //   console.log(window.pageYOffset);
    // });
    window.addEventListener('resize', this.resize.bind(this));
    let dbRef = Database.collection('Hashtags');
    dbRef
      .orderBy('count', 'desc')
      .get()
      .then((querySnapshot) => {
        let hashtags = [];
        querySnapshot.forEach((doc) => {
          hashtags.push(doc.data());
        });
        this.setState({ hashtags });
      })
      .catch((err) => {
        console.log(err);
      });
  };
  filtering = (tags = []) => {
    let docRefs = Database.collection('Artworks');
    docRefs = docRefs.where('uploaded', '==', true);
    tags.forEach((tag) => {
      docRefs = docRefs.where('hashtags.' + tag, '==', true);
    });

    docRefs.get().then((snap) => {
      this.setState({ size: snap.size, list: [] });
    });

    let storageRefs = Storage.ref('artworks');
    this.setState({ status: true });

    docRefs
      .orderBy('timestamp', 'desc')
      .limit(10)
      .get()
      .then((snap) => {
        this.setState({
          last: snap.docs[snap.docs.length - 1].data().timestamp,
        });
        let list = this.state.list.slice();
        snap.forEach((doc) => {
          let key = doc._key.path.segments[doc._key.path.segments.length - 1];
          storageRefs
            .child('/' + key)
            .getDownloadURL()
            .then((url) => {
              list.push(
                this.returnElement(
                  key,
                  url,
                  doc.data().title,
                  doc.data().eng_title,
                  doc.data().soldout,
                ),
              );
              this.setState({ list }, this.grid.updateLayout());
            })
            .catch((err) => {
              console.log(err);
            });
        });
      })
      .catch((err) => {
        console.log(err);
      });
    this.setState({ loaded: true, status: false });
  };

  handleSearchChange = (e) => {
    this.setState({ search_word: e.target.value });
    if (e.target.value === '') this.updateArtworkList(true, []);
  };
  onKeyPress = (e) => {
    if (e.key === 'Enter') {
      this.getSearchResult(this.state.search_word);
    }
  };
  onClickSearch = () => {
    this.getSearchResult(this.state.search_word);
  };
  onKeyPress = (e) => {
    if (e.key === 'Enter') {
      this.getSearchResult(this.state.search_word);
    }
  };
  // Or 쿼리는 결과로 병합해야 한다. , prefix search 밖에 안됨, 전체검색 시 array-contain 필드 추가 요망
  getSearchResult = async (word) => {
    this.setState({ loaded: false });
    let storageRefs = Storage.ref('artworks');
    let docRefs = Database.collection('Artworks');
    let titleRefs = docRefs
      .where('title', '>=', word)
      .where('title', '<=', word + '\uf8ff');
    let eng_titleRefs = docRefs
      .where('eng_title', '>=', word)
      .where('eng_title', '<=', word + '\uf8ff');

    let list = [];
    titleRefs.get().then((snap) => {
      this.updateList(storageRefs, list, snap);
    });
    eng_titleRefs.get().then((snap) => {
      this.updateList(storageRefs, list, snap);
    });
  };

  updateList = (storageRefs, list, snapShot) => {
    snapShot.forEach((doc) => {
      let key = doc._key.path.segments[doc._key.path.segments.length - 1];
      storageRefs
        .child(`/${key}`)
        .getDownloadURL()
        .then((url) => {
          list.push(
            this.returnElement(
              key,
              url,
              doc.data().title,
              doc.data().eng_title,
              doc.data().soldout,
            ),
          );
          this.setState({ list }, this.grid.updateLayout());
        })
        .catch((err) => {
          console.log(err);
        });
    });
  };

  resize = async () => {
    await this.setState({
      gridWidth: window.innerWidth > 768 ? '30%' : '45%',
      frameWidth: window.innerWidth > 768 ? '15px' : '10px',
    });
    setTimeout(() => {
      this.grid.updateLayout();
    }, 500);
  };
  removeItem = async () => {
    await Database.collection('Artworks')
      .doc(this.state.key)
      .get()
      .then((res) => {
        Object.keys(res.data().hashtags).forEach((hashtag) => {
          Database.collection('Hashtags')
            .doc(hashtag)
            .get()
            .then((res) => {
              let count = res.data().count;
              if (count > 0) {
                Database.collection('Hashtags')
                  .doc(res.id)
                  .update({
                    count: parseInt(count) - 1,
                  })
                  .then(() => {
                    // console.log(res.id, "카운트다운");
                  })
                  .catch((err) => {
                    console.log(err);
                  });
              } else {
                Database.collection('Hashtags')
                  .doc(res.id)
                  .delete()
                  .then(() => {
                    // console.log(res.id, "삭제");
                  })
                  .catch((err) => console.log(err));
              }
            });
        });
      });
    await Database.collection('Artworks')
      .doc(this.state.key)
      .delete()
      .then(() => {
        console.log('삭제완료');
      })
      .catch((err) => console.log(err));
    await Storage.ref('artworks')
      .child('/' + this.state.key)
      .delete()
      .then(() => {
        console.log('이미지 삭제완료');
      });
    this.setState({ showDeleteModal: false });
    window.location.reload();
  };

  render() {
    return (
      <Container className="artworks-main">
        <Modal
          ariaHideApp={false}
          isOpen={this.state.showDeleteModal}
          onRequestClose={() => {
            this.setState({ showDeleteModal: false });
          }}
          className="alert-modal"
          overlayClassName="modal-overlay"
        >
          <Container fluid style={{ padding: 0 }}>
            <Row className="modal-container">
              <Col xs={12} className="alert">
                <span>삭제하시겠습니까?</span>
              </Col>
              <Col
                sm={12}
                className="alert-btn pointer"
                onClick={() => {
                  this.removeItem();
                }}
              >
                <span>삭제</span>
              </Col>
              <Col
                sm={12}
                className="alert-btn pointer"
                onClick={() => {
                  this.setState({ showDeleteModal: false });
                }}
              >
                <span>취소</span>
              </Col>
            </Row>
          </Container>
        </Modal>
        <Row className="p-3">
          <Col md={2} xs={4}>
            <NavLink
              exact
              to="/shop"
              onClick={() => {
                console.log(123);
              }}
              className="shop-btn"
              activeClassName="shop-btn-highlight"
            >
              <span>Artworks</span>
            </NavLink>
          </Col>
          {/*
            <Col md={2} xs={4}>
            <NavLink
              exact
              to="/shop/goods"
              className="shop-btn"
              activeClassName="shop-btn-highlight"
            >
              <span>Goods</span>
            </NavLink>
             </Col>
          */}
        </Row>
        <UploadModal
          show={this.state.show}
          category={false}
          callback={() => {
            this.setState({ show: false });
          }}
        />
        <EditModal
          show={this.state.edit}
          elementkey={this.state.key}
          category={false}
          callback={() => {
            this.setState({ edit: false });
          }}
        />
        <ShopHeader
          description={'The work can be purchased through 1:1 consultation.'}
          description2={'You can enjoy the works through scrolling.'}
        />
        {/* <Row className="hashtags">
          <div style={{ width: "auto" }}>
            {this.state.hashtags.map((tag, key) => {
              if (this.state.selectedTags.includes(tag.id)) {
                return (
                  <span
                    key={key}
                    className="pointer hash-span-selected"
                    onClick={() => {
                      let selectedTags = this.state.selectedTags.filter(
                        (id) => id !== tag.id
                      );
                      this.setState({ selectedTags });
                      this.filtering(selectedTags);
                    }}
                  >
                    {"#" + tag.id}
                  </span>
                );
              } else {
                return (
                  <span
                    key={key}
                    className="pointer hash-span"
                    onClick={() => {
                      let selectedTags = this.state.selectedTags.slice();
                      selectedTags.push(tag.id);
                      this.setState({ selectedTags });
                      this.filtering(selectedTags);
                    }}
                  >
                    {"#" + tag.id}
                  </span>
                );
              }
            })}
          </div>
        </Row> */}
        <Row>
          <div className="fadein shop-header">
            <div className="btn-box">
              {this.props.store.user.isAdmin() ? (
                <div className="shop-btn">
                  <div
                    onClick={() => {
                      this.setState({ show: true });
                    }}
                    className="pointer "
                  >
                    <span>Upload</span>
                  </div>
                </div>
              ) : (
                <div />
              )}
              {/* 
              2020.12.01 Goods 임시 삭제
                <div className="shop-btn">     
                  <div onClick={()=>{}} className="pointer">
                    <span>Filter</span>
                  </div>
                </div> */}
            </div>
          </div>
        </Row>
        <Row style={{ display: 'flow-root' }}>
          <div className="search_body">
            <input
              type="search"
              onChange={this.handleSearchChange}
              onKeyPress={this.onKeyPress}
              placeholder="Search.."
            />
            <button
              onClick={() => {
                this.onClickSearch();
              }}
            >
              <FiSearch className="fa fa-search" />
            </button>
          </div>
        </Row>
        <div style={{ minHeight: '80vh' }}>
          <StackGrid
            className="my-3"
            columnWidth={this.state.gridWidth}
            gridRef={(grid) => (this.grid = grid)}
            gutterWidth={30}
            gutterHeight={30}
            monitorImagesLoaded={true}
          >
            {this.state.list}
          </StackGrid>
          <BottomScrollListener
            onBottom={this.handleOnDocumentBottom}
            offset={20}
            debounce={20}
          />{' '}
        </div>
        {this.state.status ? (
          <Col
            xs={12}
            className="flex justify-content-center align-items-center"
            style={{ height: '15vh' }}
          >
            <div>
              <Spinner animation={'border'} />
            </div>
          </Col>
        ) : (
          <Col
            xs={12}
            className="flex justify-content-center align-items-center"
          >
            {' '}
            <p>See more</p>{' '}
          </Col>
        )}
        <div
          style={{
            position: 'fixed',
            bottom: '10px',
            left: 0,
            width: '100%',
            height: '50px',
            display: 'flex',
            zIndex: 100,
            justifyContent: 'center',
          }}
        >
          <span
            style={{
              position: 'relative',

              backgroundColor: 'rgba(255,255,255,0.7)',
              width: '100px',
              height: '50px',
              borderRadius: '25px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >{`${this.state.list.length} / ${this.state.size}`}</span>
        </div>
      </Container>
    );
  }
}
export default Artworks;
