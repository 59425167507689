import React, { Component } from 'react';
import { Col, Spinner } from 'react-bootstrap';
import { Database, Storage } from 'components';
import { TiFolderOpen } from 'react-icons/ti';
import { FiX, FiRotateCw } from 'react-icons/fi';
import { WithContext as ReactTags } from 'react-tag-input';
import 'components/style.scss';
import 'style.scss';
import { observer, inject } from 'mobx-react';
const KeyCodes = {
  comma: 188,
  enter: 13,
  space: 32,
};

const delimiters = [KeyCodes.comma, KeyCodes.enter, KeyCodes.space];

@inject('store')
@observer
class UploadArtworks extends Component {
  constructor(props) {
    super(props);
    this.state = {
      title: '',
      eng_title: '',
      subtitle: '',
      description: '',
      material: '', //0:기타, 1:아크릴, 2:유화, 3:수채화, 4:크레파스, 5:오일파스텔
      base: 0, //0:캔버스, 1:종이
      onSale: false,
      own: false,
      soldout: false,
      shirts: false,
      shoes: false,
      ecobag: false,
      else: false,
      date: '',
      width: '',
      height: '',
      artworks: '',
      status: false,
      percent: 0,
      preview: [],
      key: null,
      tags: [],
      suggestions: [],
      memo: '',
      pick: false,
    };
    this.handleDelete = this.handleDelete.bind(this);
    this.handleAddition = this.handleAddition.bind(this);
    //this.handleDrag = this.handleDrag.bind(this);
  }
  componentDidMount = () => {
    let hashtags = Database.collection('Hashtags');
    hashtags
      .get()
      .then(async (querySnapshot) => {
        let hashlist = [];
        await querySnapshot.forEach((doc) => {
          hashlist.push(doc.data());
        });
        this.setState({ suggestions: hashlist });
      })
      .catch((error) => {
        console.log('Error : ', error);
      });
  };
  // displayDate(date) {
  //     var date = new Date();
  //     var year = date.getFullYear();
  //     var month = date.getMonth() + 1
  //     var day = date.getDate();
  //     if (month < 10) {
  //         month = "0" + month;
  //     }
  //     if (day < 10) {
  //         day = "0" + day;
  //     }

  //     return (year + "-" + month + "-" + day);

  // }
  handleDelete(i) {
    const { tags } = this.state;
    this.setState({
      tags: tags.filter((tag, index) => index !== i),
    });
  }

  handleAddition(tag) {
    let tmp = tag;

    tag.text = '#' + tag.text;
    this.setState((state) => ({ tags: [...state.tags, tmp] }));
  }

  displayButton = (flag) => {
    return flag ? (
      <div className="inputbox-button" style={{ borderColor: '#326bb5' }}>
        <div className="activated" />
      </div>
    ) : (
      <div className="inputbox-button" />
    );
  };
  upload = () => {
    let hashtags = {};
    for (let i = 0; i < this.state.tags.length; i++) {
      hashtags[this.state.tags[i].id] = true;
    }
    console.log(hashtags);
    Database.collection('Artworks')
      .add({
        custom_ecobag: this.state.ecobag,
        custom_else: this.state.else,
        custom_shoes: this.state.shoes,
        custom_tshirts: this.state.shirts,
        date: this.state.date,
        height: this.state.height,
        soldout: this.state.soldout,
        own: this.state.own,
        on_sale: this.state.onSale,
        subtitle: this.state.subtitle,
        timestamp: new Date(),
        title: this.state.title,
        eng_title: this.state.eng_title,
        material: this.state.material,
        base: this.state.base,
        width: this.state.width,
        hashtags,
        uploaded: false,
        memo: this.state.memo,
        pick: this.state.pick,
      })
      .then((res) => {
        const key = res._key.path.segments[1];
        this.setState({ key });
        let artworksRef = Storage.ref('artworks');
        let path = artworksRef.child('/' + key);
        let hashtagsRefs = Database.collection('Hashtags');
        this.state.tags.forEach((tag) => {
          hashtagsRefs
            .doc(tag.id)
            .get()
            .then((doc) => {
              if (doc.exists) {
                let { id, text, count } = doc.data();
                hashtagsRefs
                  .doc(tag.id)
                  .update({
                    id,
                    text,
                    count: count + 1,
                  })
                  .catch((err) => {
                    console.log('@update' + err);
                  });
              } else {
                hashtagsRefs
                  .doc(tag.id)
                  .set({
                    id: tag.id,
                    text: tag.text,
                    count: 1,
                  })
                  .catch((err) => {
                    console.log('@add' + err);
                  });
              }
            })
            .catch((err) => {
              console.log('@get' + err);
            });
        });
        path
          .put(this.state.preview[0].imageValue)
          .then((snapshot) => {
            Database.collection('Artworks')
              .doc(key)
              .update({
                uploaded: true,
              })
              .then((res) => {
                alert(this.state.title + '\n업로드 완료');
                this.setState({ status: false });
                this.props.callback();
              })
              .catch((err) => {
                this.setState({ status: false });
                alert('업로드 실패');
                console.log(err);
              });
          })
          .catch((err) => {
            this.setState({ status: false });
            alert('업로드 실패');
            console.log(err);
          });
      })
      .catch((err) => {
        this.setState({ status: false });
        alert('업로드 실패');
        console.log(err);
      });
  };
  load_image = (e) => {
    const files = e.target.files;
    const filesArr = Array.prototype.slice.call(files);
    filesArr.forEach((file) => {
      const reader = new FileReader();
      reader.onload = (e) => {
        const image = new Image();
        image.className = 'main-img-item';
        image.src = e.target.result;
        image.onload = (imageEvent) => {
          // Resize the image
          this.resize_image(image, file);
        };
      };
      reader.readAsDataURL(file);
    });
  };

  resize_image = (image, file) => {
    let canvas = document.createElement('canvas'),
      max_size = 1280,
      width = image.width,
      height = image.height;
    if (width > height) {
      if (width > max_size) {
        height *= max_size / width;
        width = max_size;
      }
    } else {
      if (height > max_size) {
        width *= max_size / height;
        height = max_size;
      }
    }
    canvas.width = width;
    canvas.height = height;

    canvas.getContext('2d').drawImage(image, 0, 0, width, height);
    const dataUrl = canvas.toDataURL(file.type);
    const imageValue = this.dataURLToBlob(dataUrl);
    let preview = this.state.preview;
    preview.push({ dataUrl, type: file.type, imageValue });
    this.setState({ preview });
  };
  rotateImage = (item, index) => {
    let url = item.dataUrl; // type = item.type;
    const image = new Image();
    image.className = 'img-item';
    image.src = url;
    image.onload = (imageEvent) => {
      let canvas = document.createElement('canvas');
      let max_size = 1280,
        width = image.width,
        height = image.height;
      if (width > height) {
        if (width > max_size) {
          height *= max_size / width;
          width = max_size;
        }
      } else {
        if (height > max_size) {
          width *= max_size / height;
          height = max_size;
        }
      }
      let w = width,
        h = height;
      let max = w > h ? w : h;
      canvas.width = max;
      canvas.height = max;
      let ctx = canvas.getContext('2d');

      ctx.rotate((90 * Math.PI) / 180);
      ctx.drawImage(image, 0, -h, w, h);
      ctx.restore();

      let tempCanvas = document.createElement('canvas');
      tempCanvas.width = h;
      tempCanvas.height = w;
      let tCtx = tempCanvas.getContext('2d');
      tCtx.drawImage(canvas, 0, 0);
      tCtx.restore();

      // let resizeCanvas = document.createElement("canvas");
      // resizeCanvas.width = h;
      // resizeCanvas.height = w;
      // resizeCanvas.getContext("2d").drawImage(tempCanvas, 0, 0, h, w);

      const dataUrl = tempCanvas.toDataURL('image/jpeg');
      const imageValue = this.dataURLToBlob(dataUrl);

      console.log(imageValue);
      let preview = this.state.preview;
      preview[index] = { dataUrl, imageValue, type: 'image/jpeg' };
      this.setState({ preview });
    };
  };
  dataURLToBlob = (dataURL) => {
    const BASE64_MARKER = ';base64,';
    if (dataURL.indexOf(BASE64_MARKER) === -1) {
      const parts = dataURL.split(',');
      const contentType = parts[0].split(':')[1];
      const raw = parts[1];
      return new Blob([raw], {
        type: contentType,
      });
    }
    const parts = dataURL.split(BASE64_MARKER);
    const contentType = parts[0].split(':')[1];
    const raw = window.atob(parts[1]);
    const rawLength = raw.length;
    const uInt8Array = new Uint8Array(rawLength);
    let i = 0;
    while (i < rawLength) {
      uInt8Array[i] = raw.charCodeAt(i);
      i++;
    }
    return new Blob([uInt8Array], {
      type: contentType,
    });
  };
  setMaterial = (m) => {
    let { material } = this.state;
    const index = material.indexOf(m);
    if (index === -1) {
      material += m;
    } else {
      material = material.replace(m, '');
    }
    this.setState({ material });
  };
  render() {
    return (
      <Col xs={12} className="upload-detail">
        <div className="px-3" style={{ width: '100%' }}>
          <span className="weight900">해시태그</span>
          <br />
          <br />
        </div>
        <div className="pb-3 tag-test">
          <ReactTags
            tags={this.state.tags}
            inline={false}
            suggestions={this.state.suggestions}
            handleDelete={this.handleDelete}
            handleAddition={this.handleAddition}
            // handleDrag={this.handleDrag}
            placeholder={'태그 입력'}
            delimiters={delimiters}
          />
        </div>
        <div className="p-3">
          <span className="weight900">
            제목 {'  (' + this.state.title.length + '/50)'}
          </span>
          <input
            type="text"
            value={this.state.title}
            maxLength={50}
            onChange={(e) => this.setState({ title: e.target.value })}
            className="textbox image-title input-text"
          />
          <span className="weight900">
            영문 제목 {'  (' + this.state.eng_title.length + '/50)'}
          </span>
          <input
            type="text"
            value={this.state.eng_title}
            maxLength={50}
            onChange={(e) => this.setState({ eng_title: e.target.value })}
            className="textbox image-title input-text"
          />
          <span className="weight900">
            부제 {'  (' + this.state.subtitle.length + '/50)'}
          </span>
          <input
            type="text"
            value={this.state.subtitle}
            maxLength={50}
            onChange={(e) => this.setState({ subtitle: e.target.value })}
            className="textbox image-title input-text"
          />
          <span className="weight900">메모</span>
          <input
            type="text"
            value={this.state.memo}
            maxLength={50}
            onChange={(e) => this.setState({ memo: e.target.value })}
            className="textbox image-title input-text"
          />
          {/* <span>설명 {'  ('+this.state.description.length+'/1000)'}</span>
                    <textarea className="textbox image-description" maxLength={1000} onChange={(e=>this.setState({description:e.target.value}))} value={this.state.description}/> */}
        </div>

        <div
          className="w100p flex justify-content-center align-items-center p-3"
          style={{ flexDirection: 'column' }}
        >
          <label
            htmlFor="upload-image"
            className="flex justify-content-center align-items-center pointer"
            style={{
              width: '150px',
              height: '50px',
              borderRadius: '10px',
              backgroundColor: '#eee',
              flexDirection: 'column',
              fontSize: '14px',
              fontWeight: '900',
              color: '#333',
            }}
          >
            <div
              className="flex justify-content-center align-items-center"
              style={{ flexDirection: 'row' }}
            >
              <span style={{ marginRight: '20px' }}>이미지 선택</span>
              <TiFolderOpen size={'20px'} style={{ color: '#333' }} />
            </div>
            {/* <ProgressBar style={{ width: "80%", marginTop: "10%", marginBottom: "10%" }} now={this.state.percent} animated />
                        <span>{this.state.percent}%</span> */}
          </label>
          <input
            ref={(ref) => (this.image_input = ref)}
            style={{ display: 'none' }}
            type="file"
            accept="image/*"
            id="upload-image"
            onChange={(e) => {
              this.load_image(e);
            }}
          />
          <div
            className="flex align-items-center justify-content-center"
            style={{ flexDirection: 'row', width: '90%', padding: '5vw' }}
          >
            {this.state.preview.map((element, key) => {
              return (
                <div className="div-item" key={key}>
                  <div>
                    <img
                      alt=""
                      src={element.dataUrl}
                      className="main-img-item"
                    />
                  </div>
                  <div className="image-icon-box">
                    <div
                      className="pointer custom-icon"
                      onClick={() => {
                        this.rotateImage(element, key);
                      }}
                    >
                      <FiRotateCw size={'20px'} style={{ color: '#333' }} />
                    </div>
                    <div
                      className="pointer custom-icon"
                      onClick={() => {
                        let preview = this.state.preview.slice();
                        preview.splice(key, 1);
                        this.setState({ preview });
                      }}
                    >
                      <FiX size={'20px'} style={{ color: '#333' }} />
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>

        <div className="p-3">
          <span className="weight900">제작년도</span>
          <input
            type="text"
            value={this.state.date}
            maxLength={4}
            onChange={(e) => this.setState({ date: e.target.value })}
            className="textbox numberbox input-text"
          />
        </div>
        <div className="p-3">
          <span className="weight900">Material</span>
          <div className="inputbox-container py-2">
            <div
              className="inputbox-element"
              onClick={() => {
                this.setMaterial('0');
              }}
            >
              {this.displayButton(this.state.material.indexOf('0') > -1)}
              <span
                className="btn-text"
                style={
                  this.state.material.indexOf('0') > -1
                    ? { color: '#326bb5', fontWeight: 500 }
                    : {}
                }
              >
                기타
              </span>
            </div>
            <div
              className="inputbox-element"
              onClick={() => {
                this.setMaterial('1');
              }}
            >
              {this.displayButton(this.state.material.indexOf('1') > -1)}
              <span
                className="btn-text"
                style={
                  this.state.material.indexOf('1') > -1
                    ? { color: '#326bb5', fontWeight: 500 }
                    : {}
                }
              >
                아크릴
              </span>
            </div>
            <div
              className="inputbox-element"
              onClick={() => {
                this.setMaterial('2');
              }}
            >
              {this.displayButton(this.state.material.indexOf('2') > -1)}
              <span
                className="btn-text"
                style={
                  this.state.material.indexOf('2') > -1
                    ? { color: '#326bb5', fontWeight: 500 }
                    : {}
                }
              >
                크레파스
              </span>
            </div>
            <div
              className="inputbox-element"
              onClick={() => {
                this.setMaterial('3');
              }}
            >
              {this.displayButton(this.state.material.indexOf('3') > -1)}
              <span
                className="btn-text"
                style={
                  this.state.material.indexOf('3') > -1
                    ? { color: '#326bb5', fontWeight: 500 }
                    : {}
                }
              >
                유화
              </span>
            </div>
            <div
              className="inputbox-element"
              onClick={() => {
                this.setMaterial('6');
              }}
            >
              {this.displayButton(this.state.material.indexOf('6') > -1)}
              <span
                className="btn-text"
                style={
                  this.state.material.indexOf('6') > -1
                    ? { color: '#326bb5', fontWeight: 500 }
                    : {}
                }
              >
                오일파스텔
              </span>
            </div>
          </div>
        </div>
        <div className="p-3">
          <span className="weight900">Base</span>
          <div className="inputbox-container py-2">
            <div
              className="inputbox-element"
              onClick={() => {
                this.setState({ base: 0 });
              }}
            >
              {this.displayButton(this.state.base === 0)}
              <span
                className="btn-text"
                style={
                  this.state.shirts ? { color: '#326bb5', fontWeight: 500 } : {}
                }
              >
                캔버스
              </span>
            </div>
            <div
              className="inputbox-element"
              onClick={() => {
                this.setState({ base: 1 });
              }}
            >
              {this.displayButton(this.state.base === 1)}
              <span
                className="btn-text"
                style={
                  this.state.shoes ? { color: '#326bb5', fontWeight: 500 } : {}
                }
              >
                종이
              </span>
            </div>
            <div
              className="inputbox-element"
              onClick={() => {
                this.setState({ base: 2 });
              }}
            >
              {this.displayButton(this.state.base === 2)}
              <span
                className="btn-text"
                style={
                  this.state.shoes ? { color: '#326bb5', fontWeight: 500 } : {}
                }
              >
                기타
              </span>
            </div>
          </div>
        </div>
        <div className="p-3">
          <span className="weight900">가로</span>
          <input
            type="number"
            value={this.state.width}
            onChange={(e) => this.setState({ width: e.target.value })}
            className="textbox numberbox input-text"
          />
          <span className="weight900">cm</span>
          <br />
          <span className="weight900">세로</span>
          <input
            type="number"
            value={this.state.height}
            onChange={(e) => this.setState({ height: e.target.value })}
            className="textbox numberbox input-text"
          />
          <span className="weight900">cm</span>
        </div>
        <div className="p-3">
          <span className="weight900">기타 여부</span>
          <div className="inputbox-container py-2">
            <div
              className="inputbox-element"
              onClick={() => {
                this.setState({ onSale: !this.state.onSale });
              }}
            >
              {this.displayButton(this.state.onSale)}
              <span
                className="btn-text"
                style={
                  this.state.onSale ? { color: '#326bb5', fontWeight: 500 } : {}
                }
              >
                판매
              </span>
            </div>
            <div
              className="inputbox-element"
              onClick={() => {
                this.setState({ soldout: !this.state.soldout });
              }}
            >
              {this.displayButton(this.state.soldout)}
              <span
                className="btn-text"
                style={
                  this.state.soldout
                    ? { color: '#326bb5', fontWeight: 500 }
                    : {}
                }
              >
                품절
              </span>
            </div>
            <div
              className="inputbox-element"
              onClick={() => {
                this.setState({ own: !this.state.own });
              }}
            >
              {this.displayButton(this.state.own)}
              <span
                className="btn-text"
                style={
                  this.state.own ? { color: '#326bb5', fontWeight: 500 } : {}
                }
              >
                소유
              </span>
            </div>
          </div>
          <span className="weight900">커스텀 가능 품목</span>
          <div className="inputbox-container">
            <div
              className="inputbox-element"
              onClick={() => {
                this.setState({ shirts: !this.state.shirts });
              }}
            >
              {this.displayButton(this.state.shirts)}
              <span
                className="btn-text"
                style={
                  this.state.shirts ? { color: '#326bb5', fontWeight: 500 } : {}
                }
              >
                티셔츠
              </span>
            </div>
            <div
              className="inputbox-element"
              onClick={() => {
                this.setState({ shoes: !this.state.shoes });
              }}
            >
              {this.displayButton(this.state.shoes)}
              <span
                className="btn-text"
                style={
                  this.state.shoes ? { color: '#326bb5', fontWeight: 500 } : {}
                }
              >
                신발
              </span>
            </div>
            <div
              className="inputbox-element"
              onClick={() => {
                this.setState({ ecobag: !this.state.ecobag });
              }}
            >
              {this.displayButton(this.state.ecobag)}
              <span
                className="btn-text"
                style={
                  this.state.ecobag ? { color: '#326bb5', fontWeight: 500 } : {}
                }
              >
                에코백
              </span>
            </div>
            <div
              className="inputbox-element"
              onClick={() => {
                this.setState({ else: !this.state.else });
              }}
            >
              {this.displayButton(this.state.else)}
              <span
                className="btn-text"
                style={
                  this.state.else ? { color: '#326bb5', fontWeight: 500 } : {}
                }
              >
                기타
              </span>
            </div>
          </div>
          <span className="weight900">ZiBEZI's pick</span>
          <div className="inputbox-container">
            <div
              className="inputbox-element"
              onClick={() => {
                this.setState({ pick: !this.state.pick });
              }}
            >
              {this.displayButton(this.state.pick)}
              <span
                className="btn-text"
                style={
                  this.state.pick ? { color: '#326bb5', fontWeight: 500 } : {}
                }
              >
                PICK
              </span>
            </div>
          </div>
        </div>
        {this.state.status ? (
          <div className="h100px border-top flex justify-content-center align-items-center w100p">
            <Spinner animation={'border'} />
          </div>
        ) : (
          <div
            onClick={() => {
              this.setState({ status: true });
              this.upload();
            }}
            className="h100px border-top pointer"
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              width: '100%',
            }}
          >
            <span className="weight900">업로드하기</span>
          </div>
        )}
      </Col>
    );
  }
}
export default UploadArtworks;
