import React, { Component } from 'react';
import 'style.scss';
import { Row, Col } from 'react-bootstrap';
import './StoryCss.scss';
class StoryIntro extends Component {
  constructor(props) {
    super(props);
    this.state = {
      contents: this.props.children,
    };
  }

  render() {
    return (
      <Row>
        <div className="main_wrap">
          <div className="main_cont">
            <div className="main_h_line"></div>
            <p className="text1">
              <h5>Modern Art Gallery &amp; Shop</h5>
            </p>
            <h1 className="text1">
              <b>
                About <br></br>
                ZiBEZI's&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              </b>
              <br></br>
            </h1>

            <p className="text2">Welcome to ZiBEZI world</p>
            <div className="button_wrap">
              <Col md={4} className="main_button">
                <a href="portfolio.html">Profile</a>
              </Col>
              <Col md={4} className="main_button">
                <a href="portfolio.html">MajorWork</a>
              </Col>
              <Col md={4} className="main_button">
                <a href="portfolio.html">portfolio</a>
              </Col>
            </div>
          </div>
        </div>
      </Row>
    );
  }
}
export default StoryIntro;
