import React, { Component } from 'react';
import './NoticeCss.scss';
import { Container, Row, Col } from 'react-bootstrap';
import ScrollAnimation from 'react-animate-on-scroll';
import Modal from 'react-responsive-modal';
class NoticeBoard extends Component {
  constructor(...args) {
    super(...args);

    this.state = { TipsModalShow: false, TradeModalShow: false, open: false };
  }
  onOpenModal = () => {
    this.setState({ TradeModalShow: true });
  };

  onCloseModal = () => {
    this.setState({ TradeModalShow: false });
  };

  render() {
    let TipsModalClose = () => this.setState({ TipsModalShow: false });
    let TradeModalClose = () => this.setState({ TradeModalShow: false });

    return (
      <Container fluid>
        <div className="header-container">
          <div className="header-title">Notice / Tips</div>
        </div>

        <Row style={{ display: 'block' }}>
          <ScrollAnimation animateOnce={true} animateIn="from-bottom-animation">
            <Col
              xs={6}
              sm={6}
              md={3}
              className="blue-hover card-Style"
              onClick={() => this.setState({ TradeModalShow: true })}
            >
              <div className="image-container">
                <img
                  src={require('images/parasite.jpg')}
                  alt=""
                  className="notice-card-image"
                ></img>
              </div>
              <div className="temp-text-box">
                <p className="card-subtitle">Notice</p>

                <p className="card-desc">
                  작품거래는 공식계정을 통하여 1:1 상담 후 안내드립니다 (kakao
                  플친 ID : zibezi)
                </p>
              </div>
            </Col>
            <Modal
              open={this.state.TradeModalShow}
              onClose={TradeModalClose}
              center
            >
              <img
                className="ModalImage"
                src={require(`images/Trading ArtWorks.jpg`)}
                alt=""
              />
              <div className="ModalBtnContainer">
                <a href="http://pf.kakao.com/_eViTT">
                  <div className="ModalBtn">문의하기</div>
                </a>
              </div>
            </Modal>

            {/* <TradeModal open={this.state.TradeModalShow} onHide={TradeModalClose} /> */}

            <Col
              xs={6}
              sm={6}
              md={3}
              className="blue-hover card-Style"
              onClick={() => alert('준비중입니다!')}
            >
              <div className="image-container">
                <img
                  src={require('images/customerReview.jpg')}
                  className="notice-card-image"
                  alt=""
                ></img>
              </div>
              <div className="temp-text-box">
                <p className="card-subtitle">Reviews</p>

                <p className="card-desc">
                  고객님들의 후기(review)를 바탕으로 공간에 더 어울리는 그림을
                  선택하세요.
                </p>
              </div>
            </Col>

            <Col
              xs={6}
              sm={6}
              md={3}
              className="blue-hover  card-Style"
              onClick={() => this.setState({ TipsModalShow: true })}
            >
              <div className="image-container">
                <img
                  alt=""
                  src={require(`images/tips.jpg`)}
                  className="notice-card-image"
                ></img>
              </div>
              <div className="temp-text-box">
                <p className="card-subtitle">Tips</p>

                <p className="card-desc">
                  그림과 액자의 위치, 거는 방법을 알려드립니다. 더 아름다운
                  공간을 만들어보세요.
                </p>
              </div>
            </Col>
            <Modal
              open={this.state.TipsModalShow}
              onClose={TipsModalClose}
              center
            >
              <img
                className="ModalImage"
                src={require(`images/tfh.jpg`)}
                alt=""
              />
              <div className="ModalBtnContainer">
                <a href="http://pf.kakao.com/_eViTT">
                  <div className="ModalBtn">문의하기</div>
                </a>
              </div>
            </Modal>
            <Col xs={6} sm={6} md={3} className="blue-hover  card-Style">
              <div className="image-container">
                <img
                  alt=""
                  src={require(`images/artConsulting.jpg`)}
                  className="notice-card-image"
                ></img>
              </div>
              <div className="temp-text-box">
                <p className="card-subtitle">Consulting</p>

                <p className="card-desc">
                  거실, 침실, 아이방, 카페, 레스토랑, 사무실 등 각 공간에 맞는
                  컨설팅을 해드립니다
                </p>
              </div>
            </Col>
          </ScrollAnimation>
        </Row>
      </Container>
    );
  }
}
export default NoticeBoard;
