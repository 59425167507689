import React, { Component, Fragment } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { Database, Storage, Firebase } from 'components';
import { Link } from 'react-router-dom';

import 'components/style.scss';
import 'style.scss';
import { observer, inject } from 'mobx-react';

@inject('store')
@observer
class Cart extends Component {
  constructor(props) {
    super(props);

    this.state = {
      user: Firebase.auth().currentUser,
      show: false,
      loading: true,
      cart_list: [],
      list: [],
      tmp: [],
    };
  }

  componentWillMount = () => {
    this.UpdateDatabase();
  };
  UpdateDatabase() {
    console.log('update database:', this.props.store.user.uid);
    let db = Database;
    let storage = Storage;
    let list = [];
    db.collection('Cart')
      .where('uid', '==', this.props.store.user.uid)
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((element, key) => {
          console.log('element: ', element.id);
          let tmp = {};
          if (element.data().gid === 'CUSTOM') {
            let cid = element.id;
            tmp.isCustom = true;
            tmp.key = cid;
            tmp.title = '커스텀';
            tmp.eng_title = 'Custom';
            tmp.price = element.data().price;
            tmp.description = '';
            tmp.amount = element.data().count;
            tmp.option = element.data().options;
            tmp.demand = element.data().demand;
            storage
              .ref('custom')
              .child('/' + cid)
              .getDownloadURL()
              .then((url) => {
                tmp.src = url;
              })
              .catch((err) => {
                console.log(err);
              });
            list.push(tmp);
          } else {
            let gid = element.data().gid;
            db.collection('Goods')
              .doc(gid)
              .get()
              .then((q) => {
                tmp.key = gid;
                tmp.isCustom = false;
                tmp.title = q.data().title;
                tmp.eng_title = q.data().title;
                tmp.price = q.data().price;
                tmp.description = q.data().description;
                tmp.amount = element.data().count;
                tmp.option = element.data().options;
                tmp.demand = element.data().demand;
              })
              .catch((err) => console.log(err));

            storage
              .ref('goods')
              .child('/' + gid + '/0')
              .getDownloadURL()
              .then((url) => {
                tmp.src = url;
              })
              .catch((err) => console.log(err));
            list.push(tmp);
          }
        });
      })
      .then(() => {
        setTimeout(() => {
          this.setState({ loading: false, list });
        }, 1000);
      })
      .catch((err) => console.log(err));
  }

  onDelete = async (key) => {
    //delete one item
    let cartRef = Database.collection('Cart');
    const uid = this.props.store.user.uid;
    await cartRef
      .where('uid', '==', uid)
      .where('gid', '==', key)
      .get()
      .then(function (querySnapshot) {
        querySnapshot.forEach((doc) => {
          doc.ref.delete();
        });
      })
      .catch(function (error) {
        console.log('Error Deleting carts: ', error);
      });

    //update list
    this.UpdateDatabase();
  };

  onDeleteAll = async () => {
    // delete all,
    let cartRef = Database.collection('Cart');
    const uid = this.props.store.user.uid;
    console.log('uid:', uid);
    await cartRef
      .where('uid', '==', uid)
      .get()
      .then(function (querySnapshot) {
        querySnapshot.forEach((doc) => {
          doc.ref.delete();
        });
      })
      .catch(function (error) {
        console.log('Error Deleting All carts: ', error);
      });
    //update list after delete
    this.UpdateDatabase();
  };
  displayWon = (src) => {
    let tmp = src;
    if (src !== undefined) {
      if (typeof src === 'string') {
        tmp = src.replace(/,/gi, '');
        tmp = tmp.replace(/\s/gi, '');
      }
      tmp = tmp.toString();
      let index = tmp.length;
      while (1) {
        index -= 3;
        if (index < 1) return tmp;
        tmp = tmp.slice(0, index) + ',' + tmp.slice(index, tmp.length);
      }
    }
  };

  render() {
    return (
      <Container
        style={{ minHeight: '80vh', backgroundColor: '#efefef', margin: 0 }}
      >
        <Row className="m-2 p-3 cart-container">
          <Col md={9} className="cart">
            <div className="cart-header">
              <span>Review your order:</span>
              <div
                className="cart-remove-all pointer"
                onClick={this.onDeleteAll}
              >
                Clear the Shopping Cart X
              </div>
            </div>
            <div className="cart-body">
              {this.state.list.map((item, key) => {
                return !item.isCustom ? (
                  <Fragment key={key}>
                    <div className="cart-element">
                      <div className="cart-image">
                        <img src={item.src} alt="" />
                      </div>
                      <div className="cart-description">
                        <span
                          style={{
                            fontWeight: 700,
                            fontSize: '16px',
                            marginBottom: '5px',
                          }}
                        >
                          {item.title}
                        </span>
                        <span>{item.eng_title}</span>

                        {item.option.color !== undefined && (
                          <span>{item.option.color}</span>
                        )}
                        {item.option.size !== undefined && (
                          <span>{item.option.size}</span>
                        )}
                        {item.option.shoes_model !== undefined && (
                          <span>{item.option.shoes_model}</span>
                        )}
                        {item.demand !== undefined && (
                          <span>요청사항 : {item.demand}</span>
                        )}
                      </div>
                      <div className="cart-remove">
                        <div
                          className="remove-btn pointer"
                          onClick={() => {
                            this.onDelete(item.key);
                          }}
                        >
                          Remove X
                        </div>
                      </div>
                    </div>
                    <div className="cart-price">
                      <div>
                        <span>Price: </span>
                        <span
                          style={{
                            color: '#333',
                            fontWeight: 700,
                            fontSize: '16px',
                            marginLeft: '10px',
                            marginRight: '5px',
                          }}
                        >
                          {this.displayWon(item.price)}
                        </span>
                        <span style={{ fontWeight: 700 }}>₩</span>
                      </div>
                      <div>
                        <span>Amount: </span>
                        <input
                          type="number"
                          style={{ backgroundColor: '#fff' }}
                          value={this.state.list[key].amount}
                          onChange={(e) => {
                            let list = this.state.list.slice();
                            list[key].amount = e.target.value;
                            console.log(list[key]);
                            this.setState({ list });
                          }}
                        />
                      </div>
                      <div>
                        <span>Subtotal: </span>
                        <span
                          style={{
                            color: '#333',
                            fontWeight: 700,
                            fontSize: '16px',
                            marginLeft: '10px',
                            marginRight: '5px',
                          }}
                        >
                          {this.displayWon(item.price * item.amount)}
                        </span>
                        <span style={{ fontWeight: 700 }}>₩</span>
                      </div>
                    </div>
                  </Fragment>
                ) : (
                  <Fragment key={key}>
                    <div className="cart-element">
                      <div className="cart-image">
                        <img src={item.src} alt="" />
                      </div>
                      <div className="cart-description">
                        <span
                          style={{
                            fontWeight: 700,
                            fontSize: '16px',
                            marginBottom: '5px',
                          }}
                        >
                          {item.title}
                        </span>
                        {item.base}
                        {item.option.map((element) => {
                          if (!element.deleted) {
                            if (element.type)
                              return (
                                <span>아트워크 추가 : {element.title}</span>
                              );
                            else
                              return <span> 텍스트 추가 : {element.text}</span>;
                          }
                        })}

                        {item.demand !== undefined && (
                          <span>요청사항 : {item.demand}</span>
                        )}
                      </div>
                      <div className="cart-remove">
                        <div
                          className="remove-btn pointer"
                          onClick={() => {
                            this.onDelete(item.key);
                          }}
                        >
                          Remove X
                        </div>
                      </div>
                    </div>
                    <div className="cart-price">
                      <div>
                        <span>Price: </span>
                        <span
                          style={{
                            color: '#333',
                            fontWeight: 700,
                            fontSize: '16px',
                            marginLeft: '10px',
                            marginRight: '5px',
                          }}
                        >
                          {this.displayWon(item.price)}
                        </span>
                        <span style={{ fontWeight: 700 }}>₩</span>
                      </div>
                      <div>
                        <span>Amount: </span>
                        <input
                          type="number"
                          style={{ backgroundColor: '#fff' }}
                          value={this.state.list[key].amount}
                          onChange={(e) => {
                            let list = this.state.list.slice();
                            list[key].amount = e.target.value;
                            this.setState({ list });
                          }}
                        />
                      </div>
                      <div>
                        <span>Subtotal: </span>
                        <span
                          style={{
                            color: '#333',
                            fontWeight: 700,
                            fontSize: '16px',
                            marginLeft: '10px',
                            marginRight: '5px',
                          }}
                        >
                          {this.displayWon(item.price * item.amount)}
                        </span>
                        <span style={{ fontWeight: 700 }}>₩</span>
                      </div>
                    </div>
                  </Fragment>
                );
              })}
            </div>
          </Col>
          <Col md={3} className="cart-summary">
            <div className="cart-summary-header">
              <span>Order Summary:</span>
            </div>
            <div className="cart-summary-price">
              <span>Total:</span>
              <span>
                {(() => {
                  let sum = 0;
                  this.state.list.forEach((item) => {
                    sum += item.price * item.amount;
                  });
                  return this.displayWon(sum);
                })()}
                ₩
              </span>
            </div>
            <Link
              to={{
                pathname: '/payment',
                state: {
                  list: this.state.list,
                },
              }}
              className="cart-order pointer"
            >
              Order
            </Link>
          </Col>
        </Row>
      </Container>
    );
  }
}
export default Cart;
