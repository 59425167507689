import React, { Component } from 'react';
import { Container, Row, Col, Image, Spinner } from 'react-bootstrap';
import { Link } from 'react-router-dom';
//import {Icon} from 'semantic-ui-react'
import 'style.scss';
import '../../components/Story/StoryCss.scss';
import { Database, Storage } from 'components';

class GalleryDetail extends Component {
  constructor(props) {
    super(props);
    let key = this.props.match.params.aid;
    this.state = {
      key,
      title: '',
      eng_title: '',
      width: '',
      height: '',
      //hashtags : '',
      date: '',
      on_sale: false,
      own: false,
      soldout: false,
      form: 'canvas',
      material: '',
      url: '',
    };
  }
  setMaterial(value) {
    if (value === 0) {
      return 'ETC';
    } else if (value === 1) {
      return 'acrylic';
    } else if (value === 2) {
      return 'crayon';
    } else if (value === 3) {
      return 'oil';
    }
  }
  componentDidMount() {
    const key = this.state.key;
    let docRefs = Database.collection('Artworks').doc(key);
    let storageRefs = Storage.ref('artworks');
    docRefs
      .get()
      .then((doc) => {
        storageRefs
          .child('/' + key)
          .getDownloadURL()
          .then((url) => {
            const data = doc.data();

            this.setState({
              key,
              title: data.title,
              eng_title: data.eng_title,
              width: data.width,
              height: data.height,
              // hashtags:data.hashtags.join(),
              date: data.date,
              on_sale: data.on_sale,
              own: data.own,
              soldout: data.soldout,
              material: data.material,
              url,
            });
          })
          .catch((err) => {
            console.log(err);
          });
      })
      .catch((err) => console.log(err));
  }
  render() {
    return (
      <Container fluid className="gallery-detail">
        <Row>
          {this.state.url ? (
            <Col xs={12} className="paddingTop">
              <Col
                md={6}
                sm={6}
                xs={12}
                className="image-box flex justify-content-center align-items-center"
              >
                <Image src={this.state.url} className="image" />
              </Col>
              <Col
                md={6}
                sm={6}
                xs={12}
                className="image-box flex justify-content-center align-items-center"
                style={{
                  flexDirection: 'column',
                  justifyContent: 'space-around',
                }}
              >
                <Col className="padding">
                  <Col
                    xs={12}
                    style={{
                      borderColor: 'lightgray',
                      borderBottomStyle: 'solid',
                      borderBottomWidth: '1px',
                    }}
                  >
                    <h3 className="galleryModalTitle">{this.state.title} </h3>

                    <p className="galleryModalDetail">{this.state.eng_title}</p>
                  </Col>
                  <Col
                    xs={12}
                    style={{
                      borderColor: 'lightgray',
                      borderBottomStyle: 'solid',
                      borderBottomWidth: '1px',
                    }}
                  >
                    <div>
                      <h5> Detail </h5>
                      <p className="galleryModalDetail">
                        Year : {' ' + this.state.date}
                      </p>
                      <p className="galleryModalDetail">
                        Size:{' ' + this.state.width}cm{'X'}
                        {this.state.height}cm
                      </p>
                      <p className="galleryModalDetail">
                        Material:{' ' + this.setMaterial(this.state.material)}
                      </p>
                      <p className="galleryModalDetail">
                        Form:{' ' + this.state.form}
                      </p>
                      {/* <p className="galleryModalDetail">Tag:{" " + this.state.hashtags['0']}</p> */}
                    </div>
                  </Col>
                  <Col
                    xs={12}
                    style={{
                      borderColor: 'lightgray',
                      borderBottomStyle: 'solid',
                      borderBottomWidth: '1px',
                    }}
                  >
                    <div>
                      <h5>Status</h5>
                      <div
                        className="galleryIcons"
                        style={{ display: 'inline-flex' }}
                      >
                        {this.state.own && (
                          <div className="iconContainer">
                            <div
                              className="modalDot"
                              style={{ backgroundColor: '#98FB98' }}
                            />
                          </div>
                        )}
                        {this.state.soldout && (
                          <div className="iconContainer">
                            <div
                              className="modalDot"
                              style={{ backgroundColor: '#DC143C' }}
                            />
                          </div>
                        )}
                        {this.state.on_sale && (
                          <div className="iconContainer">
                            <img src={require(`images/shop.png`)} alt="" />
                          </div>
                        )}
                      </div>
                    </div>
                  </Col>
                  <Col
                    xs={12}
                    style={{
                      borderColor: 'lightgray',
                      borderBottomStyle: 'solid',
                      borderBottomWidth: '1px',
                    }}
                  >
                    <div>
                      <a
                        href="http://pf.kakao.com/_eViTT"
                        className="GoShopBtn"
                      >
                        문의
                      </a>
                      {this.state.on_sale && (
                        <Link
                          to={'/shop/artworks/' + this.state.key}
                          className="GoShopBtn"
                        >
                          Shop
                        </Link>
                      )}
                    </div>
                    <p style={{ fontSize: '10px', marginBottom: '0px' }}>
                      All rights reserved by ZiBEZI
                    </p>
                  </Col>
                </Col>
              </Col>
            </Col>
          ) : (
            <Col
              xs={12}
              className="flex justify-content-center align-items-center"
              style={{ height: '80vh' }}
            >
              <div>
                <Spinner animation={'border'} />
              </div>
            </Col>
          )}
        </Row>
      </Container>
    );
  }
}
export default GalleryDetail;
