import React, { Component } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import Modal from 'react-modal';
import EditArtworks from './EditArtworks';
import EditGoods from './EditGoods';

import '../style.scss';
import 'style.scss';
import { observer, inject } from 'mobx-react';

@inject('store')
@observer
class EditModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show: this.props.show,
      category: this.props.category, //false = Artworks, true = Goods
      elementkey: this.props.elementkey,
    };
  }

  componentWillReceiveProps = (nextProps) => {
    this.setState({
      category: nextProps.category,
      show: nextProps.show,
      elementkey: nextProps.elementkey,
    });
  };
  render() {
    let display = !this.state.category ? (
      <EditArtworks
        elementkey={this.state.elementkey}
        callback={() => {
          this.props.callback();
        }}
      />
    ) : (
      <EditGoods
        elementkey={this.state.elementkey}
        callback={() => this.props.callback()}
      />
    );
    return (
      <Modal
        ariaHideApp={false}
        isOpen={this.state.show}
        onRequestClose={() => {
          this.props.callback();
        }}
        className="upload-modal"
        overlayClassName="modal-overlay"
      >
        <Container fluid>
          <Row className="modal-container">
            <Col
              xs={12}
              className="w100p h100px flex align-items-center justify-content-center p-3 border-bottom"
            >
              작품 및 상품 업로드
            </Col>
            <Col xs={12} className="w100p height-rest px-3">
              {display}
            </Col>
          </Row>
        </Container>
      </Modal>
    );
  }
}
export default EditModal;
