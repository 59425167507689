import React, { Component } from 'react';

import 'style.scss';
import { Container, Row, Col } from 'react-bootstrap';
import './StoryCss.scss';
import ScrollAnimation from 'react-animate-on-scroll';

const contents = [
  {
    src: require('images/story/major1.jpeg'),
    title: 'The King and the Queen',
    year: '2020',
  },
  {
    src: require('images/story/major2.jpeg'),
    title: 'Parasite',
    year: '2018',
  },
  {
    src: require('images/story/major5.jpeg'),
    title: 'LA',
    year: '2019',
  },

  {
    src: require('images/story/major4.png'),
    title: 'Pastel love',
    year: '2022',
  },
  {
    src: require('images/story/major3.jpeg'),
    title: 'The dust in Pastel',
    year: '2024',
  },
  {
    src: require('images/story/major6.jpeg'),
    title: 'Living creatures',
    year: '2020',
  },
  {
    src: require('images/story/major7.jpeg'),
    title: 'ZiBEZI Showcase',
    year: '2022',
  },
  {
    src: require('images/story/major8.jpeg'),
    title: 'Future Human',
    year: '2019',
  },
  {
    src: require('images/story/major9.jpeg'),
    title: 'My Room ',
    year: '2021',
  },
  {
    src: require('images/story/major10.jpeg'),
    title: 'A Fairy in the Forest',
    year: '2019',
  },
  {
    src: require('images/story/major11.jpeg'),
    title: 'Fast Movement',
    year: '2022',
  },
  {
    src: require('images/story/major12.jpeg'),
    title: 'Four Seasons ',
    year: '2020',
  },
  {
    src: require('images/story/major13.jpeg'),
    title: 'Pastel World',
    year: '2022',
  },
  {
    src: require('images/story/major14.jpeg'),
    title: 'Home',
    year: '2018',
  },
  {
    src: require('images/story/major15.jpeg'),
    title: 'The hour of the Earth ',
    year: '2020',
  },
  {
    src: require('images/story/major16.png'),
    title: 'Star treck',
    year: '2020',
  },
];

class StoryMajor extends Component {
  render() {
    return (
      <Container fluid>
        <Row className="story-body">
          <div className="story-title-container">
            <div className="title-line"></div>
            <h3 className="story-title">Major Work</h3>
            <h3 className="story-stitle"> &nbsp; ZiBEZI</h3>
          </div>
        </Row>
        <Row
          className="clearfix"
          style={{ display: 'block', textAlign: 'center' }}
        >
          <ScrollAnimation animateOnce={true} animateIn="from-bottom-animation">
            {contents.map(({ src, title, year }) => (
              <Col
                xs={6}
                sm={6}
                md={3}
                key={title}
                className="blue-hover card-Style between"
              >
                <div className="major-image-container">
                  <img src={src} className="major-image" alt=""></img>
                </div>
                <div className="px-2 py-2">
                  <p className="major-subtitle">{year}</p>

                  <h1 className="card-title">{title}</h1>
                </div>
              </Col>
            ))}
          </ScrollAnimation>
        </Row>
      </Container>
    );
  }
}
export default StoryMajor;
