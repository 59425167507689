import React, { Component } from 'react';
import '../../style.scss';

class Image extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dimensions: {},
    };
    this.onImgLoad = this.onImgLoad.bind(this);
    console.log(this.props.src);
  }
  onImgLoad({ target: img }) {
    this.setState({
      dimensions: { height: img.offsetHeight, width: img.offsetWidth },
    });
  }
  render() {
    const { src } = this.props;
    const { width, height } = this.state.dimensions;

    return (
      <div>
        <p style={{ color: '#ffffff' }}>
          dimensions width{width}, height{height}
        </p>
        <img onLoad={this.onImgLoad} alt="a" src={src} />
      </div>
    );
  }
}
export default Image;
