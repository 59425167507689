import React, { Component } from 'react';
import { Row, Col } from 'react-bootstrap';
import '../style.scss';
import 'style.scss';

class HomeIntroduce extends Component {
  render() {
    return (
      <Row className="introduce-box">
        <Col
          lg={{ span: 6, offset: 3 }}
          md={{ span: 8, offset: 2 }}
          xs={{ span: 10, offset: 1 }}
        >
          <p className="side-text">{this.props.title}</p>
          <div className="side-text-sub">
            <p className="side-text-sub">{this.props.subtitle}</p>
          </div>
          <br />
          <p style={{ color: '#333', fontSize: '12px', textAlign: 'center' }}>
            {this.props.paragraph}
          </p>
          <br />
        </Col>
      </Row>
    );
  }
}
export default HomeIntroduce;
