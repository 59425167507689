import React, { Component, Fragment } from 'react';
import { Route, Switch, BrowserRouter } from 'react-router-dom';
import { Container, Row, Spinner } from 'react-bootstrap';
import { createBrowserHistory } from 'history';
import { Provider } from 'mobx-react';
import Store from './stores';
import './style.scss';
import {
  Home,
  Story,
  Gallery,
  Shop,
  Notice,
  Login,
  SignUp,
  Mypage,
  Admin,
  Payment,
  Reform,
  Custom,
} from './routes';
import { Firebase } from 'components';
import { NavigationBar, Footer, NotFound, paySuccess } from 'components';
import ScrollToTop from './ScrollToTop';

const store = new Store();
const history = createBrowserHistory();
class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
    };
  }
  componentDidMount = async () => {
    Firebase.auth().onAuthStateChanged((user) => {
      if (user) store.user.successLogin(user.email);
      setTimeout(() => {
        this.setState({ loading: false });
      }, 0);
    });
  };
  render() {
    return (
      <Provider store={store}>
        <BrowserRouter history={history}>
          <ScrollToTop>
            <Container
              fluid="md"
              className="scroll-hidden"
              style={{ backgroundColor: '#efefef', margin: 0, zIndex: 0 }}
            >
              {this.state.loading ? (
                <div
                  style={{
                    position: 'absolute',
                    zIndex: 1000,
                    top: 0,
                    left: 0,
                    width: '100vmax',
                    height: '100vmax',
                    backgroundColor: 'rgba(51,51,51,0.7)',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <Spinner animation={'border'} variant="light" size={'lg'} />
                </div>
              ) : (
                <div />
              )}
              <Fragment>
                <Row>
                  <NavigationBar history={history} />
                </Row>
                <Row>
                  <Switch>
                    <Route exact path="/" component={Home} />
                    <Route path="/gallery" component={Gallery} />
                    <Route path="/shop" component={Shop} />
                    <Route path="/story" component={Story} />
                    <Route path="/notice" component={Notice} />
                    <Route path="/mypage" component={Mypage} />
                    {/* <Route path = "/cart" component={Cart}/> */}
                    <Route path="/login" component={Login} />
                    <Route path="/signup" component={SignUp} />
                    <Route path="/admin" component={Admin} />
                    {/* <Route path="/payment" component={Payment} />
                    <Route path="/paySuccess" component={paySuccess} />
                    <Route path="/reform" component={Reform} />
                    <Route path="/custom" component={Custom} /> */}
                    <Route component={NotFound} />
                  </Switch>
                </Row>
              </Fragment>
            </Container>
            <Footer />
          </ScrollToTop>
        </BrowserRouter>
      </Provider>
    );
  }
}
export default App;
