import React, { Component } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import Modal from 'react-modal';
import UploadArtworks from './UploadArtworks';
import UploadGoods from './UploadGoods';

import '../style.scss';
import 'style.scss';
import { observer, inject } from 'mobx-react';

@inject('store')
@observer
class UploadModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
      category: this.props.category, //false = Artworks, true = Goods
    };
  }
  componentWillReceiveProps = (nextProps) => {
    this.setState({ show: nextProps.show });
  };
  categorySelect = (category) => {
    this.setState({ category });
  };

  render() {
    return (
      <Modal
        ariaHideApp={false}
        isOpen={this.state.show}
        onRequestClose={() => {
          this.props.callback();
        }}
        className="upload-modal"
        overlayClassName="modal-overlay"
      >
        <Container fluid>
          <Row className="modal-container">
            <Col
              xs={12}
              className="w100p h100px flex align-items-center justify-content-center p-3 border-bottom"
            >
              작품 및 상품 업로드
            </Col>
            <Col xs={12} className="w100p height-rest px-3">
              <Col xs={12} className="flex justify-content-center my-3">
                <div
                  onClick={() => this.categorySelect(0)}
                  className={
                    'category-btn m-1 pointer ' +
                    (!this.state.category ? 'category-selected' : '')
                  }
                >
                  <span>Artworks</span>
                </div>
                <div
                  onClick={() => this.categorySelect(1)}
                  className={
                    'category-btn m-1 pointer ' +
                    (this.state.category ? 'category-selected' : '')
                  }
                >
                  <span>Goods</span>
                </div>
              </Col>
              {!this.state.category ? (
                <UploadArtworks callback={() => this.props.callback()} />
              ) : (
                <Col xs={12}>
                  <UploadGoods callback={() => this.props.callback()} />
                </Col>
              )}
            </Col>
          </Row>
        </Container>
      </Modal>
    );
  }
}
export default UploadModal;
