import React, { Component } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import 'components/style.scss';
import 'style.scss';
import '../Notice/NoticeCss.scss';
import { observer, inject } from 'mobx-react';
import ScrollAnimation from 'react-animate-on-scroll';

@inject('store')
@observer
class ReformComp extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
      edit: false,
      key: null,
    };
  }

  render() {
    return (
      <Container className="artworks-main">
        <Row className="shop-margin-top" />

        <Row className="p-5">
          <Col md={2} xs={4}>
            <NavLink
              exact
              to="/shop"
              className="shop-btn"
              activeClassName="shop-btn-highlight"
            >
              <span>Artworks</span>
            </NavLink>
          </Col>
          <Col md={2} xs={4}>
            <NavLink
              to="/shop/goods"
              className="shop-btn"
              activeClassName="shop-btn-highlight"
            >
              <span style={{ marginLeft: '15px' }}>Goods</span>
            </NavLink>
          </Col>
        </Row>

        <Row className="reform-description">
          <Col xs={12} className="reform-detail-header">
            리폼 (reform)
          </Col>
          <Col
            xs={12}
            className="reform-detail-contents"
            style={{ textAlign: 'center' }}
          >
            <span>
              “리폼”이란 낡거나 오래된 물건을 새롭게 고치는 일로, 다양한 재료에
              적용 가능합니다.
              <br /> 이미 소장하고 있는 오래된 물건들을 zibezi작가의 그림을 그려
              넣어 새로운 느낌과 새로운 스타일의 작품으로 재탄생 가능합니다.
              <br />
              <br /> 하단 양식을 작성하여 카카오톡 문의 해주시면 친절히
              답변드립니다
            </span>
          </Col>
          <Col xs={12}>
            <div className="reform-textarea" rows="20" autocomplete="off">
              <p>
                리폼 대상 물품 : 가방(예시)
                <br />
                리폼 스타일 :
                <br />
                전화 번호 :
                <br />
              </p>
            </div>
          </Col>
        </Row>

        <div className="reform-border-container">
          <div className="reform-border"></div>
        </div>

        <Row style={{ display: 'block' }} className="consult-row">
          <ScrollAnimation animateOnce={true} animateIn="from-bottom-animation">
            <Col xs={6} sm={6} md={6} className="blue-hover  card-Style">
              <div className="image-container">
                <img
                  alt=""
                  src={require(`images/reform/bag.jpg`)}
                  className="card-image"
                ></img>
              </div>
              <div className="temp-text-box">
                <p className="card-subtitle">가방</p>

                <p className="card-desc">
                  소장하고 있는 가방을 지비지 작가의 페인팅을 통해 새로운
                  가방으로 재탄생 할 수 있습니다.
                </p>
              </div>
            </Col>

            <Col xs={6} sm={6} md={6} className="blue-hover  card-Style">
              <div className="image-container">
                <img
                  alt=""
                  src={require(`images/reform/frame.jpg`)}
                  className="card-image"
                ></img>
              </div>
              <div className="temp-text-box">
                <p className="card-subtitle">액자</p>

                <p className="card-desc">
                  소장하고 있는 그림액자 위 혹은 액자안의 그림 위에 지비지
                  작가의 독특한 패턴을 입혀, 새로운 작품으로 재탄생 할 수
                  있습니다.
                </p>
              </div>
            </Col>
            <Col xs={6} sm={6} md={6} className="blue-hover  card-Style">
              <div className="image-container">
                <img
                  alt=""
                  src={require(`images/reform/echo.jpg`)}
                  className="card-image"
                ></img>
              </div>
              <div className="temp-text-box">
                <p className="card-subtitle">에코백</p>

                <p className="card-desc">
                  밋밋했던 에코백 위에 지비지 작가의 페인팅 혹은 패턴을 그려
                  넣어 매일 들고 다닐 수 있는 아트 에코백으로 바꿀 수 있습니다.
                </p>
              </div>
            </Col>

            <Col xs={6} sm={6} md={6} className="blue-hover  card-Style">
              <div className="image-container">
                <img
                  alt=""
                  src={require(`images/reform/jacket.jpg`)}
                  className="card-image"
                ></img>
              </div>
              <div className="temp-text-box">
                <p className="card-subtitle">자켓</p>

                <p className="card-desc">
                  청자켓, 가죽 자켓, 바람막이 등 여러 가지 자켓 위에 지비지
                  작가만의 독특한 페인팅을 입혀 단 하나뿐인 유니크한 자켓으로
                  재탄생 시킬 수 있습니다.{' '}
                </p>
              </div>
            </Col>
            <Col xs={6} sm={6} md={6} className="blue-hover  card-Style">
              <div className="image-container">
                <img
                  alt=""
                  src={require(`images/reform/tshirt.jpg`)}
                  className="card-image"
                ></img>
              </div>
              <div className="temp-text-box">
                <p className="card-subtitle">옷(티셔츠)</p>

                <p className="card-desc">
                  소장하고 있는 티셔츠 위에 지비지 작가만의 독특한 페인팅을 입혀
                  단 하나뿐인 유니크한 자켓으로 재탄생 시킬 수 있습니다.
                </p>
              </div>
            </Col>

            <Col xs={6} sm={6} md={6} className="blue-hover  card-Style">
              <div className="image-container">
                <img
                  alt=""
                  src={require(`images/reform/custom.jpg`)}
                  className="card-image"
                ></img>
              </div>
              <div className="temp-text-box">
                <p className="card-subtitle">what's next</p>

                <p className="card-desc">
                  리폼을 할 수 있는 영역은 무궁무진합니다 :) 당신만의 창의적인
                  아이디어로 집에 잠자고 있는 아이템에 생명을 불어넣어 주세요 :){' '}
                </p>
              </div>
            </Col>
          </ScrollAnimation>
        </Row>
      </Container>
    );
  }
}
export default ReformComp;
