import React, { Component } from 'react';
import { Route, Switch, Link } from 'react-router-dom';
import { Container, Row, Col } from 'react-bootstrap';
import AdminHome from './home';
import AdminStory from './story';
import AdminSoldout from './soldout';
import AdminOrders from './orders';
import AdminTest from './test';
import AdminWork from './work';
import AdminPick from './pick';

import 'components/style.scss';
import 'style.scss';
class Admin extends Component {
  constructor(props) {
    super(props);
    console.log(window.location.href.indexOf('home') === -1);
  }
  render() {
    return (
      <Container>
        <Row>
          <Col xs={12} className="admin-category">
            <Link
              to={'/admin/home'}
              className="admin-category-element pointer"
              style={
                window.location.href.indexOf('home') !== -1
                  ? {
                      textDecoration: 'underline',
                      fontWeight: 500,
                      color: '#333',
                    }
                  : {}
              }
            >
              메인
            </Link>
            <Link
              to={'/admin/story'}
              className="admin-category-element pointer"
              style={
                window.location.href.indexOf('story') !== -1
                  ? {
                      textDecoration: 'underline',
                      fontWeight: 500,
                      color: '#333',
                    }
                  : {}
              }
            >
              스토리
            </Link>
            <Link
              to={'/admin/works'}
              className="admin-category-element pointer"
              style={
                window.location.href.indexOf('works') !== -1
                  ? {
                      textDecoration: 'underline',
                      fontWeight: 500,
                      color: '#333',
                    }
                  : {}
              }
            >
              작품관리
            </Link>
            <Link
              to={'/admin/pick'}
              className="admin-category-element pointer"
              style={
                window.location.href.indexOf('pick') !== -1
                  ? {
                      textDecoration: 'underline',
                      fontWeight: 500,
                      color: '#333',
                    }
                  : {}
              }
            >
              Zibezi's pick
            </Link>
            <Link
              to={'/admin/soldout'}
              className="admin-category-element pointer"
              style={
                window.location.href.indexOf('soldout') !== -1
                  ? {
                      textDecoration: 'underline',
                      fontWeight: 500,
                      color: '#333',
                    }
                  : {}
              }
            >
              품절관리
            </Link>
            <Link
              to={'/admin/orders'}
              className="admin-category-element pointer"
              style={
                window.location.href.indexOf('orders') !== -1
                  ? {
                      textDecoration: 'underline',
                      fontWeight: 500,
                      color: '#333',
                    }
                  : {}
              }
            >
              주문관리
            </Link>
            <Link
              to={'/admin/test'}
              className="admin-category-element pointer"
              style={
                window.location.href.indexOf('test') !== -1
                  ? {
                      textDecoration: 'underline',
                      fontWeight: 500,
                      color: '#333',
                    }
                  : {}
              }
            >
              테스트
            </Link>
          </Col>
        </Row>
        <Row>
          <Switch>
            <Route exact path="/admin/home" component={AdminHome} />
            <Route exact path="/admin/story" component={AdminStory} />
            <Route exact path="/admin/works" component={AdminWork} />
            <Route exact path="/admin/pick" component={AdminPick} />
            <Route exact path="/admin/orders" component={AdminOrders} />
            <Route exact path="/admin/soldout" component={AdminSoldout} />
            <Route exact path="/admin/test" component={AdminTest} />
          </Switch>
        </Row>
      </Container>
    );
  }
}
export default Admin;
