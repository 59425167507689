import React, { Component } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import Modal from 'react-modal';
import '../style.scss';
import 'style.scss';
import { observer, inject } from 'mobx-react';

@inject('store')
@observer
class EmailModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
    };
  }
  componentWillReceiveProps = (nextProps) => {
    this.setState({ show: nextProps.show });
  };

  render() {
    return (
      <Modal
        ariaHideApp={false}
        isOpen={this.state.show}
        className="email-modal"
        onRequestClose={() => {
          this.props.callback();
        }}
        overlayClassName="modal-overlay"
      >
        <Container fluid>
          <Row className="modal-container">
            <Col
              xs={12}
              className="w100p h100px flex align-items-center justify-content-center p-3 border-bottom modal-header"
            >
              Send Email / Inquiry
            </Col>
            <Col xs={12} className="w100p modal-description">
              The contents below will be sent to the artist's email. <br />
              Please feel free to leave any questions about the work and others
              and we will check it as soon as possible (1-3 days){' '}
            </Col>
            <Col xs={12} className="w100p height-rest px-3 modal-body">
              <form
                onSubmit={this.props.onSubmit}
                class="gform"
                method="POST"
                target="iframe1"
                data-email="lubminjee@nate.com"
                action="https://script.google.com/macros/s/AKfycbyBe0RfatfsFclA4Y6iuZJhfFNj5Aie39LBv8wZ9bb072lVzTM-nS0wj0KIxO8MlD9SGg/exec"
              >
                <input
                  type="text"
                  className="modal-input"
                  id="name"
                  name="name"
                  placeholder="Your name/nickname"
                  required
                />
                <input
                  type="text"
                  className="modal-input"
                  id="contact"
                  name="contact"
                  placeholder="Your Contact Info ( Phone number etc )"
                />
                <input
                  type="text"
                  className="modal-input"
                  id="email"
                  name="email"
                  placeholder="Your Email ( Please write it carefully )                    "
                  required
                />
                <textarea
                  id="message"
                  name="message"
                  className="modal-message"
                  placeholder="Please fill out the contents of your email."
                  required
                ></textarea>
                <button id="submit" className="submitButton">
                  Send
                </button>
                <p className="modal-description">
                  Please wait! It takes 5 to 10 seconds to send an email. <br />
                  This popup will close after email sent
                </p>
              </form>
              <img className="modal-image" src={require(`images/zibezi.png`)} />
            </Col>
            <iframe
              title="iframe1"
              id="iframe1"
              name="iframe1"
              style={{ display: 'none' }}
            ></iframe>
          </Row>
        </Container>
      </Modal>
    );
  }
}
export default EmailModal;
