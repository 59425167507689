import React, { Component } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { Database } from 'components';

import 'style.scss';
import 'components/style.scss';
class Orders extends Component {
  constructor(props) {
    super(props);
    this.state = {
      main_slider_elements: [],
      paid: [],
      cancelled: [],
      failed: [],
      ready: [],
      orderDB: [],
    };
  }

  componentDidMount = async () => {
    //paid
    await fetch(
      'https://us-central1-zibezi-gallery.cloudfunctions.net/test/paid',
    )
      .then((res) => {
        return res.text();
      })
      .then((data) => {
        //console.log(data);
        var tmp = JSON.parse(data);
        var tmp_paid = [];

        for (var i = 0; i < tmp['list'].length; i++)
          tmp_paid.push(tmp['list'][i]);
        this.setState({ paid: tmp_paid });
      })
      .catch((err) => {
        console.log(err);
      });

    //cancelled
    fetch(
      'https://us-central1-zibezi-gallery.cloudfunctions.net/test/cancelled',
    )
      .then((res) => {
        return res.text();
      })
      .then((data) => {
        var tmp = JSON.parse(data);
        var tmp_cancelled = [];
        for (var i = 0; i < tmp['list'].length; i++)
          tmp_cancelled.push(tmp['list'][i]);
        this.setState({ cancelled: tmp_cancelled });
      })
      .catch((err) => {
        console.log(err);
      });

    //ready
    fetch('https://us-central1-zibezi-gallery.cloudfunctions.net/test/ready')
      .then((res) => {
        return res.text();
      })
      .then((data) => {
        var tmp = JSON.parse(data);
        var tmp_ready = [];
        for (var i = 0; i < tmp['list'].length; i++)
          tmp_ready.push(tmp['list'][i]);
        this.setState({ ready: tmp_ready });
      })
      .catch((err) => {
        console.log(err);
      });

    //failed
    fetch('https://us-central1-zibezi-gallery.cloudfunctions.net/test/failed')
      .then((res) => {
        return res.text();
      })
      .then((data) => {
        var tmp = JSON.parse(data);
        var tmp_failed = [];
        for (var i = 0; i < tmp['list'].length; i++)
          tmp_failed.push(tmp['list'][i]);
        this.setState({ failed: tmp_failed });
      })
      .catch((err) => {
        console.log(err);
      });
  };
  componentWillMount = () => {
    Database.collection('System')
      .doc('slider_info')
      .get()
      .then((doc) => {
        if (doc.exists) {
          let arr = doc.data().slider_arr;
          arr.forEach((element) => {});
        } else {
        }
      })
      .catch((err) => {
        console.log('Database_read_error');
      });
  };

  render() {
    return (
      <Container className="admin from-bottom-animation">
        <Row className="admin-row">
          <Col xs={12} className="admin-category-header">
            <span>결제완료</span>
          </Col>
          <Col xs={12} className="">
            <div className="admin-slider-scroll">
              <div>
                <FilterableContactTable contacts={this.state.paid} />
              </div>
            </div>
          </Col>
          <Col xs={12} className="admin-category-header">
            <span>결제취소</span>
          </Col>
          <Col xs={12} className="">
            <div className="admin-slider-scroll">
              <div>
                <FilterableContactTable contacts={this.state.cancelled} />
              </div>
            </div>
          </Col>
          <Col xs={12} className="admin-category-header">
            <span>미결제</span>
          </Col>
          <Col xs={12} className="">
            <div className="admin-slider-scroll">
              <div>
                <FilterableContactTable contacts={this.state.ready} />
              </div>
            </div>
          </Col>
          <Col xs={12} className="admin-category-header">
            <span>결제실패</span>
          </Col>
          <Col xs={12} className="">
            <div className="admin-slider-scroll">
              <div>
                <FilterableContactTable contacts={this.state.failed} />
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    );
  }
}
export default Orders;

class ContactRow extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      list: [],
    };
  }

  parseMsg = (Msg) => {
    var msgJson = JSON.parse(Msg);
    var goodsList = [];
    if (msgJson.length < 3) {
      for (var i = 0; i < msgJson.length; i++) {
        var text =
          '제품명: ' +
          msgJson[i]['title'] +
          ' ' +
          msgJson[i]['amount'] +
          '개\n';

        if (
          msgJson[i]['option']['color'] !== null ||
          msgJson[i]['option']['shoes_model'] !== null ||
          msgJson[i]['option']['size'] !== null
        ) {
          text = text + '옵션: ';
          if (msgJson[i]['option']['color'] !== null)
            text = text + msgJson[i]['option']['color'] + '/';
          if (msgJson[i]['option']['shoes_model'] !== null)
            text = text + msgJson[i]['option']['shoes_model'] + '/';
          if (msgJson[i]['option']['size'] !== null)
            text = text + msgJson[i]['option']['size'] + '\n';
        }

        text = text + '요청사항: ' + msgJson[i]['demand'] + '\n\n';
        goodsList.push(text);
      }
      return goodsList;
    }
    console.log('goodslist:', goodsList);
  };

  render() {
    return (
      <tr style={{ fontSize: '10px', whiteSpace: 'pre-wrap' }}>
        <td>
          <p>{this.props.contact.buyer_name}</p>
          <p> {this.props.contact.buyer_email}</p>
          <p>{this.props.contact.buyer_tel}</p>
        </td>
        <td>
          {this.props.contact.name}/{this.props.contact.amount}
        </td>
        <td>
          {this.props.contact.buyer_addr}/{this.props.contact.buyer_postcode}
        </td>
        <td>{this.parseMsg(this.props.contact.custom_data)}</td>
      </tr>
    );
  }
}

class ContactTable extends React.Component {
  render() {
    var rows = [];
    this.props.contacts.forEach((contact) => {
      if (contact.name.indexOf(this.props.filterText) === -1) {
        return;
      }
      rows.push(<ContactRow contact={contact} />);
    });
    return (
      <table className="table">
        <thead>
          <tr>
            <th>Name/Email/Phone</th>
            <th>Goods/Amount</th>
            <th>Address</th>
            <th>Msg</th>
          </tr>
        </thead>
        <tbody>{rows}</tbody>
      </table>
    );
  }
}

class FilterableContactTable extends React.Component {
  constructor(props) {
    super(props);
    // FilterableContactTable is the owner of the state as the filterText is needed in both nodes (searchbar and table) that are below in the hierarchy tree.
    this.state = {
      filterText: '',
    };

    this.handleFilterTextInput = this.handleFilterTextInput.bind(this);
  }

  handleFilterTextInput(filterText) {
    //Call to setState to update the UI
    this.setState({
      filterText: filterText,
    });
    //React knows the state has changed, and calls render() method again to learn what should be on the screen
  }

  render() {
    return (
      <div>
        {/* <h5>결제완료</h5> */}

        <ContactTable
          contacts={this.props.contacts}
          filterText={this.state.filterText}
        />
      </div>
    );
  }
}
