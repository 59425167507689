import React, { Component } from 'react';
import 'style.scss';
import '../style.scss';

class Drawer extends Component {
  render() {
    return (
      <div className="test-border" style={{ position: 'relative' }}>
        <div
          style={{
            width: '100%0',
            height: '50px',
            backgroundColor: 'lightgray',
          }}
          className="test-border"
        ></div>
        {this.props.list.forEach((element) => {
          console.log(element);
          return (
            <div
              style={{
                width: '100%',
                height: '50px',
                backgroundColor: 'lightgray',
              }}
              className="test-border"
            >
              <div>{element.title}</div>
              <div>
                <div>{element.content}</div>
              </div>
            </div>
          );
        })}
      </div>
    );
  }
}
export default Drawer;
