import React, { Component } from 'react';
import { Row, Col, Container } from 'react-bootstrap';
import './StoryCss.scss';
import ScrollAnimation from 'react-animate-on-scroll';
import { Database } from 'components';
class StoryPortfolio extends Component {
  constructor(props) {
    super(props);
    this.state = {
      contents: this.props.children,
      solo: '',
      team: '',
      movie: '',
      award: '',
      etc: '',
      jury: '',
    };
  }
  componentWillMount = () => {
    Database.collection('Story')
      .doc('portfolio')
      .get()
      .then((res) => {
        let { solo, team, movie, award, etc, jury } = res.data();
        this.setState({ solo, team, movie, award, etc, jury });
      });
  };
  replaceAll = (str, searchStr, replaceStr) => {
    return str.split(searchStr).join(replaceStr);
  };
  render() {
    return (
      <Container fluid style={{ display: 'inline-block' }}>
        <Row className="story-body">
          <div className="story-title-container">
            <div className="title-line"></div>
            <h3 className="story-title">Portfolio</h3>
            <h3 className="story-stitle"> &nbsp; ZiBEZI</h3>
          </div>
        </Row>
        <Row className="clearfix " style={{ display: 'block' }}>
          <ScrollAnimation
            animateOnce={true}
            animateIn="from-bottom-animation"
            style={{ margin: 'auto' }}
          >
            <Col md={6} xs={12}>
              <div className="timeline_container">
                <ul className="timeline_ul">
                  <ul className="timeline_line"></ul>

                  <li className="timeline_li">
                    <div className="timeline_content">
                      <p className="timeline_content_title">Solo Exhibition</p>
                      <div
                        className="timeline_content_p"
                        style={{ whiteSpace: 'pre-wrap' }}
                      >
                        {this.replaceAll(this.state.solo, '<br/>', '\n')}
                      </div>
                    </div>
                  </li>
                  <li className="timeline_li">
                    <div className="timeline_content">
                      <p className="timeline_content_title">Group Exhibition</p>
                      <div
                        className="timeline_content_p"
                        style={{ whiteSpace: 'pre-wrap' }}
                      >
                        {this.replaceAll(this.state.team, '<br/>', '\n')}
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </Col>
            <Col md={6} xs={12}>
              <div className="timeline_container">
                <ul className="timeline_ul">
                  <ul className="timeline_line"></ul>
                  <li className="timeline_li">
                    <div className="timeline_content">
                      <p className="timeline_content_title">Movies</p>
                      <div
                        className="timeline_content_p"
                        style={{ whiteSpace: 'pre-wrap' }}
                      >
                        {this.replaceAll(this.state.movie, '<br/>', '\n')}
                      </div>
                    </div>
                  </li>
                  <li className="timeline_li">
                    <div className="timeline_content">
                      <p className="timeline_content_title">Awards</p>
                      <div
                        className="timeline_content_p"
                        style={{ whiteSpace: 'pre-wrap' }}
                      >
                        {this.replaceAll(this.state.award, '<br/>', '\n')}
                      </div>
                    </div>
                  </li>
                  <li className="timeline_li">
                    <div className="timeline_content">
                      <p className="timeline_content_title">Collaboration</p>
                      <div
                        className="timeline_content_p"
                        style={{ whiteSpace: 'pre-wrap' }}
                      >
                        {this.replaceAll(this.state.etc, '<br/>', '\n')}
                      </div>
                    </div>
                  </li>
                  <li className="timeline_li">
                    <div className="timeline_content">
                      <p className="timeline_content_title">
                        Ambassador and Jury
                      </p>
                      <div
                        className="timeline_content_p"
                        style={{ whiteSpace: 'pre-wrap' }}
                      >
                        {this.replaceAll(this.state.jury, '<br/>', '\n')}
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </Col>
          </ScrollAnimation>
        </Row>
      </Container>
    );
  }
}
export default StoryPortfolio;
