import React, { Component } from 'react';
import 'style.scss';
import { Container, Row, Col } from 'react-bootstrap';
import './StoryCss.scss';
import ScrollAnimation from 'react-animate-on-scroll';
import Photozone from 'react-photo-gallery';

const photos1 = [
  {
    src: require('images/story/photozone1.jpg'),
    width: 11,
    height: 15,
  },
  {
    src: require('images/story/photozone6.jpg'),
    width: 3,
    height: 4,
  },
  {
    src: require('images/story/photozone7.jpeg'),
    width: 3,
    height: 2,
  },
  {
    src: require('images/story/photozone8.jpeg'),
    width: 3,
    height: 4,
  },
  {
    src: require('images/story/photozone9.jpeg'),
    width: 3,
    height: 4,
  },
  {
    src: require('images/story/photozone10.jpeg'),
    width: 10,
    height: 7,
  },
  {
    src: require('images/story/photozone11.jpeg'),
    width: 4,
    height: 3,
  },
  {
    src: require('images/story/photozone12.jpeg'),
    width: 4,
    height: 3,
  },
  {
    src: require('images/story/photozone13.jpeg'),
    width: 4,
    height: 3,
  },
  {
    src: require('images/story/photozone14.jpeg'),
    width: 4,
    height: 3,
  },
  {
    src: require('images/story/photozone15.jpeg'),
    width: 4,
    height: 3,
  },
  {
    src: require('images/story/photozone16.jpeg'),
    width: 4,
    height: 3,
  },
  {
    src: require('images/story/photozone17.jpeg'),
    width: 3,
    height: 4,
  },
  {
    src: require('images/story/photozone18.jpeg'),
    width: 4,
    height: 3,
  },
];

const photos2 = [
  {
    src: require('images/story/photozone19.jpeg'),
    width: 4,
    height: 3,
  },
  {
    src: require('images/story/photozone20.jpeg'),
    width: 3,
    height: 4,
  },
  {
    src: require('images/story/photozone21.jpeg'),
    width: 4,
    height: 7,
  },
  {
    src: require('images/story/photozone22.jpeg'),
    width: 4,
    height: 3,
  },
  {
    src: require('images/story/photozone23.jpeg'),
    width: 1,
    height: 1,
  },
  {
    src: require('images/story/photozone24.png'),
    width: 2,
    height: 5,
  },
  {
    src: require('images/story/photozone25.jpeg'),
    width: 4,
    height: 3,
  },
  {
    src: require('images/story/photozone26.jpeg'),
    width: 9,
    height: 7,
  },
  {
    src: require('images/story/photozone2.jpg'),
    width: 10,
    height: 4,
  },
  {
    src: require('images/story/photozone3.jpg'),
    width: 3,
    height: 4,
  },
  {
    src: require('images/story/photozone4.jpeg'),
    width: 4,
    height: 3,
  },
  {
    src: require('images/story/photozone5.jpeg'),
    width: 4,
    height: 3,
  },
];

class StoryPhotozone extends Component {
  constructor(props) {
    super(props);
    this.state = {
      contents: this.props.children,
    };
  }

  render() {
    return (
      <Container fluid>
        <Row className="story-body">
          <div className="story-title-container">
            <div className="title-line"></div>
            <h3 className="story-title">Photozone</h3>
            <h3 className="story-stitle"> &nbsp; ZiBEZI</h3>
          </div>
        </Row>

        <Row className="display-block">
          <ScrollAnimation animateOnce={true} animateIn="from-bottom-animation">
            <Col>
              <div className="story-gallery">
                <Photozone photos={photos1} />
                <Photozone photos={photos2} />
              </div>
            </Col>
          </ScrollAnimation>
        </Row>
      </Container>
    );
  }
}
export default StoryPhotozone;
