import React, { Component } from 'react';
import './NoticeCss.scss';
import { Row, Col, Container } from 'react-bootstrap';
import ScrollAnimation from 'react-animate-on-scroll';

class NoticeConsultant extends Component {
  render() {
    return (
      <Container fluid>
        <div className="header-container">
          <div className="header-title">Art Consulting</div>
        </div>

        <Row style={{ display: 'block' }} className="consult-row">
          <ScrollAnimation animateOnce={true} animateIn="from-bottom-animation">
            <Col xs={12} md={4} className=" card-Style">
              <a href="http://pf.kakao.com/_eViTT">
                <div className="image-container-consult">
                  <img
                    alt=""
                    src={require(`images/notice/consult1.jpg`)}
                    className="consult-image"
                  ></img>
                </div>
              </a>
              <div className="text-box">
                <h1 className="card-title">공간 컨설팅</h1>
                <p className="card-desc">
                  집 벽 한 켠에 나만의 작품을 걸어보는건 어떠세요? <br />단
                  하나뿐인 작품으로 빛나는 공간을 만들어드립니다.
                </p>
              </div>
            </Col>
            <Col xs={12} md={4} className="card-Style">
              <a href="http://pf.kakao.com/_eViTT">
                <div className="image-container-consult">
                  <img
                    src={require(`images/custom.jpg`)}
                    alt=""
                    className=" consult-image"
                  ></img>
                </div>
              </a>
              <div className="text-box">
                <h1 className="card-title">커스텀</h1>
                <p className="card-desc">
                  운동화, 자켓, 모자, 핸드폰 케이스 등 다양한 재료에 <br />
                  지비지 작가의 유니크한 작품을 커스텀해드립니다{' '}
                </p>
              </div>
            </Col>

            <Col xs={12} md={4} className="card-Style">
              <a href="http://pf.kakao.com/_eViTT">
                <div className="image-container-consult">
                  <img
                    src={require(`images/notice/consult2.jpg`)}
                    alt=""
                    className=" consult-image"
                  ></img>
                </div>
              </a>
              <div className="text-box">
                <h1 className="card-title">1:1 맞춤형 컨설팅</h1>
                <p className="card-desc">
                  Need에 집중하고, 경험에 바탕을 둔 맞춤 컨설팅을
                  <br />
                  진행합니다. 방문과 피드백을 거쳐 컨설팅을 제공합니다.
                </p>
              </div>
            </Col>
          </ScrollAnimation>
        </Row>
      </Container>
    );
  }
}
export default NoticeConsultant;
