import React, { Component } from 'react';

import './StoryCss.scss';
import { Container, Row, Col } from 'react-bootstrap';
import ScrollAnimation from 'react-animate-on-scroll';
import { Database, Storage } from 'components';
class StoryProfile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      contents: this.props.children,
      first_title: '',
      first_subtitle: '',
      first_body_text: '',
      first_image: '',
      second_title: '',
      second_subtitle: '',
      second_body_text: '',
      second_image: '',
    };
  }
  componentWillMount = () => {
    let storageRefs = Storage.ref('profile');
    Database.collection('Story')
      .doc('profile')
      .get()
      .then((res) => {
        let { first, second } = res.data();
        storageRefs
          .child('/' + first.image)
          .getDownloadURL()
          .then((url) => {
            this.setState({
              first_title: first.title,
              first_subtitle: first.subtitle,
              first_body_text: first.body_text,
              first_image: url,
            });
          });
        storageRefs
          .child('/' + second.image)
          .getDownloadURL()
          .then((url) => {
            this.setState({
              second_title: second.title,
              second_subtitle: second.subtitle,
              second_body_text: second.body_text,
              second_image: url,
            });
          });
      });
  };
  replaceAll = (str, searchStr, replaceStr) => {
    return str.split(searchStr).join(replaceStr);
  };
  render() {
    return (
      <Container fluid style={{ marginTop: '100vh' }}>
        <Row className="story-body">
          <div className="story-title-container">
            <div className="title-line"></div>
            <h3 className="story-title">Profile</h3>
            <h3 className="story-stitle"> &nbsp; ZiBEZI</h3>
          </div>
        </Row>
        <Row className="display-block story-body">
          <ScrollAnimation animateOnce={true} animateIn="from-bottom-animation">
            <Col className="story-col">
              <div className="blog-card">
                <div className="meta">
                  <div
                    className="photo"
                    style={{
                      backgroundImage: `url(${this.state.first_image})`,
                    }}
                  ></div>
                  <ul className="details">
                    {/* <li className="author"><a href="#">정재훈</a></li> */}
                  </ul>
                </div>
                <div className="description">
                  <h1 style={{ fontSize: 16 }}>Jae Hoon, Jung a.k.a ZiBEZI</h1>
                  <h2>INSPIRED BY ANTONIO GAUDI</h2>
                  <p
                    style={{
                      whiteSpace: 'pre-wrap',
                      fontSize: 14,
                      marginTop: 20,
                      marginBottom: 20,
                    }}
                  >
                    {' '}
                    <b>ZiBEZI</b>'s free style painting with improvised
                    inspiration and imagination drew attention from the movie
                    2019 Oscar winning, <b>'PARASITE'</b> directed by Bong Joon
                    Ho. The artist now receives a lot attention from the public
                    for being a part of the movie. Whenever his friends ask him
                    where he is, he used to answer back to them that he is home
                    in Korean <b>"jib-e-ji"</b>. The artist's name ZiBEZI is
                    driven from his answer. ZiBEZI is not limited to one
                    material, but uses a variety of materials other than oil
                    pastel, markers and acrylics to create his own works. ZiBEZI
                    mainly works in the US and in Korea.
                    {/* {this.replaceAll(this.state.first_body_text, '<br/>', '\n')} */}
                  </p>
                  <p className="read-more">
                    <div
                      onClick={() =>
                        window.open('https://www.instagram.com/zi_be_zi/')
                      }
                    >
                      <img src={require(`images/instagram.png`)} alt="" />
                    </div>
                  </p>
                </div>
              </div>
              <div className="blog-card">
                <div className="description" style={{ flexBasis: 'initial' }}>
                  <h1 style={{ fontSize: 16 }}>Letter from ZiBEZI</h1>
                  <p
                    style={{
                      whiteSpace: 'pre-wrap',
                      fontSize: 14,
                      marginTop: 20,
                      marginBottom: 20,
                      lineHeight: 1.5,
                    }}
                  >
                    When I used to be a rapper in Korea, I experienced many
                    challenging and difficult moments. During these hard times
                    and when I was not focusing on music, I decided to reach out
                    for a piece of paper and a marker that were always sitting
                    on my desk. I never thought about shifting my career, but
                    for some reason my instinct was telling me to give it a try.
                    Once I put a marker on a paper, I knew what I needed to do.
                    I started by turning my attention to the natural beauty
                    around me like the plants, trees, animals, and birds. I
                    wanted to draw anything and everything in this beautiful
                    world. I immersed myself and painted freely without a
                    thought or care in the world. It seemed like all the sadness
                    had disappeared and I was looking at life through a new
                    lens. That was the beginning of my new life as an artist and
                    at that moment I was reborn. I have since been expressing my
                    emotions and feelings on the canvas every moment possible.
                    One day, I went to Gaudi’s exhibition, and I was inspired.
                    Gaudi said, "Straight line belongs to men, the curved to
                    God." I loved it. Since then, I started drawing madly and
                    realized the curve is the realm of God. I thought, I must
                    utilize curves and included other materials and techniques
                    such as paints, oil pastels and made sure to include
                    straight and curved lines in all of my work. I was fortunate
                    to work with director, Bong Joon Ho, who gave me an
                    opportunity to be a part of his movie, Parasite. Thankfully,
                    I was able to expand my horizons. I want to share my story
                    in the hopes that one day, my art can cure someone as it
                    cured me. In the future, I am looking forward to expressing
                    a variety of new emotions in my paintings and sharing them
                    with you.
                    {/* {this.replaceAll(
                      this.state.second_body_text,
                      '<br/>',
                      '\n',
                    )} */}
                  </p>
                </div>
              </div>
            </Col>
          </ScrollAnimation>
        </Row>
      </Container>
    );
  }
}
export default StoryProfile;
