import React, { Component } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { Database, Storage } from 'components';
import { EditModal } from 'components';
import { FiEdit, FiX } from 'react-icons/fi';
import { observer, inject } from 'mobx-react';
import Modal from 'react-modal';

import 'style.scss';
import 'components/style.scss';

@inject('store')
@observer
class Pick extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
      edit: false,
      key: null,
      list: [],
      showDeleteModal: false,
      total: '',
    };
  }

  returnElement = (key, url, title, eng_title, soldout, memo, timestamp) => {
    return [
      <div key={key} style={{ marginTop: '20px' }} onClick={() => {}}>
        <div className="">
          <div
            className={soldout ? 'soldout-circle' : ''}
            style={{ float: 'left' }}
          ></div>
          <span className="grid-name-text-bold">
            {title}
            {eng_title ? ' / ' + eng_title : ''}
          </span>
          <br />
          <span>{memo ? ' ' + memo : ''}</span>
          <div className="grid-admin-box">
            <div
              className="grid-admin-icon pointer"
              onClick={() => {
                this.setState({ key, edit: true });
              }}
            >
              <FiEdit size={20} />
            </div>
            <div
              className="grid-admin-icon pointer"
              onClick={async () => {
                await this.setState({ key, showDeleteModal: true });
              }}
            >
              <FiX size={20} />
            </div>
          </div>
        </div>
      </div>,
      timestamp.seconds,
    ];
  };

  removeItem = async () => {
    await Database.collection('Artworks')
      .doc(this.state.key)
      .get()
      .then((res) => {
        Object.keys(res.data().hashtags).forEach((hashtag) => {
          Database.collection('Hashtags')
            .doc(hashtag)
            .get()
            .then((res) => {
              let count = res.data().count;
              if (count > 0) {
                Database.collection('Hashtags')
                  .doc(res.id)
                  .update({
                    count: parseInt(count) - 1,
                  })
                  .then(() => {
                    // console.log(res.id, "카운트다운");
                  })
                  .catch((err) => {
                    console.log(err);
                  });
              } else {
                Database.collection('Hashtags')
                  .doc(res.id)
                  .delete()
                  .then(() => {
                    // console.log(res.id, "삭제");
                  })
                  .catch((err) => console.log(err));
              }
            });
        });
      });
    await Database.collection('Artworks')
      .doc(this.state.key)
      .delete()
      .then(() => {
        console.log('삭제완료');
      })
      .catch((err) => console.log(err));
    await Storage.ref('artworks')
      .child('/' + this.state.key)
      .delete()
      .then(() => {
        console.log('이미지 삭제완료');
      });
    this.setState({ showDeleteModal: false });
    window.location.reload();
  };

  componentDidMount = async () => {
    let docRefs = Database.collection('Artworks')
      .orderBy('timestamp', 'desc')
      .where('pick', '==', true);
    let storageRefs = Storage.ref('artworks');

    await docRefs
      .get()
      .then((querySnapshot) => {
        let list = this.state.list.slice();
        querySnapshot.forEach((doc) => {
          console.log(doc);
          let key = doc._key.path.segments[doc._key.path.segments.length - 1];
          storageRefs
            .child('/' + key)
            .getDownloadURL()
            .then((url) => {
              list.push(
                this.returnElement(
                  key,
                  url,
                  doc.data().title,
                  doc.data().eng_title,
                  doc.data().soldout,
                  doc.data().memo,
                  doc.data().timestamp,
                ),
              );
              this.setState({ list });
            })
            .catch((err) => {
              console.log(err);
            });
        });
      })
      .catch((err) => console.log(err));
  };
  sortList = async () => {
    const sorted = this.state.list.sort((a, b) => b[1] - a[1]);
    this.setState({ list: sorted });
  };
  componentDidUpdate = () => {};

  render() {
    return (
      <Container className="">
        <Row className="admin-row">
          <EditModal
            show={this.state.edit}
            elementkey={this.state.key}
            category={false}
            callback={() => {
              this.setState({ edit: false });
            }}
          />

          <Col
            xs={12}
            className="work-total"
            onClick={() => {
              this.sortList();
            }}
          >
            <span>ZiBEZI's Pick</span>
          </Col>
          <Col
            xs={12}
            stlye={{ marginBottom: '30px' }}
            className="admin-category-header"
          >
            {this.state.list.map((key) => (
              <span>{key[0]}</span>
            ))}
          </Col>
          <Modal
            ariaHideApp={false}
            isOpen={this.state.showDeleteModal}
            onRequestClose={() => {
              this.setState({ showDeleteModal: false });
            }}
            className="alert-modal"
            overlayClassName="modal-overlay"
          >
            <Container fluid style={{ padding: 0 }}>
              <Row className="modal-container">
                <Col xs={12} className="alert">
                  <span>삭제하시겠습니까?</span>
                </Col>
                <Col
                  sm={12}
                  className="alert-btn pointer"
                  onClick={() => {
                    this.removeItem();
                  }}
                >
                  <span>삭제</span>
                </Col>
                <Col
                  sm={12}
                  className="alert-btn pointer"
                  onClick={() => {
                    this.setState({ showDeleteModal: false });
                  }}
                >
                  <span>취소</span>
                </Col>
              </Row>
            </Container>
          </Modal>
        </Row>
      </Container>
    );
  }
}
export default Pick;
