import React, { Component } from 'react';
import { Row, Col } from 'react-bootstrap';
import 'style.scss';
import 'components/style.scss';

class ShippingInfo extends Component {
  render() {
    return (
      <Row className="shipping-info-container">
        <Col xs={12} className="shipping-info-header">
          배송정보(Shipping information)
        </Col>
        <Col md={2} className="shipping-info-category">
          상품
        </Col>
        <Col md={10} className="shipping-info-detail">
          <p>
            - 재는 방법이나 위치에 따라 사이즈의 1~3cm 의 오차가 있을 수 있으니
            상세사이즈를 꼭 확인해주세요.
            <br />
            - 아트 워크 및 굿즈의 색상은 촬영시 조명에 따라 다르게 보일 수
            있으니 가장 가까운 상세컷을 확인해주세요.
            <br />- 모니터의 해상도에 따라 약간씩 다르게 보일 수 있으니
            참고해주세요.
          </p>
        </Col>

        <Col md={2} className="shipping-info-category">
          배송
        </Col>
        <Col md={10} className="shipping-info-detail">
          <p>
            - 배송기간은 주문후 s평균 입금후 굿즈– 약 5~10일정도, 슈즈잡화
            5~10일정도, 맞춤제작 약2주(일요일/국가공휴일 제외) 소요됩니다.
            <br />
            - 제작이 지연되거나 수입상품의 경우 다소 더 늦어질 수 있습니다.
            구매전 문의사항은 게시판 또는 상담전화를 통하여 구매해주세요.
            <br />
            - 주문하신 상품의 개수가 많거나 배송지연상품이 끼어있는 경우 4일
            이후엔 준비된 상품 먼저 발송해드립니다. (부분배송을 원치 않으실 경우
            미리 말씀해주셔야 합니다.)
            <br />- 주문메세지란에 주문변경 관련사항은 반영되지 않으니
            변경사항이 있으시면 꼭 따로 게시판에 글을 남겨주세요.
          </p>
        </Col>

        <Col md={2} className="shipping-info-category">
          주문변경
        </Col>
        <Col md={10} className="shipping-info-detail">
          <p>
            - 발송되기 전 상태일시 오후3시까지 변경가능하며 이후는 변경이
            불가능합니다. 이후 취소시엔 상품 수령시 반송처리가 되며 택배비는
            고객님 부담입니다.
            <br />- 변경시엔 발송이 보류되며 다음날 발송되거나 주문서를 새로
            작성해주세요.
          </p>
        </Col>

        <Col md={2} className="shipping-info-category">
          입금
        </Col>
        <Col md={10} className="shipping-info-detail">
          <p>
            - 주문자 성함과 입금자 성함이 다를 경우, 자동으로 확인되지 않으니
            다를 경우 꼭 알려주셔야 입금확인이 가능합니다.
          </p>
        </Col>

        <Col md={2} className="shipping-info-category">
          교환 빛 반품{' '}
        </Col>
        <Col md={10} className="shipping-info-detail">
          <p>
            - 교환 및 반품은 사진을 찍어 카카오톡을 통한 상담 후 굿즈의 하자가
            있는 경우에 한해서만 가능하고, 아트워크의 교환 및 반품은 불가합니다.
            <br />
            <br />
            ※교환/반품이 불가능한 경우
            <br />
            - 전자상거래법에 의하여 교환/반품 요청은 수령일로부터 7일이내입니다.
            <br />
            - 상품 수령일로부터 7일이 지났을 경우
            <br />
            - 사용 및 소비에 의해 상품의 가치가 훼손된 경우
            (섬유유연제/향수/바디로션/담배냄새/주름/수선/스크래치 등)
            <br />- 판매/생산방식의 특성상 교환/반품시 판매자에게 회복할 수 없는
            손해가 발생하는 경우 (개별생산/맞춤제작 등)
          </p>
        </Col>

        <Col md={2} className="shipping-info-category">
          불량
        </Col>
        <Col md={10} className="shipping-info-detail">
          <p>
            - 아트워크를 제외한 굿즈의 경우 제작과정상 미비한 오염/ 니트소재의
            약간의 울트임이나 짜임의 불규칙, 매듭이 보이는 현상/미세한 박음질
            마감처리
            <br />
            - 컬러가 있는 의류의 경우 염색이나 워싱공정상 미비한 색감차이 /
            공정상 약품처리처리 과정에 의한 냄새(가죽,섬유냄새등)는 불량 사유가
            되지 않으며 이를 이유로 반품시엔 단순변심으로 구분되어 택배비가
            부과됩니다.
            <br />
            - 세탁부주의에 의한 변의 변형/착용한 흔적이 있을경우
            <br />
            - 각종냄새-향수, 섬유탈취제 냄새등이 날 경우 착용으로 간주하여
            반품,교환이 어렵습니다.
            <br />- 배송요청 메시지란에 남겨주신 메모는 반영되지 않을 경우도
            있으니 상품에 대핸 변경/요청사항이 있으시면 꼭 따로 게시판에
            남겨주세요.
          </p>
        </Col>
      </Row>
    );
  }
}
export default ShippingInfo;
