import React, { Component } from 'react';
import { Container, Row, Col, Image } from 'react-bootstrap';
import { Database, Storage } from 'components';
import { FaArrowsAlt, FaPlus, FaMinus, FaTimes } from 'react-icons/fa';
import * as htmlToImage from 'html-to-image';
import Draggable from 'react-draggable';
import Modal from 'react-modal';
import StackGrid from 'react-stack-grid';

import 'style.scss';
import 'components/style.scss';
class AdminHome extends Component {
  constructor(props) {
    super(props);
    this.state = {
      main_slider_elements: [],
      activeDrags: 0,
      endOfCustom: false,
      category: false,
      color: false,
      artworks_list_modal: false,
      artworks_selected: null,
      artworks_list: [],
      curIndex: 0,
      maxIndex: 0,
      custom_list: [],
      inputFocused: false,
      num_drag: 0,
      test: 0,
      text: '',
    };
  }

  componentWillMount = () => {
    Database.collection('System')
      .doc('slider_info')
      .get()
      .then((doc) => {
        if (doc.exists) {
          let arr = doc.data().slider_arr;
          arr.forEach((element) => {});
        } else {
        }
      })
      .catch((err) => {
        console.log('Database_read_error');
      });
  };
  componentDidMount = () => {
    let database = Database.collection('Artworks');
    let storage = Storage.ref('artworks');
    database
      .where('uploaded', '==', true)
      .get()
      .then((res) => {
        res.forEach((doc) => {
          const key = doc._key.path.segments[doc._key.path.segments.length - 1];
          const data = doc.data();
          storage
            .child('/' + key)
            .getDownloadURL()
            .then((url) => {
              this.setState({
                artworks_list: [
                  ...this.state.artworks_list,
                  { title: data.title, eng_title: data.eng_title, key, url },
                ],
              });
            });
        });
        this.setState({
          maxIndex: res.size,
          curIndex: res.size >= 11 ? 11 : res.size,
        });
      });
  };
  saveme = () => {
    this.setState({ endOfCustom: true });

    htmlToImage
      .toJpeg(document.getElementById('custom-result'), { quality: 0.95 })
      .then(function (dataUrl) {
        var link = document.createElement('a');
        link.download = 'my-image-name.jpeg';
        link.href = dataUrl;
        link.click();
      });

    setTimeout(() => {
      this.setState({ endOfCustom: false });
    }, 300);
  };
  onMore = () => {
    let { curIndex, maxIndex } = this.state;
    console.log(curIndex, maxIndex);
    curIndex = curIndex + 12 <= maxIndex ? curIndex + 12 : maxIndex;
    this.setState({ curIndex });
  };
  onSelectCategory = (category) => {
    this.setState({ category });
  };
  onSelectColor = (color) => {
    this.setState({ color });
  };
  resize = (d, index) => {
    let custom_list = this.state.custom_list;
    if (d) {
      custom_list[index].ratio = custom_list[index].ratio + 0.1;
    } else {
      custom_list[index].ratio = custom_list[index].ratio - 0.1;
    }
    this.setState({ custom_list });
  };
  onArtworkDelete = (key) => {
    let custom_list = this.state.custom_list;
    custom_list[key].deleted = true;
    this.setState({ custom_list });
  };
  returnHighlightedBtn = (flag) => {
    return flag ? { border: 'solid 1px #333', color: '#333' } : {};
  };
  renderArtworksModal = () => {
    return (
      <Modal
        ariaHideApp={false}
        isOpen={this.state.artworks_list_modal}
        onRequestClose={() => this.setState({ artworks_list_modal: false })}
        className="custom-artwork-modal"
        overlayClassName="modal-overlay"
      >
        <div className="modal-container">
          <div className="modal-header">아트워크 선택</div>
          <div className="modal-content">
            <div>
              <StackGrid
                className="my-3"
                columnWidth={'25%'}
                gridRef={(grid) => (this.grid = grid)}
                gutterWidth={30}
                gutterHeight={30}
              >
                {this.state.artworks_list
                  .slice(0, this.state.curIndex + 1)
                  .map((element) => {
                    return (
                      <div
                        key={element.key}
                        className="modal-grid"
                        style={
                          this.state.artworks_selected === element.key
                            ? { backgroundColor: '#333' }
                            : {}
                        }
                        onClick={() => {
                          this.setState({ artworks_selected: element.key });
                        }}
                      >
                        <Image
                          src={element.url}
                          className="grid-image"
                          style={
                            this.state.artworks_selected === element.key
                              ? { backgroundColor: '#333' }
                              : {}
                          }
                        />
                        <div
                          className="grid-text"
                          style={
                            this.state.artworks_selected === element.key
                              ? { color: 'white', fontWeight: 500 }
                              : {}
                          }
                        >
                          {element.title}/{element.eng_title}
                        </div>
                      </div>
                    );
                  })}
              </StackGrid>
            </div>
            <div className="refresh-btn">
              <span className="refresh-text" onClick={() => this.onMore()}>
                더 불러오기
              </span>
            </div>
          </div>
          <div
            className="modal-confirm"
            onClick={() => {
              this.state.artworks_list.forEach((element) => {
                if (element.key === this.state.artworks_selected) {
                  this.setState({
                    custom_list: [
                      ...this.state.custom_list,
                      {
                        type: true,
                        key: element.key,
                        url: element.url,
                        index: this.state.num_drag,
                        deleted: false,
                        onHover: false,
                        ratio: 1,
                      },
                    ],
                    num_drag: ++this.state.num_drag,
                    artworks_selected: null,
                    artworks_list_modal: false,
                  });
                  return;
                }
              });
            }}
          >
            추가하기
          </div>
        </div>
      </Modal>
    );
  };
  render() {
    return (
      <Container className="admin">
        {this.renderArtworksModal()}
        <Row className="admin-row custom">
          <Col xs={12} className="admin-category-header">
            <span>커스텀</span>
          </Col>
          <Col xs={12} className="custom-step">
            <div className="custom-step-header">1. 베이스 선택</div>
            <div className="custom-step-container">
              <div
                className="custom-category-btn pointer"
                style={this.returnHighlightedBtn(this.state.category === 0)}
                onClick={() => this.onSelectCategory(0)}
              >
                티셔츠
              </div>
              <div
                className="custom-category-btn pointer"
                style={this.returnHighlightedBtn(this.state.category === 1)}
                onClick={() => this.onSelectCategory(1)}
              >
                에코백
              </div>
            </div>
          </Col>
          <Col xs={12} className="custom-step">
            <div className="custom-step-header">2. 색상 선택</div>
            <div className="custom-step-container">
              <div
                className="custom-category-btn pointer"
                style={this.returnHighlightedBtn(this.state.color === 'BLACK')}
                onClick={() => this.onSelectColor('BLACK')}
              >
                블랙
              </div>
              <div
                className="custom-category-btn pointer"
                style={this.returnHighlightedBtn(this.state.color === 'WHITE')}
                onClick={() => this.onSelectColor('WHITE')}
              >
                화이트
              </div>
              <div
                className="custom-category-btn pointer"
                style={this.returnHighlightedBtn(this.state.color === 'GRAY')}
                onClick={() => this.onSelectColor('GRAY')}
              >
                그레이
              </div>
              <div
                className="custom-category-btn pointer"
                style={this.returnHighlightedBtn(this.state.color === 'PINK')}
                onClick={() => this.onSelectColor('PINK')}
              >
                핑크
              </div>
            </div>
          </Col>
          <Col xs={12} className="custom-step">
            <div className="custom-step-header">
              <span>3. 커스텀</span>
              <span className="header-sub">
                아트워크, 텍스트 추가시 비용이 추가적으로 발생합니다.
              </span>
            </div>
            <div className="option-container">
              <div
                className="option-artwork"
                onClick={() =>
                  this.setState({
                    artworks_list_modal: true,
                    artworks_selected: null,
                  })
                }
              >
                아트워크 추가
              </div>
              <div
                className="option-text"
                style={this.state.inputFocused ? { width: 'auto' } : {}}
                onClick={() => this.textInput.focus()}
              >
                텍스트 추가
                <input
                  type="text"
                  ref={(ref) => (this.textInput = ref)}
                  onChange={(e) => {
                    this.setState({ text: e.target.value });
                  }}
                  value={this.state.text}
                  className="option-text-input"
                  onFocus={() => {
                    this.setState({ inputFocused: true });
                  }}
                  onBlur={() => {
                    setTimeout(
                      () => this.setState({ inputFocused: false }),
                      300,
                    );
                  }}
                  style={
                    this.state.inputFocused
                      ? {
                          width: '200px',
                          marginLeft: '10px',
                          marginRight: '10px',
                        }
                      : { width: '0' }
                  }
                />
                <div
                  className="option-text-confirm"
                  style={
                    this.state.inputFocused
                      ? { display: 'block' }
                      : { display: 'none' }
                  }
                  onClick={() => {
                    this.setState({
                      inputFocused: false,
                      custom_list: [
                        ...this.state.custom_list,
                        {
                          type: false,
                          index: this.state.num_drag,
                          text: this.state.text,
                          onHover: false,
                        },
                      ],
                      text: '',
                      num_drag: ++this.state.num_drag,
                    });
                  }}
                >
                  추가
                </div>
              </div>
            </div>
            <div className="custom-custom-container">
              <div className="custom-paper" id="custom-result">
                <Image
                  src={require('images/tshirts_front.jpg')}
                  className="custom-background"
                />

                {this.state.custom_list.map((element, key) => {
                  return element.type === true ? (
                    <Draggable key={key} handle={'#drag' + element.index}>
                      <div
                        className="custom-image-wrapper"
                        onMouseOver={() => {
                          let custom_list = this.state.custom_list;
                          custom_list[element.index].onHover = true;
                          this.setState({ custom_list });
                        }}
                        onMouseOut={() => {
                          let custom_list = this.state.custom_list;
                          custom_list[element.index].onHover = false;
                          this.setState({ custom_list });
                        }}
                        style={element.deleted ? { display: 'none' } : {}}
                      >
                        <Image
                          src={element.url}
                          className="custom-center"
                          style={{
                            width: element.ratio * 100 + 'px',
                            height: element.ratio * 100 + 'px',
                          }}
                        />
                        <div
                          className="controller-container"
                          style={!element.onHover ? { display: 'none' } : {}}
                          //style={this.state.endOfCustom?{display:'none', width:'100%'}:{width:'100%'}}
                        >
                          <FaMinus
                            className="pointer"
                            size={'13px'}
                            onClick={() => this.resize(false, element.index)}
                          />
                          <FaPlus
                            className="pointer"
                            size={'13px'}
                            onClick={() => this.resize(true, element.index)}
                          />
                          <FaArrowsAlt
                            size={'13px'}
                            id={'drag' + element.index}
                            className="pointer"
                          />
                          <FaTimes
                            size={'13px'}
                            className="pointer"
                            onClick={() => {
                              this.onArtworkDelete(element.index);
                            }}
                          />
                        </div>
                      </div>
                    </Draggable>
                  ) : (
                    <Draggable key={key} handle={'#drag' + element.index}>
                      <div
                        className="custom-text-wrapper"
                        style={element.deleted ? { display: 'none' } : {}}
                        onMouseOver={() => {
                          let custom_list = this.state.custom_list;
                          custom_list[element.index].onHover = true;
                          this.setState({ custom_list });
                        }}
                        onMouseOut={() => {
                          let custom_list = this.state.custom_list;
                          custom_list[element.index].onHover = false;
                          this.setState({ custom_list });
                        }}
                      >
                        <span>{element.text}</span>
                        <div
                          className="controller-container"
                          style={!element.onHover ? { display: 'none' } : {}}
                          //style={this.state.endOfCustom?{display:'none', width:'100%'}:{width:'100%'}}
                        >
                          <FaArrowsAlt
                            size={'13px'}
                            id={'drag' + element.index}
                            className="pointer"
                          />
                          <FaTimes
                            size={'13px'}
                            className="pointer"
                            onClick={() => {
                              this.onArtworkDelete(element.index);
                            }}
                          />
                        </div>
                      </div>
                    </Draggable>
                  );
                })}
              </div>
              <div className="next-btn pointer" onClick={() => this.saveme()}>
                장바구니에 추가
              </div>
            </div>
          </Col>
          <Col xs={12} className="custom-step">
            <div className="custom-step-header">4. 장바구니 추가</div>
            <div className="custom-step-container"></div>
          </Col>
        </Row>
      </Container>
    );
  }
}
export default AdminHome;
