import React, { Component } from 'react';
import { Route, Switch, NavLink, Redirect } from 'react-router-dom';
import { Container, Row, Col } from 'react-bootstrap';
import Mypage from './Mypage';
import Cart from './Cart';

import 'components/style.scss';
import 'style.scss';

import { observer, inject } from 'mobx-react';
@inject('store')
@observer
class Info extends Component {
  render() {
    return (
      <Container style={{ padding: 0 }}>
        {this.props.store.user.isLogined ? (
          <Container>
            <Row className="shop-margin-top" />
            <Row className="p-5">
              <Col md={2} xs={4}>
                <NavLink
                  exact
                  to="/mypage"
                  className="shop-btn"
                  activeClassName="shop-btn-highlight"
                >
                  <span>Mypage</span>
                </NavLink>
              </Col>
              <Col md={2} xs={4}>
                <NavLink
                  to="/mypage/cart"
                  className="shop-btn"
                  activeClassName="shop-btn-highlight"
                >
                  <span>Cart</span>
                </NavLink>
              </Col>
            </Row>
            <Switch>
              <Route path="/mypage/cart" component={Cart} />
              <Route exact path="/mypage" component={Mypage} />
            </Switch>
          </Container>
        ) : (
          <Redirect to="/" />
        )}
      </Container>
    );
  }
}
export default Info;
