import React, { Component } from 'react';
import { NoticeBoard, NoticeClass, NoticeConsultant } from 'components';

class Notice extends Component {
  render() {
    return (
      <div>
        <NoticeBoard></NoticeBoard>
        <NoticeClass></NoticeClass>
        <NoticeConsultant></NoticeConsultant>
      </div>
    );
  }
}
export default Notice;
