import React, { Component } from 'react';
import { Container } from 'react-bootstrap';
import { Database } from 'components';

import 'style.scss';
import 'components/style.scss';
class AdminHome extends Component {
  constructor(props) {
    super(props);
    this.state = {
      main_slider_elements: [],
    };
  }
  componentWillMount = () => {
    Database.collection('System')
      .doc('slider_info')
      .get()
      .then((doc) => {
        if (doc.exists) {
          let arr = doc.data().slider_arr;
          arr.forEach((element) => {});
        } else {
        }
      })
      .catch((err) => {
        console.log('Database_read_error');
      });
  };
  render() {
    return (
      <Container className="admin from-bottom-animation">
        {/* <Row className="admin-row">
            <Col xs={12} className="admin-category-header">
              <span>상단 슬라이더</span>
              <TiFolderOpen size={"20px"} className="pointer" style={{ color: "#333", marginLeft:"10px" }} />
            </Col>
            <Col xs={12} className="admin-slider-container">
              <div className="admin-slider-scroll">
                <div> 
                </div>
              </div>
            </Col>
          </Row> */}
      </Container>
    );
  }
}
export default AdminHome;
