import React, { Component } from 'react';
import { Container, Row, Accordion, Image } from 'react-bootstrap';
import {
  FiUser,
  FiInstagram,
  FiMessageCircle,
  FiMail,
  FiInfo,
  FiPhone,
  FiUsers,
} from 'react-icons/fi';
import { MdCreditCard } from 'react-icons/md';
import 'style.scss';
import '../style.scss';
import FooterPopup from './FooterPopup';
import EmailModal from '../Email/EmailModal';
class Footer extends Component {
  state = {
    width: window.innerWidth,
    messagePopup: false,
    emailPopup: false,
  };
  componentDidMount = () => {
    window.addEventListener('resize', this.resize.bind(this));
  };
  resize() {
    this.setState({ width: window.innerWidth });
  }
  openPopup = () => {
    this.setState({ messagePopup: true });
  };
  onEmailClick = () => {
    this.setState({ emailPopup: true });
  };
  onEmailClose = () => {
    this.setState({ emailPopup: false });
  };
  onEmailSubmit = () => {
    setTimeout(() => {
      alert('Your email has been sent.');
      this.setState({ emailPopup: false });
    }, 5000);
  };
  render() {
    return this.state.width > 767 ? (
      <div className="footer-div">
        <Container className="footer-container">
          <Row className="footer">
            <div
              className="footer-element"
              style={{ alignItems: 'flex-start' }}
            >
              <Image
                src={require('images/zibezi.png')}
                className="footer-image"
              />
              <div className="left-align-div">
                <span>
                  서울시 서초구 강남대로321 1310호 지비지 <br />
                  1100 s hope st , Los Angeles, CA, 90015
                </span>
                <span>사업자 등록번호 : 494-43-00541</span>
                <span>대표 : 안민지</span>
              </div>
            </div>
            <div className="footer-element">
              <span className="highlighted">
                <MdCreditCard size={20} className="footer-icon" />
                Account
              </span>
              <span className="indented">국민은행 안민지</span>
              <span className="indented">453801-01-147093</span>
            </div>
            <div className="footer-element">
              <span className="highlighted">
                <FiInfo size={20} className="footer-icon" />
                Info
              </span>
              <span className="indented">개인정보보호정책</span>
              <span className="indented">배송 | 교환</span>
            </div>
            <div className="footer-element">
              <span className="highlighted">
                <FiPhone size={20} className="footer-icon" />
                Contact us
              </span>
              <span className="indented">010-8980-7921(KR)</span>
              <span className="indented">+1-310-920-3027(US)</span>
              <span className="indented">24H/7D</span>
            </div>
            <div className="footer-element">
              <span className="highlighted">
                <FiUsers size={20} className="footer-icon" />
                SNS
              </span>
              <div className="footer-iconbox indented">
                <FiInstagram
                  size={30}
                  onClick={() =>
                    window.open('https://www.instagram.com/zi_be_zi/')
                  }
                  className="pointer footer-icon"
                />
                <FiMessageCircle
                  size={30}
                  onClick={() => window.open('http://pf.kakao.com/_eViTT')}
                  className="pointer footer-icon"
                />
                <FiMail size={30} className="footer-icon" />
              </div>
            </div>
          </Row>
          <div className="rightIcon" style={{ opacity: '1' }}>
            <div className="rightContainer">
              <div className="rightEmail" onClick={this.onEmailClick}>
                <img src={require(`images/emailIcon.png`)} alt="" />
              </div>
              <div onClick={this.openPopup}>
                <img src={require(`images/message.png`)} alt="" />
              </div>
              <a
                href="http://pf.kakao.com/_eViTT"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={require(`images/kakao2.png`)} alt="" />
              </a>
              <a
                href="https://www.instagram.com/zi_be_zi/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={require(`images/instagram.png`)} alt="" />
              </a>
            </div>
          </div>
          <FooterPopup popup={this.state.messagePopup} />
          <EmailModal
            show={this.state.emailPopup}
            onSubmit={this.onEmailSubmit}
            callback={this.onEmailClose}
          />
        </Container>
      </div>
    ) : (
      <div>
        <Accordion defaultActiveKey="" style={{ borderRadius: 0 }}>
          <div className="card">
            <Accordion.Toggle eventKey="0" className="card-header">
              <span className="highlighted">
                <FiUser size={15} className="footer-icon" />
                Company
              </span>
            </Accordion.Toggle>
            <Accordion.Collapse eventKey="0">
              <div className="card-body">
                <span className="indented">
                  서울시 서초구 강남대로321 1310호 지비지
                  <br />
                  1100 s hope st , Los Angeles, CA, 90015
                </span>
                <span className="indented">사업자 등록번호 : 494-43-00541</span>
                <span className="indented">대표 안민지</span>
              </div>
            </Accordion.Collapse>
          </div>
          <div className="card">
            <Accordion.Toggle eventKey="1" className="card-header">
              <span className="highlighted">
                <MdCreditCard size={15} className="footer-icon" />
                Account
              </span>
            </Accordion.Toggle>
            <Accordion.Collapse eventKey="1">
              <div className="card-body">
                <span className="indented">국민은행 안민지</span>
                <span className="indented">453801-01-147093</span>
              </div>
            </Accordion.Collapse>
          </div>
          <div className="card">
            <Accordion.Toggle eventKey="2" className="card-header">
              <span className="highlighted">
                <FiInfo size={15} className="footer-icon" />
                Info
              </span>
            </Accordion.Toggle>
            <Accordion.Collapse eventKey="2">
              <div className="card-body">
                <span className="indented">개인정보보호정책</span>
                <span className="indented">배송 | 교환</span>
              </div>
            </Accordion.Collapse>
          </div>
          <div className="card">
            <Accordion.Toggle eventKey="3" className="card-header">
              <span className="highlighted">
                <FiPhone size={15} className="footer-icon" />
                Contact us
              </span>
            </Accordion.Toggle>
            <Accordion.Collapse eventKey="3">
              <div className="card-body">
                <span className="indented">010-8980-7921(KR)</span>
                <span className="indented">+1-310-920-3027(US)</span>
                <span className="indented">24H/7D</span>
              </div>
            </Accordion.Collapse>
          </div>
          <div className="card">
            <Accordion.Toggle eventKey="4" className="card-header">
              <span className="highlighted">
                <FiUsers size={15} className="footer-icon" />
                SNS
              </span>
            </Accordion.Toggle>
            <Accordion.Collapse eventKey="4">
              <div className="card-body">
                <div
                  className="pointer"
                  onClick={() =>
                    window.open('https://www.instagram.com/zi_be_zi/')
                  }
                  style={{ width: '100px' }}
                >
                  <span>
                    <FiInstagram size={15} className="footer-icon" />
                    인스타그램
                  </span>
                </div>
                <div
                  className="pointer"
                  onClick={() => window.open('http://pf.kakao.com/_eViTT')}
                  style={{ width: '100px' }}
                >
                  <span>
                    <FiMessageCircle size={15} className="footer-icon" />
                    카카오톡
                  </span>
                </div>
                <div style={{ width: '100px' }}>
                  <span>
                    <FiMail size={15} className="footer-icon" />
                    메일
                  </span>
                </div>
              </div>
            </Accordion.Collapse>
          </div>
        </Accordion>
        <div className="rightIcon" style={{ opacity: '1' }}>
          <div className="rightContainer">
            <div className="rightEmail" onClick={this.onEmailClick}>
              <img src={require(`images/emailIcon.png`)} alt="" />
            </div>
            <div onClick={this.openPopup}>
              <img src={require(`images/message.png`)} alt="" />
            </div>
            <a
              href="http://pf.kakao.com/_eViTT"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={require(`images/kakao2.png`)} alt="" />
            </a>
            <a
              href="https://www.instagram.com/zi_be_zi/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={require(`images/instagram.png`)} alt="" />
            </a>
          </div>
        </div>
        <FooterPopup popup={this.state.messagePopup} />
        <EmailModal
          show={this.state.emailPopup}
          onSubmit={this.onEmailSubmit}
          callback={this.onEmailClose}
        />
      </div>
    );
  }
}
export default Footer;
