import React, { Component } from 'react';
import { Route, Switch } from 'react-router-dom';
import { Container, Row } from 'react-bootstrap';
import Artworks from './Artworks';
import ArtworksDetail from './ArtworksDetail';
import Pagination from './Pagination/Pagination';
import ZibeziPick from './ZibeziPick';
import 'components/style.scss';
import 'style.scss';
class Shop extends Component {
  render() {
    return (
      <Container fluid>
        <Row className="shop-margin-top" />
        <Switch>
          <Route exact path="/shop" component={Artworks} />
          <Route exact path="/shop/artworks/:aid" component={ArtworksDetail} />
          {/* <Route exact path="/shop/goods" component={Goods} />
          <Route exact path="/shop/goods/:gid" component={GoodsDetail} /> */}
          <Route exact path="/shop/pagination" component={Pagination} />
          <Route exact path="/shop/pick" component={ZibeziPick} />
        </Switch>
      </Container>
    );
  }
}
export default Shop;
