import React, { useState } from 'react';
import styled from 'styled-components';
import zombie from '../../images/zombie.png';

const PopupContainer = styled.div`
  box-sizing: border-box;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 250px;
  z-index: 9999;
  object-fit: cover;
  outline: 0;
  background-color: #fff;
`;
const PopupImage = styled.img`
  width: 100%;
  min-height: 234px;
  max-height: 360px;
  display: block;
  margin: auto;
`;
const PopupButtonContainerWrapper = styled.div`
  display: flex;
  flex-direction: row;
  border-top: 1px solid #ebebeb;
`;
const PopupButtonContainer = styled.a`
  flex: 1;
`;
const PopupCloseButtonContainer = styled.div`
  flex: 1;
`;
const PopupButton = styled.button`
width: 100%;
justify-content: center;
  margin-top: 10px
  align-items: center;
  appearance: button;
  background-color: #fff;
  border-style: none;
  box-shadow: rgba(255, 255, 255, 0.26) 0 1px 2px inset;
  box-sizing: border-box;
  color: #000;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  flex-shrink: 0;
  font-family: 'RM Neue', sans-serif;
  font-size: 16px;
  margin: 0 auto;
  padding: 21px
  text-align: center;
  text-transform: none;
  transition: color 0.13s ease-in-out, background 0.13s ease-in-out,
    opacity 0.13s ease-in-out, box-shadow 0.13s ease-in-out;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;

  &:hover {
    background-color:none;
  }
  &:active {
    background-color: #006ae8;
  }
`;

const PopupDescription = styled.div`
  padding: 21px;
  font-size: 20px;
  font-weight: bold;
  border-top: 1px solid
`;

export default function HomePopup2(props) {
  const [popup, setPopup] = useState(true);
  const closePopup = () => setPopup(false);

  return (
    <>
      {popup ? (
        <PopupContainer>
          <PopupImage src={zombie} alt="popup image" />
          <PopupDescription>
            ZIBEZI x KOREAN ZOMBIE Collections
          </PopupDescription>
          <PopupButtonContainerWrapper>
            <PopupButtonContainer
              href="https://zombienation.us/collections/zibezi-x-korean-zombie"
              target="_blank"
              rel="noreferrer"
            >
              <PopupButton
                type="button"
                style={{ backgroundColor: '#000', color: '#fff' }}
              >
                Go Shop
              </PopupButton>
            </PopupButtonContainer>
            <PopupCloseButtonContainer>
              <PopupButton type="button" onClick={closePopup}>
                Close
              </PopupButton>
            </PopupCloseButtonContainer>
          </PopupButtonContainerWrapper>
        </PopupContainer>
      ) : (
        ''
      )}
    </>
  );
}
