import React, { useEffect, useState } from 'react';
import { Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import '.././ShopCss.scss';
import { Firebase as firebase } from 'components';
import { FiEdit, FiX } from 'react-icons/fi';

export default function WorkCard(props) {
  //const [key,setKey] = useState(null);
  const [edit, setEdit] = useState(false);
  const [work, setWork] = useState([]);
  const [deleteModal, setDeleteModal] = useState(false);
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    setWork(props.work);
  }, [props]);

  return (
    <Col xs={6} sm={6} md={4} className="blue-hover card-style" key={work.key}>
      <Link to={'/shop/artworks/' + work.key} key={work.key}>
        <div className="image-container">
          <img
            style={loaded ? {} : { display: 'none' }}
            className="card-image"
            alt="work"
            //src={work.imageUrl ? work.imageUrl : require("images/1.1.png")}
            src={work.imageUrl}
            ng-src
            onLoad={() => setLoaded(true)}
            onError={(e) => {
              e.target.onerror = null;
              work.imageUrl = work.imageUrl;
            }}
          />
        </div>
      </Link>
      <div className="title-box">
        <p className="card-title">
          {work.title}
          <span className={work.soldout ? 'soldout-circle' : ''}></span>
        </p>
        <p className="card-subtitle">{work.eng_title}</p>
      </div>
      {firebase.auth().currentUser &&
        firebase.auth().currentUser.email == 'admin@zibezi.com' && (
          <div className="grid-admin-box">
            <div
              className="grid-admin-icon pointer"
              onClick={() => {
                setEdit(!edit);
                props.selectedKey(work.key);
                props.toggleEdit(edit);
              }}
            >
              <FiEdit size={20} />
            </div>
            <div
              className="grid-admin-icon pointer"
              onClick={async () => {
                setDeleteModal(!deleteModal);
                props.selectedKey(work.key);
                props.toggleDelete(deleteModal);
              }}
            >
              <FiX size={20} />
            </div>
          </div>
        )}
    </Col>
  );
}
