import { observable, action, computed } from 'mobx';
import { Database, Storage } from 'components';

class Data {
  @observable worksNum;
  @observable loadsNum;
  constructor() {
    this.worksNum = 0;
    this.loadsNum = 0;
    this.loadsRate = 0;
    this.getWorksNum();
  }
  @action
  setLoadsNum = (num) => {
    this.loadsNum = num;
    this.loadsRate = Math.floor((this.loadsNum / this.worksNum) * 100);
  };

  @action
  getWorksNum = (user) => {
    let docRefs = Database.collection('Artworks')
      .get()
      .then((snap) => {
        this.worksNum = snap.size;
      });
  };
}

export default Data;
