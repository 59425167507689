import React, { Component } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { Firebase as firebase, Database } from 'components';
import Modal from 'react-modal';
import 'components/style.scss';
import 'style.scss';
import DaumPostcode from 'react-daum-postcode';

import { observer, inject } from 'mobx-react';

@inject('store')
@observer
class Info extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
      list: [],
      name: '',
      email: '',
      zipcode: '',
      address: '',
      address_detail: '',
      phone: '',
      addsearch: false,
    };
  }

  componentDidMount = () => {
    Database.collection('Users')
      .doc(this.props.store.user.uid)
      .get()
      .then((query) => {
        let data = query.data();
        this.setState({
          name: data.name,
          email: data.email,
          zipcode: data.zip,
          address: data.address,
          address_detail: data.address_detail,
          phone: data.phone_number,
        });
      });
  };

  handleAddress = async (data) => {
    let fullAddress = data.address;
    let extraAddress = '';

    if (data.addressType === 'R') {
      if (data.bname !== '') {
        extraAddress += data.bname;
      }
      if (data.buildingName !== '') {
        extraAddress +=
          extraAddress !== '' ? `, ${data.buildingName}` : data.buildingName;
      }
      fullAddress += extraAddress !== '' ? ` (${extraAddress})` : '';
    }
    await this.setState({
      zipcode: data.zonecode,
      address: fullAddress,
      addsearch: false,
    });

    Database.collection('Users').doc(this.state.email).update({
      zip: this.state.zipcode,
      address: this.state.address,
    });
  };

  handleChange = (event) => {
    this.setState({
      address_detail: event.target.value,
    });
  };

  onChangePassword = () => {
    let auth = firebase.auth();
    let emailAddress = this.state.email;
    if (emailAddress != null) {
      auth
        .sendPasswordResetEmail(emailAddress)
        .then(function () {
          // Email sent.
        })
        .catch(function (error) {
          // An error happened.
        });
    }
    alert('메일로 비밀번호 변경링크가 발송되었습니다.');
  };

  onChangeDetail = () => {
    Database.collection('Users').doc(this.state.email).update({
      address_detail: this.state.address_detail,
    });
  };

  render() {
    return (
      <Container>
        <Modal
          isOpen={this.state.addsearch}
          onRequestClose={() => {
            this.setState({ addsearch: false });
          }}
          className="address-modal"
          overlayClassName="modal-overlay"
        >
          <Container fluid style={{ padding: 0 }}>
            <Row className="modal-container">
              <DaumPostcode onComplete={this.handleAddress} />
            </Row>
          </Container>
        </Modal>
        <Row style={{ minHeight: '70vh' }}>
          <Col md={3} className="myLeftContainer">
            <h1>Info</h1>
          </Col>

          <Col md={9} className="myRightContainer">
            {/* <h1>Info.</h1> */}
            <Col md={12} className="rightRow">
              <Col md={6} className="eachInfo">
                <label> Email </label>
                <p>{this.state.email}</p>
              </Col>
              <Col md={6} className="eachInfo">
                <label> Name </label>
                <p>{this.state.name}</p>
              </Col>
            </Col>
            <Col md={12} className="rightRow">
              <Col md={6} className="eachInfo">
                <label> Password </label>
                <div
                  style={{ textAlign: 'center' }}
                  onClick={this.onChangePassword}
                >
                  <button> 비밀번호 변경 </button>
                </div>
              </Col>
              <Col md={6} className="eachInfo">
                <label> phone </label>
                <p>{this.state.phone}</p>
              </Col>
            </Col>

            <Col md={12} className="rightRow">
              <Col md={6} className="eachInfo">
                <label> Zip / Address </label>
                <p>
                  {this.state.zipcode + ' '}/{this.state.address}
                </p>
                <div
                  style={{ textAlign: 'center' }}
                  onClick={() => {
                    this.setState({ addsearch: true });
                  }}
                >
                  <button> 주소 변경 </button>
                </div>
              </Col>
              <Col md={6} className="eachInfo">
                <label> DetailAddress </label>
                <p>
                  <input
                    id="address_detail"
                    name="address_detail"
                    value={this.state.address_detail}
                    onChange={(e) => this.handleChange(e)}
                  ></input>
                </p>
                <div
                  onClick={this.onChangeDetail}
                  style={{ textAlign: 'center' }}
                  type="text"
                >
                  <button>변경 </button>
                </div>
              </Col>
            </Col>
          </Col>
        </Row>
        <Row style={{ minHeight: '70vh' }}>
          <Col md={3} className="myLeftContainer">
            <h1>Order</h1>
          </Col>
          <Col md={9}></Col>
        </Row>
      </Container>
    );
  }
}
export default Info;
