import React, { Component } from 'react';
import {
  StoryProfile,
  StoryMajor,
  StoryPortfolio,
  StoryPhotozone,
} from 'components';
import { Container } from 'react-bootstrap';
import '../../components/Story/StoryCss.scss';
import '../../components/style.scss';
import { Row, Col } from 'react-bootstrap';

import scrollToComponent from 'react-scroll-to-component';
class Story extends Component {
  render() {
    return (
      <Container
        fluid
        style={{ display: 'flex', flexDirection: 'column', maxWidth: '1280px' }}
      >
        <Row>
          <div className="main_wrap">
            <div className="main_cont">
              <div className="main_h_line"></div>
              <p className="text1">
                <h5>Modern Art Gallery &amp; Shop</h5>
              </p>
              <h1 className="text1">
                <b>
                  About <br></br>
                  ZiBEZI's&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </b>
                <br></br>
              </h1>

              <p className="text2">Welcome to ZiBEZI world</p>
              <div className="button_wrap">
                <Col
                  md={3}
                  className="main_button"
                  style={{ display: 'table' }}
                >
                  <div
                    className="main_button2"
                    onClick={() =>
                      scrollToComponent(this.StoryProfile, {
                        offset: 0,
                        align: 'top',
                        duration: 1500,
                      })
                    }
                  >
                    Story
                  </div>
                </Col>
                <Col
                  md={3}
                  className="main_button"
                  style={{ display: 'table' }}
                >
                  <div
                    className="main_button2"
                    onClick={() =>
                      scrollToComponent(this.StoryPhotozone, {
                        offset: 0,
                        align: 'top',
                        duration: 1500,
                      })
                    }
                  >
                    Photozone
                  </div>
                </Col>
                <Col
                  md={3}
                  className="main_button"
                  style={{ display: 'table' }}
                >
                  <div
                    className="main_button2"
                    onClick={() =>
                      scrollToComponent(this.StoryMajor, {
                        offset: 0,
                        align: 'top',
                        duration: 1500,
                      })
                    }
                  >
                    MajorWork
                  </div>
                </Col>
                <Col
                  md={3}
                  className="main_button"
                  style={{ display: 'table' }}
                >
                  <div
                    className="main_button2"
                    onClick={() =>
                      scrollToComponent(this.StoryPortfolio, {
                        offset: 0,
                        align: 'top',
                        duration: 2000,
                      })
                    }
                  >
                    Portfolio
                  </div>
                </Col>
              </div>
            </div>
          </div>
        </Row>
        <StoryProfile
          ref={(section) => {
            this.StoryProfile = section;
          }}
        />
        <StoryPortfolio
          ref={(section) => {
            this.StoryPortfolio = section;
          }}
        />
        <StoryMajor
          ref={(section) => {
            this.StoryMajor = section;
          }}
        />
        <StoryPhotozone
          ref={(section) => {
            this.StoryPhotozone = section;
          }}
        />
      </Container>
    );
  }
}
export default Story;
