import React, { useState, useEffect } from 'react';
import { Row, Col, Container } from 'react-bootstrap';
import '.././ShopCss.scss';
import { EditModal, Storage, Database, Firebase as firebase } from 'components';
import useStore from '../../../stores/useStore';
import { observer, useObserver } from 'mobx-react';
import Modal from 'react-modal';
import WorkCard from './WorkCard';

const WorksPage = observer((props) => {
  const { Works } = useStore();
  const [works, setWorks] = useState(props.works);
  const [cards, setCards] = useState([]);
  //const [worksPerPage, setWorksPerPage] = useState(12);
  const [key, setKey] = useState(null);
  const [edit, setEdit] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);

  if (!Works.loading) {
    setTimeout(() => {
      setWorks(props.works);
    }, 1000);
  }
  useEffect(() => {
    setCardsUI();
    async function setCardsUI() {
      let list = new Promise(async function (resolve, reject) {
        let cardList = await works.map(function (work) {
          return (
            <WorkCard
              work={work}
              toggleEdit={(e) => toggleEdit(e)}
              selectedKey={(e) => selectedKey(e)}
              toggleDelete={(e) => toggleDelete(e)}
            />
          );
        });
        resolve(cardList);
      });
      list.then((res) => {
        setCards(res);
      });
    }
  }, [works]);

  const toggleDelete = (e) => {
    setDeleteModal(e);
  };
  const toggleEdit = (e) => {
    setEdit(e);
  };
  const selectedKey = (key) => {
    setKey(key);
  };

  const removeItem = async () => {
    await Database.collection('Artworks')
      .doc(key)
      .get()
      .then((res) => {
        Object.keys(res.data().hashtags).forEach((hashtag) => {
          Database.collection('Hashtags')
            .doc(hashtag)
            .get()
            .then((res) => {
              let count = res.data().count;
              if (count > 0) {
                Database.collection('Hashtags')
                  .doc(res.id)
                  .update({
                    count: parseInt(count) - 1,
                  })
                  .then(() => {
                    // console.log(res.id, "카운트다운");
                  })
                  .catch((err) => {
                    console.log(err);
                  });
              } else {
                Database.collection('Hashtags')
                  .doc(res.id)
                  .delete()
                  .then(() => {
                    // console.log(res.id, "삭제");
                  })
                  .catch((err) => console.log(err));
              }
            });
        });
      });
    await Database.collection('Artworks')
      .doc(key)
      .delete()
      .then(() => {
        console.log('삭제완료');
      })
      .catch((err) => console.log(err));
    await Storage.ref('artworks')
      .child('/' + key)
      .delete()
      .then(() => {
        console.log('이미지 삭제완료');
      });
    setDeleteModal(false);
    window.location.reload();
  };

  return useObserver(() =>
    !Works.loading ? (
      <>
        <Modal
          ariaHideApp={false}
          isOpen={deleteModal}
          onRequestClose={() => {
            setDeleteModal(false);
          }}
          className="alert-modal"
          overlayClassName="modal-overlay"
        >
          <Container fluid style={{ padding: 0 }}>
            <Row className="modal-container">
              <Col xs={12} className="alert">
                <span>삭제하시겠습니까?</span>
              </Col>
              <Col
                sm={12}
                className="alert-btn pointer"
                onClick={() => {
                  removeItem();
                }}
              >
                <span>삭제</span>
              </Col>
              <Col
                sm={12}
                className="alert-btn pointer"
                onClick={() => {
                  setDeleteModal(false);
                }}
              >
                <span>취소</span>
              </Col>
            </Row>
          </Container>
        </Modal>
        <EditModal
          show={edit}
          elementkey={key}
          category={false}
          callback={() => {
            setEdit(false);
          }}
        />

        <div key={''}>{cards}</div>
      </>
    ) : (
      <div>Loading...</div>
    ),
  );
});

export default WorksPage;
