import React, { Component } from 'react';
import { Row, Image, Spinner, Col, Container } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { Database, Storage } from 'components';
import StackGrid from 'react-stack-grid';
import 'components/style.scss';
import 'style.scss';
import { observer, inject } from 'mobx-react';
import ViewPoint from 'react-responsive-modal';
import BottomScrollListener from 'react-bottom-scroll-listener';

@inject('store')
@observer
class GalleryScreen extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
      size: 0,
      viewPointShow: false,
      open: false,
      gridWidth: window.innerWidth > 768 ? '30%' : '45%',
      frameWidth: window.innerWidth > 768 ? '15px' : '10px',
      list: [],
      hashtags: [],
      selectedTags: [],
      loaded: true,
      last: '',
      status: false,
    };
  }
  onOpenModal = () => {
    this.setState({ viewPointShow: true });
  };

  onCloseModal = () => {
    this.setState({ viewPointShow: false });
  };

  handleOnDocumentBottom = async () => {
    let list = this.state.list.slice();
    if (this.state.loaded && !this.state.selectedTags.length) {
      this.setState({ loaded: false, status: true });
      await setTimeout(() => this.grid.updateLayout(), 100);
      let docRefs = Database.collection('Artworks')
        .orderBy('timestamp', 'desc')
        .startAfter(this.state.last)
        .limit(10);
      let storageRefs = Storage.ref('artworks');

      await docRefs
        .where('uploaded', '==', true)
        .get()
        .then((querySnapshot) => {
          this.setState({
            last: querySnapshot.docs[querySnapshot.docs.length - 1].data()
              .timestamp,
          });
          querySnapshot.forEach((doc) => {
            let key = doc._key.path.segments[doc._key.path.segments.length - 1];
            storageRefs
              .child('/' + key)
              .getDownloadURL()
              .then((url) => {
                list.push(
                  this.returnElement(
                    key,
                    url,
                    doc.data().title,
                    doc.data().eng_title,
                    doc.data().soldout,
                  ),
                );
              })
              .catch((err) => {
                console.log(err);
              });
          }); //foreach
        })
        .catch((err) => console.log(err));
    } //if
    this.setState({ list });
    setTimeout(() => this.grid.updateLayout(), 300);
    setTimeout(() => this.setState({ loaded: true, status: false }), 800);
  };

  returnElement = (key, url, title, eng_title, soldout) => {
    return (
      <Link to={'/gallery/' + key} key={key}>
        <div
          key={key}
          className="artwork-grid pointer"
          onClick={() => {}}
          style={{ padding: this.state.frameWidth }}
        >
          <div className="artwork-element">
            <div className=" pointer">
              <Image
                className="artwork-image"
                src={url ? url : require('images/1.1.png')}
              />
            </div>
            <div style={{ padding: '10px 0 10px 5px' }}>
              <span className="grid-name-text">
                {title}
                {eng_title ? ' / ' + eng_title : ''}
              </span>
              <span className={soldout ? 'soldout-circle' : ''}></span>
              <br />
            </div>
          </div>
        </div>
      </Link>
    );
  };
  updateArtworkList = async (init = false, tags = []) => {
    let dbRefs = Database.collection('Artworks').where('uploaded', '==', true);
    let last = this.state.last;
    if (init) {
      last = '';
      this.setState({ list: [] });
    }
    tags.forEach((tag) => {
      dbRefs = dbRefs.where(`hashtags.${tag}`, '==', true);
    });
    dbRefs.get().then((snap) => {
      console.log(snap.size);
      this.setState({ size: snap.size, list: [] });
    });

    this.setState({ status: true });

    let storageRefs = Storage.ref('artworks');

    dbRefs
      .orderBy('timestamp', 'desc')
      .limit(15)
      .get()
      .then((snap) => {
        this.setState({
          last: snap.docs[snap.docs.length - 1].data().timestamp,
        });
        let list = this.state.list.slice();
        snap.forEach((doc) => {
          let key = doc._key.path.segments[doc._key.path.segments.length - 1];
          storageRefs
            .child(`/${key}`)
            .getDownloadURL()
            .then((url) => {
              list.push(
                this.returnElement(
                  key,
                  url,
                  doc.data().title,
                  doc.data().eng_title,
                  doc.data().soldout,
                ),
              );
              this.setState({ list });
              setTimeout(() => {
                this.grid.updateLayout();
              }, 300);
            })
            .catch((err) => {
              console.log(err);
            });
        });
      })
      .catch((err) => {
        console.log(err);
      });
    this.setState({ loaded: true, status: false });
  };
  componentWillMount = async () => {
    this.updateArtworkList(true);
  };

  componentDidMount = () => {
    window.addEventListener('resize', this.resize.bind(this));
    let dbRef = Database.collection('Hashtags');
    dbRef
      .orderBy('count', 'desc')
      .get()
      .then((querySnapshot) => {
        let hashtags = [];
        querySnapshot.forEach((doc) => {
          hashtags.push(doc.data());
        });
        this.setState({ hashtags });
      })
      .catch((err) => {
        console.log(err);
      });
  };
  filtering = (tags) => {
    let docRefs = Database.collection('Artworks');
    let storageRefs = Storage.ref('artworks');
    if (tags.length === 0) {
      docRefs
        .where('uploaded', '==', true)
        .get()
        .then((querySnapshot) => {
          let list = [];
          querySnapshot.forEach((doc) => {
            let key = doc._key.path.segments[doc._key.path.segments.length - 1];
            storageRefs
              .child('/' + key)
              .getDownloadURL()
              .then((url) => {
                list.push(
                  this.returnElement(
                    key,
                    url,
                    doc.data().title,
                    doc.data().eng_title,
                    doc.data().soldout,
                  ),
                );
                this.setState({ list });
                setTimeout(() => this.grid.updateLayout(), 1000);
              })
              .catch((err) => {
                console.log(err);
              });
          });
        })
        .catch((err) => console.log(err));
    } else {
      let list = [];
      docRefs = docRefs.where('uploaded', '==', true);
      tags.forEach((tag) => {
        docRefs = docRefs.where('hashtags.' + tag, '==', true);
      });
      docRefs
        .get()
        .then((querySnapshot) => {
          console.log(querySnapshot);
          if (!querySnapshot.empty) {
            querySnapshot.forEach((doc) => {
              console.log(doc.data());
              let key =
                doc._key.path.segments[doc._key.path.segments.length - 1];
              storageRefs
                .child('/' + key)
                .getDownloadURL()
                .then((url) => {
                  // let list = this.state.list.slice();
                  list.push(
                    this.returnElement(
                      key,
                      url,
                      doc.data().title,
                      doc.data().eng_title,
                      doc.data().soldout,
                    ),
                  );
                  this.setState({ list });
                  setTimeout(() => this.grid.updateLayout(), 1000);
                })
                .catch((err) => {
                  console.log(err);
                });
            });
          } else {
            this.setState({ list });
            setTimeout(() => this.grid.updateLayout(), 1000);
          }
        })
        .catch((err) => console.log(err));
    }
  };
  resize = async () => {
    await this.setState({
      gridWidth: window.innerWidth > 768 ? '30%' : '45%',
      frameWidth: window.innerWidth > 768 ? '15px' : '10px',
    });
    setTimeout(() => {
      this.grid.updateLayout();
    }, 500);
  };

  render() {
    return (
      <Container>
        <Row className="video-section">
          <img
            className="section-img max300"
            src={require('images/logologo.png')}
            alt=""
          ></img>
        </Row>

        <Row className="viewPointContainer">
          <div
            href="#"
            className="video-btn"
            onClick={() => this.setState({ viewPointShow: true })}
          >
            작가의 말
          </div>
          {/* <div style={{marginLeft:"5px"}}href="#" className = "video-btn" onClick={() => this.setState({ viewPointShow: true })}>WITH ARTIST</div> */}
        </Row>
        <ViewPoint
          open={this.state.viewPointShow}
          onClose={this.onCloseModal}
          center
        >
          <img
            className="ModalImage"
            src={require(`images/viewPoint.jpg`)}
            alt=""
          />
          <div className="ModalBtnContainer"></div>
        </ViewPoint>

        {/* <Row className="hashtags" >
              <div style={{width:"auto"}}>
                 {this.state.hashtags.map((tag, key)=>{
                if(this.state.selectedTags.includes(tag.id))
                {return(
                  
                  <span key={key} className = "pointer hash-span-selected"
                  onClick={()=>{
                    let selectedTags = this.state.selectedTags.filter(id => id !== tag.id);
                    this.setState({selectedTags});
                    this.filtering(selectedTags);
                  }}
                  >{"#"+tag.id}</span>
                )}else{
                  return(
                   <span key={key} className = "pointer hash-span"
                    onClick={()=>{
                      let selectedTags = this.state.selectedTags.slice();
                      selectedTags.push(tag.id)
                      this.setState({selectedTags});
                      this.filtering(selectedTags);
                    }}
                    >{"#"+tag.id}</span>
                  )
                }
              })}
              </div>
            </Row> */}
        <div style={{ minHeight: '80vh' }}>
          <StackGrid
            className="m-3"
            columnWidth={this.state.gridWidth}
            gridRef={(grid) => (this.grid = grid)}
            gutterWidth={30}
            gutterHeight={30}
          >
            {this.state.list}
          </StackGrid>
          <BottomScrollListener
            onBottom={this.handleOnDocumentBottom}
            offset={20}
            debounce={20}
          />
        </div>

        {this.state.status ? (
          <Col
            xs={12}
            className="flex justify-content-center align-items-center"
            style={{ height: '15vh' }}
          >
            <div>
              <Spinner animation={'border'} />
            </div>
          </Col>
        ) : (
          <Col
            xs={12}
            className="flex justify-content-center align-items-center"
          >
            {' '}
            <p>Please Scroll Down</p>{' '}
          </Col>
        )}
        <div
          style={{
            position: 'fixed',
            bottom: '10px',
            left: 0,
            width: '100%',
            height: '50px',
            display: 'flex',
            zIndex: 1000,
            justifyContent: 'center',
          }}
        >
          <span
            style={{
              position: 'relative',

              backgroundColor: 'rgba(255,255,255,0.7)',
              width: '100px',
              height: '50px',
              borderRadius: '25px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >{`${this.state.list.length} / ${this.state.size}`}</span>
        </div>
      </Container>
    );
  }
}
export default GalleryScreen;
