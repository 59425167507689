import React, { Component, useState, useEffect } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { Link, NavLink } from 'react-router-dom';
import { ShopHeader, Firebase as firebase } from 'components';
import 'components/style.scss';
import 'style.scss';
import { observer, inject, useObserver } from 'mobx-react';
import useStore from '../../stores/useStore';

const ZibeziPick = observer((props) => {
  const { Works } = useStore();
  const [works, setWorks] = useState([]);
  const [cards, setCards] = useState([]);

  useEffect(() => {
    setCardsUI();
    async function setCardsUI() {
      const zibeziPick = await Works.getZibeziPick();

      setTimeout(() => {
        setWorks(zibeziPick);
      }, 2000);
      let list = await works.map(function (work) {
        return returnCard(work);
      });
      setCards(list);
      await Works.setLoading(false);
    }
    return () => {};
  }, [works]);

  const returnCard = (work) => {
    return (
      <Col
        xs={6}
        sm={6}
        md={4}
        className="blue-hover card-style"
        key={work.key}
      >
        <Link to={'/shop/artworks/' + work.key} key={work.key}>
          <div className="image-container">
            <img
              className="card-image"
              alt="work"
              src={work.url ? work.url : require('images/1.1.png')}
            />
          </div>
        </Link>
        <div className="title-box">
          <p className="card-title">
            {work.title}
            <span className={work.soldout ? 'soldout-circle' : ''}></span>
          </p>
          <p className="card-subtitle">{work.eng_title}</p>
        </div>
      </Col>
    );
  };
  return useObserver(() => (
    <Container className="artworks-main">
      <Row className="p-3">
        <Col md={2} xs={4}>
          <NavLink
            exact
            to="/shop/pagination"
            onClick={() => {}}
            className="shop-btn"
            activeClassName="shop-btn-highlight"
          >
            <span>Artworks</span>
          </NavLink>
        </Col>
        <Col md={2} xs={4}>
          <NavLink
            exact
            to="/shop/pick"
            onClick={() => {}}
            className="shop-btn"
            activeClassName="shop-btn-highlight"
          >
            <span>ZiBEZI's Pick</span>
          </NavLink>
        </Col>
      </Row>
      <ShopHeader
        description={'The work can be purchased through 1:1 consultation.'}
        description2={'Selected by ZiBEZI'}
      />
      <div className="display-container">
        <div key={''}>{cards}</div>
      </div>
    </Container>
  ));
});
export default ZibeziPick;
