import React, { Component } from 'react';
import { Container, Row, Col, Image } from 'react-bootstrap';
import { Database, Storage } from 'components';
import ShippingInfo from './ShippingInfo';
import { MdCheck, MdClose } from 'react-icons/md';
import 'style.scss';
import 'components/style.scss';

class ArtworksDetail extends Component {
  constructor(props) {
    super(props);
    let key = this.props.match.params.aid;

    this.state = {
      key,
      title: '',
      eng_title: '',
      width: '',
      height: '',
      date: '',
      on_sale: false,
      custom_tshirts: false,
      custom_ecobag: false,
      custom_shoes: false,
      custom_else: false,
      base: null,
      material: '',
      own: false,
      soldout: false,
      hashtags: [],
      url: require('images/loading.gif'),
    };
  }
  componentDidMount = () => {
    const key = this.state.key;
    let docRefs = Database.collection('Artworks').doc(key);
    let storageRefs = Storage.ref('artworks');
    docRefs
      .get()
      .then((doc) => {
        storageRefs
          .child('/' + key)
          .getDownloadURL()
          .then((url) => {
            const data = doc.data();
            this.setState({
              key,
              title: data.title,
              eng_title: data.eng_title,
              width: data.width,
              height: data.height,
              date: data.date,
              on_sale: data.on_sale,
              base: data.base,
              material: data.material.toString(),
              own: data.own,
              soldout: data.soldout,
              hashtags: data.hashtags,
              custom_tshirts: data.custom_tshirts,
              custom_ecobag: data.custom_ecobag,
              custom_shoes: data.custom_shoes,
              custom_else: data.custom_else,
              url,
            });
          })
          .catch((err) => {
            console.log(err);
          });
      })
      .catch((err) => console.log(err));
  };
  displayFlag = (flag) => {
    return flag ? (
      <MdCheck color={'gray'} size={'20px'} />
    ) : (
      <MdClose color={'gray'} size={'20px'} />
    );
  };
  displayHashtags = () => {
    let hashtags = Object.entries(this.state.hashtags);
    if (hashtags.length > 0) {
      return (
        <div className="hashtags-container">
          {hashtags.map((element, key) => {
            return (
              <span key={key} className="hashtag-span">
                #{element[0]}
              </span>
            );
          })}
        </div>
      );
    }
  };
  displayBase = () => {
    switch (this.state.base) {
      case 0:
        return 'canvas';
      case 1:
        return 'paper';
      default:
        return '';
    }
  };
  displayMaterial = () => {
    const { material } = this.state;
    let returnString = '';
    if (material.length > 0) {
      for (let i = 0; i < material.length; i++) {
        switch (material[i]) {
          case '0': {
            returnString += 'else, ';
            break;
          }
          case '1': {
            returnString += 'acrylic, ';
            break;
          }
          case '2': {
            returnString += 'oil, ';
            break;
          }
          case '3': {
            returnString += 'watercolor, ';
            break;
          }
          case '4': {
            returnString += 'crayon, ';
            break;
          }
          case '5': {
            returnString += 'maca, ';
            break;
          }
          case '6': {
            returnString += 'oil pastel, ';
            break;
          }
          default: {
            break;
          }
        }
      }
      returnString = returnString.slice(0, returnString.length - 2);
    } else {
    }
    return returnString;
  };
  render() {
    return (
      <Container className="artwork-detail">
        <Row className="artwork-detail-row">
          <Col xs={12}>
            <Col lg={7} className="image-box">
              <Image src={this.state.url} className="image" />
            </Col>
            <Col lg={5} className="description-div">
              <div className="artwork-text-div">
                <span className="title">{this.state.title}</span>
                <br />
                <span className="subtitle">{this.state.eng_title}</span>
                <br />
                <span className="else-text">
                  {this.state.width}cm{'X'}
                  {this.state.height}cm
                </span>
                <br />
                <span className="else-text">{this.displayBase()}</span>
                <br />
                <span className="else-text">{this.displayMaterial()}</span>
                <br />
                <span className="else-text">
                  {this.state.date.split('-')[0]}
                </span>
                {this.displayHashtags()}
              </div>
              <div
                onClick={() => window.open('http://pf.kakao.com/_eViTT')}
                className="contact pointer"
              >
                Contact us
              </div>
            </Col>
          </Col>
        </Row>
        <Row>
          <Col>
            <div className="roomchoice">
              <Image
                src={require('images/room/concrete.jpg')}
                className="roomchoice-image"
              />
              <div className="roomchoice-image-src-container">
                <Image src={this.state.url} className="roomchoice-image-src" />
              </div>
            </div>
            <div className="roomchoice">
              <Image
                src={require('images/room/livingroom.jpg')}
                className="roomchoice-image"
              />
              <div className="roomchoice-image-src-container">
                <Image
                  src={this.state.url}
                  className="roomchoice-image-src-small"
                />
              </div>
            </div>
            <div className="roomchoice">
              <Image
                src={require('images/room/sofa2.jpg')}
                className="roomchoice-image"
              />
              <div className="roomchoice-image-src-container">
                <Image src={this.state.url} className="roomchoice-image-src" />
              </div>
            </div>
            <div className="roomchoice">
              <Image
                src={require('images/room/sofa.png')}
                className="roomchoice-image"
              />
              <div className="roomchoice-image-src-container">
                <Image src={this.state.url} className="roomchoice-image-src" />
              </div>
            </div>
          </Col>
        </Row>
        <Row className="custom-container">
          <Col xs={12} className="flag-header">
            Custom
          </Col>
          <Col md={6}>
            <Col xs={6} className="custom-flag-container">
              <Col xs={12} className="flag-element flag-border">
                Tshirts
              </Col>
              <Col xs={12} className="flag-element">
                {this.displayFlag(this.state.custom_tshirts)}
              </Col>
            </Col>
            <Col xs={6} className="custom-flag-container">
              <Col xs={12} className="flag-element flag-border">
                Shoes
              </Col>
              <Col xs={12} className="flag-element">
                {this.displayFlag(this.state.custom_shoes)}
              </Col>
            </Col>
          </Col>
          <Col md={6}>
            <Col xs={6} className="custom-flag-container">
              <Col xs={12} className="flag-element flag-border">
                Eco bag
              </Col>
              <Col xs={12} className="flag-element">
                {this.displayFlag(this.state.custom_ecobag)}
              </Col>
            </Col>
            <Col xs={6} className="custom-flag-container">
              <Col xs={12} className="flag-element flag-border">
                etc
              </Col>
              <Col xs={12} className="flag-element">
                {this.displayFlag(this.state.custom_else)}
              </Col>
            </Col>
          </Col>
        </Row>
        <ShippingInfo />
      </Container>
    );
  }
}
export default ArtworksDetail;
